import { PUBLIC_PERMISSION } from 'auth/hooks/UseHasPermission'
import { generateCrudActions } from 'auth/utils/generateCrudActions'
import { Navigation } from './types'

const navigation: Navigation = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'dashboard',
    url: '/dashboard',
    permissions: [PUBLIC_PERMISSION],
  },
  {
    id: 'reports',
    title: 'Reportes',
    type: 'group',
    children: [
      {
        id: 'statement-of-incomes',
        type: 'item',
        title: 'Estado de resultados',
        icon: 'attach_money',
        url: '/reports/statement-of-incomes',
        permissions: ['report.statementOfIncomes'],
      },
      {
        id: 'month-by-month',
        type: 'item',
        title: 'Mes a mes',
        icon: 'attach_money',
        url: '/reports/month-by-month',
        permissions: ['report.monthByMonth'],
      },
      {
        id: 'account-balance',
        type: 'item',
        title: 'Reporte de saldos',
        icon: 'account_balance_wallet',
        url: '/reports/account-balance',
        permissions: ['report.accountBalance'],
      },
    ],
  },
  {
    id: 'moves',
    title: 'Movimientos',
    type: 'group',
    children: [
      {
        id: 'incomes',
        type: 'item',
        title: 'Ingresos',
        icon: 'attach_money',
        url: '/incomes',
        permissions: generateCrudActions('income'),
      },
      {
        id: 'outcomes',
        type: 'item',
        title: 'Egresos',
        icon: 'money_off',
        url: '/outcomes',
        permissions: generateCrudActions('outcome'),
      },
      {
        id: 'transfers',
        type: 'item',
        title: 'Transferencias',
        icon: 'multiple_stop',
        url: '/transfers',
        permissions: generateCrudActions('transfer'),
      },
    ],
  },
  {
    id: 'config',
    title: 'Configuración',
    type: 'group',
    children: [
      {
        id: 'outcomes',
        type: 'item',
        title: 'Egresos programados',
        icon: 'schedule_send',
        url: '/schedule-outcomes',
        permissions: generateCrudActions('scheduledOutcome'),
      },
    ],
  },
  {
    id: 'catalogs',
    title: 'Catalogos',
    type: 'group',
    children: [
      {
        id: 'organizations',
        title: 'Organizaciones',
        icon: 'corporate_fare',
        type: 'item',
        url: '/organizations',
        permissions: generateCrudActions('organization'),
      },
      {
        id: 'companies-collapse',
        title: 'Compañías',
        type: 'collapse',
        icon: 'store',
        children: [
          {
            id: 'companies',
            title: 'Todos',
            type: 'item',
            url: '/companies',
            exact: true,
            permissions: generateCrudActions('company'),
          },
          {
            id: 'customers',
            title: 'Clientes',
            type: 'item',
            url: '/companies/customer',
            permissions: generateCrudActions('company'),
          },
          {
            id: 'suppliers',
            title: 'Proveedores',
            type: 'item',
            url: '/companies/supplier',
            permissions: generateCrudActions('company'),
          },
          {
            id: 'employee',
            title: 'Empleados',
            type: 'item',
            url: '/companies/employee',
            permissions: generateCrudActions('company'),
          },
        ],
      },
      {
        id: 'banks',
        title: 'Bancos',
        icon: 'account_balance',
        type: 'item',
        url: '/banks',
        permissions: generateCrudActions('bank', ['list']),
      },
      {
        id: 'bank-accounts',
        title: 'Cuentas de banco',
        icon: 'account_balance_wallet',
        type: 'item',
        url: '/bankaccounts',
        permissions: generateCrudActions('bankAccount'),
      },
      {
        id: 'credits-group',
        title: 'Créditos',
        type: 'collapse',
        icon: 'credit_card',
        children: [
          {
            id: 'credits',
            title: 'Financiamientos',
            type: 'item',
            url: '/credits',
            permissions: generateCrudActions('credit'),
          },
          {
            id: 'customer-credits',
            title: 'Crédito clientes',
            type: 'item',
            url: '/customer-credits',
            permissions: generateCrudActions('credit'),
          },
        ],
      },
      {
        id: 'credit-cards',
        title: 'Tarjetas de crédito',
        icon: 'credit_card',
        type: 'item',
        url: '/creditcards',
        permissions: generateCrudActions('creditCard'),
      },
      {
        id: 'type-credits',
        title: 'Tipos de crédito',
        icon: 'credit_card',
        type: 'item',
        url: '/type-credits',
        permissions: generateCrudActions('typeCredit'),
      },
      {
        id: 'category-move-collpase',
        title: 'Categorías de mov.',
        type: 'collapse',
        icon: 'addchart',
        children: [
          {
            id: 'category-move-income',
            title: 'Ingresos',
            type: 'item',
            url: '/categorymoves/income',
            permissions: generateCrudActions('categoryMove'),
          },
          {
            id: 'category-move-outcome',
            title: 'Egresos',
            type: 'item',
            url: '/categorymoves/outcome',
            permissions: generateCrudActions('categoryMove'),
          },
        ],
      },
    ],
  },
  {
    id: 'auth',
    title: 'Acceso',
    type: 'group',
    children: [
      {
        id: 'users',
        title: 'Usuarios',
        icon: 'account_circle',
        type: 'item',
        url: '/users',
      },
      {
        id: 'user-profiles',
        title: 'Perfiles de Usuario',
        icon: 'group',
        type: 'item',
        url: '/user-profiles',
      },
    ],
  },
]

export const useNavigationList = () => {
  return navigation
}
