import * as React from 'react'
import { WithPermissions } from 'auth/components/WithPermissions'
import { useCrudContext } from './CrudPage/CrudContext'

export interface WithUpdateActionProps {
  tenantId?: string | null
}

export const WithUpdateAction: React.FC<WithUpdateActionProps> = ({
  tenantId,
  children,
}) => {
  const { name } = useCrudContext()

  return tenantId ? (
    <WithPermissions permissions={[`${name}.update`]}>
      {children}
    </WithPermissions>
  ) : null
}
