import { CrudPage } from 'shared/crud/CrudPage'
import { ListPage } from 'shared/crud/CrudPage/ListPage'
import { Create } from './components/Create'
import { Update } from './components/Update'
import { List } from './components/List'
import { AddPage } from 'shared/crud/CrudPage/AddPage'
import { EditPage } from 'shared/crud/CrudPage/EditPage'

export const CustomerCreditPages = () => {
  return (
    <CrudPage name="credit">
      <ListPage title="Listado de créditos de clientes">
        <List></List>
      </ListPage>
      <AddPage>
        <Create></Create>
      </AddPage>
      <EditPage>
        <Update></Update>
      </EditPage>
    </CrudPage>
  )
}
