import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Form } from 'shared/forms'
import { useUpdateOutcomeMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import { useGetOutcome } from '../data/Queries'
import { Company, CompanyType } from 'modules/company/models/Company'
import {
  CategoryMove,
  MoveType,
} from 'modules/category-move/models/CategoryMove'
import { CompanyAutocomplete } from 'modules/company/components/CompanyAutocomplete'
import { CategoryMoveAutocomplete } from 'modules/category-move/components/CategoryMoveAutocomplete'

interface UpdateForm {
  company: Company
  category: CategoryMove
}

const schema = yup.object().shape({
  company: yup
    .object()
    .nullable()
    .required('Debe asignar un cliente al egreso'),
  category: yup
    .object()
    .nullable()
    .required('Debe asignar una categoría al egreso'),
})

const formId = 'UpdateOutcome'
const dialogId = 'OutcomeEditFormDialog'

export const Update = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const { id } = useParams<{ id: string }>()

  const { data, loading: isGetting, error } = useGetOutcome({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
  const [updateOutcome, { loading: isUpdating }] = useUpdateOutcomeMutation()

  const loading = isGetting || isUpdating

  const outcome = React.useMemo(() => {
    return data?.getOutcome
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<UpdateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      company: null,
      category: null,
    },
  })
  const { formState, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (outcome) {
      reset(outcome)
    }
  }, [outcome, reset])

  React.useEffect(() => {
    if (error) {
      console.warn(error)

      let msg = 'Ha ocurrido un error al obtener el egreso. Intentelo más tarde'

      if (error.message === 'Outcome not found') {
        msg = 'Egreso no encontrado'
      }

      enqueueSnackbar(msg, { variant: 'error' })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, enqueueSnackbar])

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/outcomes')
  }

  const onSubmit: SubmitHandler<UpdateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const { category, company } = values

      await updateOutcome({
        variables: {
          data: {
            id: outcome.id,
            categoryId: category.id,
            companyId: company.id,
          },
        },
      })

      enqueueSnackbar('Egreso actualizado correctamente', {
        variant: 'success',
      })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        'Ha ocurrido un error al actualizar el egreso. Intentelo más tarde',
        { variant: 'error' },
      )
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      open={isOpen}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Editar egreso</DialogTitle>
      <DialogContent>
        <Form {...formInstance} onSubmit={onSubmit} formProps={{ id: formId }}>
          <CompanyAutocomplete
            name="company"
            type={[
              CompanyType.supplier,
              CompanyType.employee,
              CompanyType.debtor,
            ]}
            inputProps={{
              label: 'Proveedor/Empleado/Acreedor',
              variant: 'outlined',
              required: true,
            }}
            placeholder="Buscar Proveedor/Empleado/Acreedor"
            className="mb-16"
            fullWidth
          ></CompanyAutocomplete>
          <CategoryMoveAutocomplete
            type={MoveType.outcome}
            name="category"
            inputProps={{
              label: 'Categoría',
              variant: 'outlined',
              required: true,
            }}
            placeholder="Buscar categoría"
            className="mb-16"
            fullWidth
          ></CategoryMoveAutocomplete>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
