import { Divider, Grid, Typography, useTheme } from '@material-ui/core'
import ReactApexChart from 'react-apexcharts'
import { formatCurrency, formatNumber } from 'shared/utils'
import { UseNetIncomeTotalsState } from './UseNetIncomeTotals'

export const NetIncomeTotals = ({
  totals,
  chart,
  totalsState,
  chartState,
}: UseNetIncomeTotalsState) => {
  const error = totalsState?.error ?? chartState.error

  return (
    <>
      <Grid container spacing={2}>
        {error ? (
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              color="error"
              className="text-center"
            >
              Error al intentar obtener la información. Intente de nuevo más
              tarde.
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={8}>
          {chart ? (
            <Chart
              labels={chart?.labels}
              income={chart?.income}
              outcome={chart?.outcome}
            ></Chart>
          ) : null}
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={2} className="h-full">
            <Grid item xs={12} className="flex items-center">
              <AmountTotal
                title="Ingresos"
                amount={totals?.income || 0}
              ></AmountTotal>
            </Grid>
            <Grid item xs={12} className="flex items-center">
              <AmountTotal
                title="Créditos"
                amount={totals?.totalCredits || 0}
              ></AmountTotal>
            </Grid>
            <Grid item xs={12} className="flex items-center">
              <AmountTotal
                title="Egresos"
                amount={totals?.outcome || 0}
              ></AmountTotal>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider></Divider>
      <Grid container spacing={2}>
        <Grid item xs={8}></Grid>
        <Grid item xs={4} className="flex">
          <AmountTotal
            title="Resultado del mes"
            amount={totals?.total || 0}
          ></AmountTotal>
        </Grid>
      </Grid>
      <Divider></Divider>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <AmountTotal
            title="Saldo incial"
            amount={totals?.initialBalance || 0}
          ></AmountTotal>
        </Grid>
        <Grid item xs={4} className="flex items-center">
          <AmountTotal
            title="Saldo final"
            amount={totals?.balance || 0}
          ></AmountTotal>
        </Grid>
        <Grid item xs={4} className="flex">
          <AmountTotal
            title="Diferencia"
            amount={totals?.balance - totals?.initialBalance || 0}
          ></AmountTotal>
        </Grid>
      </Grid>
    </>
  )
}

interface AmountTotalProps {
  title: string
  amount: number
}

function AmountTotal({ amount, title }: AmountTotalProps) {
  return (
    <div className="flex flex-col w-full p-8 items-end">
      <Typography className="text-10 font-semibold" color="textSecondary">
        {title}
      </Typography>
      <div className="flex items-center">
        <Typography className="text-24 font-normal" color="textSecondary">
          $
        </Typography>
        <Typography className="text-24 mx-4 font-medium tracking-tighter">
          {formatNumber(amount)}
        </Typography>
      </div>
    </div>
  )
}

interface ChartProps {
  labels: string[]
  income: number[]
  outcome: number[]
}

const options: ApexCharts.ApexOptions = {
  chart: {
    type: 'area',
    height: '100%',
    sparkline: {
      enabled: true,
    },
  },
  stroke: {
    width: 2,
  },
  grid: {
    padding: {
      top: 10,
      right: 0,
      bottom: 10,
      left: 0,
    },
  },
  fill: {
    type: 'solid',
    opacity: 0.7,
  },
  tooltip: {
    followCursor: true,
    theme: 'dark',
    fixed: {
      enabled: false,
      position: 'topRight',
      offsetX: 0,
      offsetY: 0,
    },
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return formatCurrency(value)
      },
    },
  },
}

function Chart({ labels, income, outcome }: ChartProps) {
  const theme = useTheme()

  return (
    <div className="flex w-full p-8">
      <div className="h-256 w-full">
        <ReactApexChart
          options={{
            ...options,
            colors: [theme.palette.info.main, theme.palette.error.main],
            xaxis: {
              categories: labels,
            },
          }}
          series={[
            {
              data: income,
              borderWidth: 0,
              fill: true,
              name: 'Ingreso',
              pointHitRadius: 20,
              pointRadius: 0,
            },
            {
              data: outcome,
              borderWidth: 0,
              fill: true,
              name: 'Egreso',
              pointHitRadius: 20,
              pointRadius: 0,
            },
          ]}
          type={options.chart.type}
          height={options.chart.height}
        />
      </div>
    </div>
  )
}
