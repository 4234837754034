import * as React from 'react'
import { useAuthContext } from 'auth/contexts/AuthContext'
import { User } from 'auth/models/User'

export const PUBLIC_PERMISSION = 'PUBLIC'

export const useHasPermission = (permissions: string[]) => {
  const { user } = useAuthContext()

  return React.useMemo(() => {
    return hasPermission(user, permissions)
  }, [user, permissions])
}

export const hasPermission = (user: User, permissions: string[]) => {
  const userPermissions = user.permissions
    .concat(
      user.profiles.reduce((acu, next) => acu.concat(next.permissions), []),
    )
    .map(p => p.name)

  if (user.isSuperuser) {
    return true
  }

  if (permissions.includes(PUBLIC_PERMISSION)) {
    return true
  }

  const userPermissionsSet = new Set(userPermissions)

  for (const permission of permissions) {
    if (userPermissionsSet.has(permission)) {
      return true
    }
  }

  return false
}
