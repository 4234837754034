import * as React from 'react'
import { create } from 'jss'
import {
  jssPreset,
  MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles'
import jssExtend from 'jss-plugin-extend'
import { BrowserRouter } from 'react-router-dom'
import { AppTheme } from 'theme'
import { ThemeProvider } from '@emotion/react'
import { ApolloProvider } from '@apollo/client'
import { SnackbarProvider } from 'notistack'
import { IconButton, SnackbarOrigin } from '@material-ui/core'
import { apolloClient } from 'core/GqlClient'
import { AuthProvider } from 'auth/contexts/AuthContext'
import Close from '@material-ui/icons/Close'
import { DateProvider } from 'shared/DateProvider'

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, jssExtend()],
  insertionPoint: document.getElementById('jss-insertion-point') as HTMLElement,
})

const notistackAnchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
} as SnackbarOrigin

export const Providers: React.FC = ({ children }) => {
  const notistackRef = React.createRef<SnackbarProvider>()

  const onClickDismiss = (key: React.ReactText) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <ApolloProvider client={apolloClient}>
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={AppTheme}>
          <ThemeProvider theme={AppTheme}>
            <BrowserRouter>
              <SnackbarProvider
                ref={notistackRef}
                anchorOrigin={notistackAnchorOrigin}
                maxSnack={3}
                action={key => (
                  <IconButton onClick={onClickDismiss(key)}>
                    <Close style={{ color: 'white' }}></Close>
                  </IconButton>
                )}
              >
                <AuthProvider>
                  <DateProvider>{children}</DateProvider>
                </AuthProvider>
              </SnackbarProvider>
            </BrowserRouter>
          </ThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </ApolloProvider>
  )
}
