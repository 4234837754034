import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Bank } from '../models/Bank'

const LIST_BANK = gql`
  query {
    listBank {
      id
      name
      status
      createdAt
    }
  }
`
export type ListBankResponse = { listBank: Bank[] }

export const useBankList = (options?: QueryHookOptions<ListBankResponse>) => {
  const result = useQuery<ListBankResponse>(LIST_BANK, options)
  return result
}
