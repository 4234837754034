import 'yup-helpers'
import 'typeface-muli'
import 'styles/index.css'
import 'dayjs/locale/es'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import localeData from 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'

import dayjs from 'dayjs'

dayjs.locale('es')
dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(localeData)
