import * as React from 'react'
import dayjs from 'dayjs'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import { useCategoryMoveList } from '../data/Queries'
import { CategoryMove, MoveType } from '../models/CategoryMove'
import { Column, Row } from 'react-table'
import { EditButton } from 'shared/crud/EditButton'
import { StatusUpdate } from './StatusUpdate'
import { Delete } from './Delete'
import { Card } from '@material-ui/core'
import { DeleteButton } from 'shared/crud/DeleteButton'
import {
  ExportCsvButton,
  ExportCsvButtonProps,
} from 'shared/crud/ExportCsvButton'
import { getRowsForDeletion } from 'shared/utils'

export const List = ({ moveType }: { moveType?: string }) => {
  const { data: categoryMoveListData } = useCategoryMoveList({
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      data: {
        moveType: moveType as MoveType,
      },
    },
  })

  const columns = React.useMemo<Column<CategoryMove>[]>(() => {
    return [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        id: 'parent.name',
        Header: 'Categoría padre',
        accessor: ({ parent }) => parent?.name,
      },
      {
        Header: 'Fecha de creación',
        accessor: 'createdAt',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        disableSortBy: true,
        Header: 'Estatus',
        accessor: categoryMove => (
          <StatusUpdate card={categoryMove}></StatusUpdate>
        ),
      },
      {
        disableSortBy: true,
        Header: 'Acciones',
        accessor: categoryMove => (
          <EditButton
            title="Editar categoría"
            aria-label="editar categoría"
            id={categoryMove.id}
            tenantId={categoryMove.tenantId}
          ></EditButton>
        ),
      },
    ]
  }, [])

  const data = React.useMemo(() => {
    return categoryMoveListData?.listCategoryMove ?? []
  }, [categoryMoveListData])

  const tableInstance = useDataTable({
    columns,
    data,
  })

  const csvProps = React.useMemo<ExportCsvButtonProps<CategoryMove>>(() => {
    return {
      columns: [
        {
          id: 'name',
          header: 'Nombre',
          cell: row => row.name,
        },
        {
          id: 'parent.name',
          header: 'Categoría padre',
          cell: row => row.parent?.name,
        },
        {
          id: 'createdAt',
          header: 'Fecha de creación',
          cell: row => dayjs(row.createdAt).format('L'),
        },
        {
          id: 'status',
          header: 'Estatus',
          cell: row => row.status,
        },
      ],
      filename: `Categoría de movimientos - ${dayjs().format('L hh:mm:ss')}`,
      data,
    }
  }, [data])

  const toolBarContent = (selectedRows: Row<CategoryMove>[]) => {
    return selectedRows.length ? (
      <DeleteButton>
        <Delete categories={getRowsForDeletion(selectedRows)}></Delete>
      </DeleteButton>
    ) : (
      <ExportCsvButton {...csvProps}></ExportCsvButton>
    )
  }

  return (
    <Card>
      <DataTable toolBarContent={toolBarContent} {...tableInstance}></DataTable>
    </Card>
  )
}
