import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  ServerError,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import { setContext } from '@apollo/client/link/context'
import { AuthRepository } from 'auth/data/AuthRepository'
import { User } from 'auth/models/User'

import { Config } from './config'

const httpLink = createHttpLink({
  uri: Config.apiUrl,
})

const authLink = setContext((_, { headers }) => {
  const token = AuthRepository.getToken()
  const tenant = AuthRepository.getTenant()
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-tenant-id': tenant,
    },
  }
})

const unauthorizedLink = onError(({ networkError }) => {
  if ((networkError as ServerError)?.statusCode === 401) {
    AuthRepository.clearSession()
    window.location.href = '/login'
  }
})

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, unauthorizedLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      listUsers: {
        merge(existing: User[] = [], incoming: User[]) {
          return [...existing, ...incoming]
        },
      },
    },
  }),
})
