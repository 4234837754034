import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useDisclosure } from 'shared/hooks'
import { useSnackbar } from 'notistack'
import { formatCurrency } from 'shared/utils'
import { AccountTransfer } from 'modules/account/models/AccountTransfer'
import { useDeleteAccountTransferMutation } from 'modules/account/data/Mutations'
import dayjs from 'dayjs'

export interface DeleteProps {
  transfer: AccountTransfer
}

const dialogId = 'TransferDeleteDialog'

export const Delete = ({ transfer }: DeleteProps) => {
  const { isOpen, open, close } = useDisclosure()

  const { enqueueSnackbar } = useSnackbar()

  const [deleteTransfer] = useDeleteAccountTransferMutation()

  const deleteCards = async () => {
    try {
      await deleteTransfer({ variables: { id: transfer.id } })
      enqueueSnackbar(`Transferencia eliminada correctamente`, {
        variant: 'success',
      })
      close()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        `Ha ocurrido un error al eliminar la transferencia. Intentelo más tarde`,
        { variant: 'error' },
      )
    }
  }

  return (
    <>
      <Tooltip title="Eliminar transferencia">
        <IconButton aria-label="eliminar" onClick={open}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        open={isOpen}
        onClose={close}
        aria-labelledby={dialogId}
      >
        <DialogTitle id={dialogId}>Eliminar transferencia</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Deseas eliminar la transferencia con los siguientes datos?
          </DialogContentText>
          <List dense>
            <ListItem>
              <ListItemText
                primary={`- Fecha: ${dayjs(transfer.date).format('L')}`}
              />
            </ListItem>
            {transfer.source.account.organization ? (
              <ListItem>
                <ListItemText
                  primary={`- Caja origen (organización): ${transfer.source.account.organization.name}`}
                />{' '}
              </ListItem>
            ) : null}
            {transfer.source.account.bankAccount ? (
              <ListItem>
                <ListItemText
                  primary={`- Cuenta de banco origen: ${transfer.source.account.bankAccount.name}`}
                />{' '}
              </ListItem>
            ) : null}
            {transfer.target.account.organization ? (
              <ListItem>
                <ListItemText
                  primary={`- Caja destino (organización): ${transfer.target.account.organization.name}`}
                />{' '}
              </ListItem>
            ) : null}
            {transfer.target.account.bankAccount ? (
              <ListItem>
                <ListItemText
                  primary={`- Cuenta de banco destino: ${transfer.target.account.bankAccount.name}`}
                />{' '}
              </ListItem>
            ) : null}
            <ListItem>
              <ListItemText
                primary={`- Monto: ${formatCurrency(transfer.source.amount)}`}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteCards} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
