import { BaseModel } from 'models/BaseModel'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { Organization } from 'modules/organization/models/Organization'

export enum AccountType {
  bankAccount = 'bankAccount',
  credit = 'credit',
  creditCard = 'creditCard',
  cash = 'cash',
}

export const accountTypes: { text: string; value: AccountType }[] = [
  {
    text: 'Cuenta de banco',
    value: AccountType.bankAccount,
  },
  {
    text: 'Crédito',
    value: AccountType.credit,
  },
  {
    text: 'Tarjeta de crédito',
    value: AccountType.creditCard,
  },
  {
    text: 'Caja',
    value: AccountType.cash,
  },
]

export class Account extends BaseModel {
  initialBalance: number
  balance: number
  bankAccount: BankAccount
  organization: Organization

  constructor(data: Partial<Account> = {}) {
    super(data)
    Object.assign(this, data)
  }
}
