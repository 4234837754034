export enum Frequency {
  annual = 'annual',
  semester = 'semester',
  bimonthly = 'bimonthly',
  monthly = 'monthly',
  biweekly = 'biweekly',
}

export const frequencyOptions: { text: string; value: Frequency }[] = [
  {
    text: 'Anual',
    value: Frequency.annual,
  },
  {
    text: 'Semestral',
    value: Frequency.semester,
  },
  {
    text: 'Bimestral',
    value: Frequency.bimonthly,
  },
  {
    text: 'Mensual',
    value: Frequency.monthly,
  },
  {
    text: 'Quincenal',
    value: Frequency.biweekly,
  },
]
