import { parseDate } from 'core/utils'
import { TenantModel } from 'models/TenantModel'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { CategoryMove } from 'modules/category-move/models/CategoryMove'
import { Company } from 'modules/company/models/Company'
import { Organization } from 'modules/organization/models/Organization'
import { Invoice } from './Invoice'
import { Ticket } from './Ticket'
import { Credit } from 'modules/credit/models/Credit'

export enum PaymentMethod {
  cash = 'cash',
  transfer = 'transfer',
  deposit = 'deposit',
  check = 'check',
  credit_card = 'credit_card',
  debit_card = 'debit_card',
  customer_credit = 'customer_credit',
}

export const paymentMethods: { text: string; value: PaymentMethod }[] = [
  {
    text: 'Efectivo',
    value: PaymentMethod.cash,
  },
  {
    text: 'Transferencia',
    value: PaymentMethod.transfer,
  },
  {
    text: 'Depósito',
    value: PaymentMethod.deposit,
  },
  {
    text: 'Cheque',
    value: PaymentMethod.check,
  },
  {
    text: 'Tarjeta de crédito',
    value: PaymentMethod.credit_card,
  },
  {
    text: 'Tarjeta de debito',
    value: PaymentMethod.debit_card,
  },
  {
    text: 'Crédito clientes',
    value: PaymentMethod.customer_credit,
  },
]

export class Income extends TenantModel {
  date: Date
  amount: number
  company: Company
  employee?: Company
  paymentMethod: PaymentMethod
  category: CategoryMove
  organization: Organization
  bankAccount: BankAccount
  notes?: string
  invoice?: Invoice
  ticket?: Ticket
  customerCredit?: Credit

  constructor(data: Partial<Income>) {
    super(data)
    const {
      date,
      company,
      category,
      organization,
      bankAccount,
      ...income
    } = data

    if (date) {
      this.date = parseDate(date)
    }

    if (company) {
      this.company = new Company(company)
    }

    if (category) {
      this.category = new CategoryMove(category)
    }

    if (organization) {
      this.organization = new Organization(organization)
    }

    if (bankAccount) {
      this.bankAccount = new BankAccount(bankAccount)
    }

    Object.assign(this, income)
  }
}
