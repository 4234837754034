import { TenantModel } from 'models/TenantModel'

export enum CompanyType {
  customer = 'customer',
  supplier = 'supplier',
  creditor = 'creditor',
  employee = 'employee',
  debtor = 'debtor',
}

export const companyTypeLabels: Record<CompanyType, string> = {
  [CompanyType.customer]: 'Cliente',
  [CompanyType.supplier]: 'Proveedor',
  [CompanyType.creditor]: 'Acreedor',
  [CompanyType.employee]: 'Empleado',
  [CompanyType.debtor]: 'Deudor',
}

export const companyTypes = Object.values(CompanyType).map(companyType => ({
  text: companyTypeLabels[companyType],
  value: companyType,
}))

export class Company extends TenantModel {
  name: string
  rfc?: string
  businessName: string
  email: string
  phone: string
  companyType: CompanyType[]

  constructor(data?: Partial<Company>) {
    super(data)
    Object.assign(this, data)
  }
}
