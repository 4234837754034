import { Grid, MenuItem } from '@material-ui/core'
import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { SelectForm } from 'shared/forms'
import { Frequency } from '../models/Frequency'
import { getDayOptions, getMonthOptions } from '../utils/cron'

export function MonthsSelect({ disabled }: { disabled?: boolean }) {
  const { getValues, control, setValue } = useFormContext()
  const [options, setOptions] = React.useState<
    { text: string; value: string }[]
  >([])

  const frequency = useWatch<Frequency>({
    control,
    name: 'frequency',
    defaultValue: getValues('frequency'),
  })

  React.useEffect(() => {
    const newOptions = getMonthOptions(frequency)
    setOptions(newOptions)
    if (!newOptions.length) {
      setValue('months', '')
    }
  }, [frequency, setValue])

  React.useEffect(() => {
    if (options.length) {
      const oldValue = getValues('months')
      const foundValue = options.find(opt => opt.value === oldValue)?.value
      const newValue = foundValue ?? options[0].value
      setValue('months', newValue)
    }
  }, [options, setValue, getValues])

  return options.length ? (
    <Grid xs={3} item>
      <SelectForm
        name="months"
        label="Mes"
        variant="outlined"
        className="mb-16"
        required
        fullWidth
        disabled={disabled}
      >
        {options.map(type => (
          <MenuItem key={type.text} value={type.value}>
            {type.text}
          </MenuItem>
        ))}
      </SelectForm>
    </Grid>
  ) : null
}

export function DaySelect({ disabled }: { disabled?: boolean }) {
  const { getValues, control, setValue } = useFormContext()
  const [options, setOptions] = React.useState<
    { text: string; value: string }[]
  >([])

  const frequency = useWatch<Frequency>({
    control,
    name: 'frequency',
    defaultValue: getValues('frequency'),
  })

  React.useEffect(() => {
    const newOptions = getDayOptions(frequency)
    setOptions(newOptions)
  }, [frequency, setValue])

  React.useEffect(() => {
    if (options.length) {
      const oldValue = getValues('days')
      const foundValue = options.find(opt => opt.value === oldValue)?.value
      const newValue = foundValue ?? options[0].value
      setValue('days', newValue)
    }
  }, [options, setValue, getValues])

  return options.length ? (
    <Grid xs={3} item>
      <SelectForm
        name="days"
        label="Día"
        variant="outlined"
        className="mb-16"
        required
        fullWidth
        disabled={disabled}
      >
        {options.map(type => (
          <MenuItem key={type.text} value={type.value}>
            {type.text}
          </MenuItem>
        ))}
      </SelectForm>
    </Grid>
  ) : null
}
