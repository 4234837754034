import { parseDate } from 'core/utils'

export enum Status {
  inactive = 'inactive',
  active = 'active',
  archived = 'archived',
}

export class BaseModel {
  id: string
  createdAt: Date
  createdBy: string
  updatedAt?: Date
  updatedBy?: string
  status: Status

  constructor(model?: Partial<BaseModel>) {
    Object.assign(this, model)

    if (!model) {
      return
    }

    this.createdAt = parseDate(model.createdAt)
    this.updatedAt = parseDate(model.updatedAt)
  }
}
