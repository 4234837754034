import * as React from 'react'
import dayjs from 'dayjs'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import { useOrganizationList } from '../data/Queries'
import { Organization } from '../models/Organization'
import { Column, Row } from 'react-table'
import { EditButton } from 'shared/crud/EditButton'
import { StatusUpdate } from './StatusUpdate'
import { Delete } from './Delete'
import { Card } from '@material-ui/core'
import { formatCurrency, getRowsForDeletion } from 'shared/utils'
import { DeleteButton } from 'shared/crud/DeleteButton'
import {
  ExportCsvButton,
  ExportCsvButtonProps,
} from 'shared/crud/ExportCsvButton'

export const List = () => {
  const { data: orgListData } = useOrganizationList({
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const columns = React.useMemo<Column<Organization>[]>(() => {
    return [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'RFC',
        accessor: 'rfc',
      },
      {
        Header: 'Razón social',
        accessor: 'businessName',
      },
      {
        id: 'account.initialBalance',
        Header: 'Saldo inicial',
        accessor: ({ account }) => formatCurrency(account.initialBalance),
      },
      {
        id: 'account.balance',
        Header: 'Saldo',
        accessor: ({ account }) => formatCurrency(account.balance),
      },
      {
        Header: 'Fecha de creación',
        accessor: 'createdAt',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        disableSortBy: true,
        Header: 'Estatus',
        accessor: org => <StatusUpdate organization={org}></StatusUpdate>,
      },
      {
        disableSortBy: true,
        Header: 'Acciones',
        accessor: org => (
          <EditButton
            title="Editar organización"
            aria-label="editar organización"
            id={org.id}
            tenantId={org.tenantId}
          ></EditButton>
        ),
      },
    ]
  }, [])

  const data = React.useMemo(() => {
    return orgListData?.listOrganization ?? []
  }, [orgListData])

  const tableInstance = useDataTable({
    columns,
    data,
  })

  const csvProps = React.useMemo<ExportCsvButtonProps<Organization>>(() => {
    return {
      columns: [
        {
          id: 'name',
          header: 'Nombre',
          cell: row => row.name,
        },
        {
          id: 'rfc',
          header: 'RFC',
          cell: row => row.rfc,
        },
        {
          id: 'businessName',
          header: 'Razon Social',
          cell: row => row.businessName,
        },
        {
          id: 'account.initialBalance',
          Header: 'Saldo inicial',
          cell: row => row.account.initialBalance,
        },
        {
          id: 'account.balance',
          header: 'Saldo',
          cell: row => row.account.balance,
        },
        {
          id: 'createdAt',
          header: 'Fecha de creación',
          cell: row => dayjs(row.createdAt).format('L'),
        },
        {
          id: 'status',
          header: 'Estatus',
          cell: row => row.status,
        },
      ],
      filename: `Organización - ${dayjs().format('L hh:mm:ss')}`,
      data,
    }
  }, [data])

  const toolBarContent = (selectedRows: Row<Organization>[]) => {
    return selectedRows.length ? (
      <DeleteButton>
        <Delete organizations={getRowsForDeletion(selectedRows)}></Delete>
      </DeleteButton>
    ) : (
      <ExportCsvButton {...csvProps}></ExportCsvButton>
    )
  }

  return (
    <Card>
      <DataTable toolBarContent={toolBarContent} {...tableInstance}></DataTable>
    </Card>
  )
}
