import * as React from 'react'
import { callAllHandlers } from 'core/utils'
import { useControllableProp } from './UseControllable'
import { useId } from './UseId'

export interface UseDisclosureProps {
  isOpen?: boolean
  defaultIsOpen?: boolean
  onClose?(): void
  onOpen?(): void
  id?: string
}

export function useDisclosure(props: UseDisclosureProps = {}) {
  const { onClose, onOpen, isOpen: isOpenProp, id: idProp } = props

  const [isOpenState, setIsOpen] = React.useState(props.defaultIsOpen || false)
  const [isControlled, isOpen] = useControllableProp(isOpenProp, isOpenState)

  const id = useId(idProp, 'disclosure')

  const close = React.useCallback(() => {
    if (!isControlled) {
      setIsOpen(false)
    }
    onClose?.()
  }, [isControlled, onClose])

  const open = React.useCallback(() => {
    if (!isControlled) {
      setIsOpen(true)
    }
    onOpen?.()
  }, [isControlled, onOpen])

  const toggle = React.useCallback(() => {
    const action = isOpen ? close : open
    action()
  }, [isOpen, open, close])

  return {
    isOpen,
    open,
    close,
    toggle,
    isControlled,
    getButtonProps: (props: any = {}) => ({
      ...props,
      'aria-expanded': 'true',
      'aria-controls': id,
      onClick: callAllHandlers(props.onClick, toggle),
    }),
    getDisclosureProps: (props: any = {}) => ({
      ...props,
      hidden: !isOpen,
      id,
    }),
  }
}

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>
