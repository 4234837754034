import * as React from 'react'
import {
  CategoryAmountFilter,
  CategoryAmountMovesFilter,
} from '../data/UseCategoryAmounts'
import {
  CategoryAmountSegmentInput,
  useCategoryAmountSegments,
} from '../data/UseCategoryAmountsSegments'

type CommonFilters = keyof CategoryAmountMovesFilter

export type UseCategoryAmountTableProps = Omit<
  CategoryAmountFilter,
  CommonFilters
>

export interface CategoryAmountTableFilter {
  organizationId?: string
  segments: CategoryAmountSegmentInput[]
}

export const useCategoryAmountTable = ({
  moveType,
  categoryOutcomeType,
}: UseCategoryAmountTableProps) => {
  const [fetch, state] = useCategoryAmountSegments({
    fetchPolicy: 'network-only',
  })

  const newFetch = React.useCallback(
    (filter: CategoryAmountTableFilter) => {
      fetch({
        variables: {
          data: {
            ...filter,
            moveType,
            categoryOutcomeType,
          },
        },
      })
    },
    [fetch, moveType, categoryOutcomeType],
  )

  return [newFetch, state] as [typeof newFetch, typeof state]
}
