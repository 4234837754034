import * as React from 'react'
import { Column } from 'react-table'
import { DataTable, useDataTable } from 'shared/common'
import { formatCurrency } from 'shared/utils'
import { AccountBalance } from 'modules/reports/account-balance/data/Queries'
import { CreditKind, creditKindLabels } from 'modules/credit/models/Credit'

interface AccountBalanceTableProps {
  data: AccountBalance[]
}

export const AccountBalanceTable = ({ data }: AccountBalanceTableProps) => {
  const columns = React.useMemo<Column<AccountBalance>[]>(() => {
    return [
      {
        id: 'account.type',
        Header: 'Tipo de Cuenta',
        accessor: ({ creditCard, bankAccount, credit }) => {
          if (creditCard) {
            return 'Tarjeta de crédito'
          }
          if (bankAccount) {
            return 'Cuenta de banco'
          }
          if (credit) {
            if (credit.kind !== CreditKind.creditor) {
              return `Crédito ${creditKindLabels[credit.kind]}`
            }
            return 'Crédito'
          }
          return 'Caja'
        },
      },
      {
        id: 'account.name',
        Header: 'Cuenta',
        accessor: ({ organization, creditCard, bankAccount, credit }) => {
          if (creditCard) {
            return creditCard.name
          }
          if (bankAccount) {
            return bankAccount.name
          }
          if (credit) {
            return credit.name
          }
          return organization?.name
        },
      },
      {
        Header: 'Saldo Inicial',
        accessor: 'initialBalance',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        Header: 'Saldo final',
        accessor: 'balance',
        Cell: ({ value }) => formatCurrency(value),
      },
    ]
  }, [])

  const tableInstance = useDataTable({
    pagination: false,
    selectable: false,
    columns,
    data,
    initialState: {
      sortBy: React.useMemo(() => [{ id: 'account.type' }], []),
    },
  })

  return <DataTable {...tableInstance}></DataTable>
}
