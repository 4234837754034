import { WithPermissions } from 'auth/components/WithPermissions'
import { useCrudContext } from './CrudPage/CrudContext'

export const DeleteButton: React.FC = ({ children }) => {
  const { name } = useCrudContext()

  return (
    <WithPermissions permissions={[`${name}.delete`]}>
      {children}
    </WithPermissions>
  )
}
