import { WithPermissions } from 'auth/components/WithPermissions'
import { Route } from 'react-router-dom'
import { useCrudContext } from './CrudContext'

export const EditPage: React.FC = ({ children }) => {
  const { url, name } = useCrudContext()

  return (
    <WithPermissions permissions={[`${name}.update`]}>
      <Route path={`${url}/edit/:id`}>{children}</Route>
    </WithPermissions>
  )
}
