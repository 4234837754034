import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client'
import {
  CategoryAmount,
  MoveType,
} from 'modules/category-move/models/CategoryMove'

export class CategoryAmountSegmentInput {
  name: string
  initDate: Date
  endDate: Date
}

export class CategoryAmountSegmentsFilter {
  moveType: MoveType
  categoryOutcomeType?: string
  organizationId?: string
  segments: CategoryAmountSegmentInput[]
}

export class CategoryAmountSegment {
  name: string
  results: CategoryAmount[]
}

export interface ListCategoryAmountSegmentsResponse {
  categoryAmountsSegments: CategoryAmountSegment[]
}

export interface ListCategoryAmountSegmentsVars {
  data: CategoryAmountSegmentsFilter
}

const QUERY = gql`
  query ListCategoryAmountsSegments($data: CategoryAmountSegmentsFilter!) {
    categoryAmountsSegments: listCategoryAmountsSegments(data: $data) {
      name
      results {
        id
        categoryId
        name
        amount
        parentId
      }
    }
  }
`

export const useCategoryAmountSegments = (
  options?: QueryHookOptions<
    ListCategoryAmountSegmentsResponse,
    ListCategoryAmountSegmentsVars
  >,
) => {
  return useLazyQuery<
    ListCategoryAmountSegmentsResponse,
    ListCategoryAmountSegmentsVars
  >(QUERY, options)
}
