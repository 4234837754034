import { createMuiTheme, ThemeOptions } from '@material-ui/core'

const theme = createMuiTheme()

export const mixins: ThemeOptions['mixins'] = {
  border: (width = 1) => ({
    borderWidth: width,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  }),
  borderLeft: (width = 1) => ({
    borderLeftWidth: width,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  }),
  borderRight: (width = 1) => ({
    borderRightWidth: width,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  }),
  borderTop: (width = 1) => ({
    borderTopWidth: width,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  }),
  borderBottom: (width = 1) => ({
    borderBottomWidth: width,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  }),
}
