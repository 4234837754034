import * as React from 'react'
import { useSafeDispatch } from '../hooks/UseSafeDispatch'
import { runIfFn } from 'core/utils'
import { createContext } from './React'

const errorMessage = (name: string, provider: 'Dispatch' | 'State') =>
  `useContext: ${name} ${provider} is undefined. Seems you forgot to wrap component within the ${name} Provider`

export type CreateReducerContextReturn<S, A> = [
  React.FC,
  () => S,
  () => React.Dispatch<A>,
]

export const createReducerContext = <S, A>(
  reducer: React.Reducer<S, A>,
  stateValueOrFunction: S | (() => S),
  name?: string,
): CreateReducerContextReturn<S, A> => {
  const initialState = () => ({ ...runIfFn(stateValueOrFunction) })

  const [StateProvider, useState] = createContext<S>({
    name,
    errorMessage: errorMessage(name, 'State'),
  })

  const [DispatchProvider, useDispatch] = createContext<React.Dispatch<A>>({
    name,
    errorMessage: errorMessage(name, 'Dispatch'),
  })

  const WrappedProvider: React.FC = function ({ children }) {
    const [state, unsecureDispatch] = React.useReducer(reducer, initialState())

    const dispatch = useSafeDispatch(unsecureDispatch)

    return (
      <StateProvider value={state}>
        <DispatchProvider value={dispatch}>{children}</DispatchProvider>
      </StateProvider>
    )
  }

  const useFactoryState = useState

  const useFactoryDispatch = useDispatch

  return [WrappedProvider, useFactoryState, useFactoryDispatch]
}
