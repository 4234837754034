import * as yup from 'yup'

yup.addMethod(yup.string, 'isOptional', function (emptyValue?: string) {
  return this.transform(value => {
    if (value === emptyValue) {
      return undefined
    }

    if (value) {
      return value
    }

    return undefined
  })
})
