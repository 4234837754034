import { generateNumberList } from 'core/utils'
import dayjs from 'dayjs'

export type DateRangeType = 'monthly' | 'yearly'
export interface DateRangeSegment {
  name: string
  initDate: Date
  endDate: Date
}

export const buildDateRange = (type: DateRangeType, ref?: dayjs.Dayjs) => {
  const date = ref ?? dayjs()

  if (type === 'monthly') {
    return {
      initDate: date.startOf('month').toDate(),
      endDate: date.endOf('month').toDate(),
    }
  }

  const year = date.year()
  return {
    initDate: dayjs().year(year).startOf('year').toDate(),
    endDate: date.endOf('month').toDate(),
  }
}

export const buildDateRangeSegments = (
  type: DateRangeType,
  ref?: dayjs.Dayjs,
): DateRangeSegment[] => {
  const date = ref ?? dayjs()
  const year = date.year()

  if (type === 'monthly') {
    const month = date.month()
    return generateNumberList(date.daysInMonth()).map((_, i) => {
      const day = i + 1
      return {
        name: day.toString().padStart(2, '0'),
        initDate: dayjs()
          .year(year)
          .month(month)
          .date(day)
          .startOf('date')
          .toDate(),
        endDate: dayjs()
          .year(year)
          .month(month)
          .date(day)
          .endOf('date')
          .toDate(),
      }
    })
  }

  return dayjs
    .months()
    .filter((_, i) => i <= date.month())
    .map((month, i) => {
      return {
        name: `${month} ${year}`,
        initDate: dayjs().year(year).month(i).startOf('month').toDate(),
        endDate: dayjs().year(year).month(i).endOf('month').toDate(),
      }
    })
}
