import { useMutation, gql } from '@apollo/client'
import { TypeCredit } from '../models/TypeCredit'
import { TypeCreditFragment } from './Queries'
import { Status } from 'models/BaseModel'

export interface ChangeTypeCreditStatusPayload {
  id: string
  status: Status
}

const CHANGE_TYPE_CREDIT_STATUS = gql`
  mutation ChangeTypeCreditStatus($data: ChangeTypeCreditStatusPayload!) {
    changeTypeCreditStatus(data: $data) {
      id
      status
    }
  }
`

export const useChangeTypeCreditStatusMutation = () => {
  const tuple = useMutation<
    { changeTypeCreditStatus: TypeCredit },
    { data: ChangeTypeCreditStatusPayload }
  >(CHANGE_TYPE_CREDIT_STATUS)

  return tuple
}

export interface UpdateTypeCreditPayload {
  id: string
  name: string
}

const UPDATE_TYPE_CREDIT = gql`
  mutation UpdateTypeCredit($data: UpdateTypeCreditPayload!) {
    updateTypeCredit(data: $data) {
      ...TypeCreditFragment
    }
  }
  ${TypeCreditFragment}
`

export const useUpdateTypeCreditMutation = () => {
  const tuple = useMutation<
    { updateTypeCredit: TypeCredit },
    { data: UpdateTypeCreditPayload }
  >(UPDATE_TYPE_CREDIT)

  return tuple
}

const DELETE_TYPE_CREDIT = gql`
  mutation DeleteTypeCredit($id: String!) {
    deleteTypeCredit(id: $id) {
      id
      status
    }
  }
`

export const useDeleteTypeCreditMutation = () => {
  const tuple = useMutation<{ deleteTypeCredit: TypeCredit }, { id: string }>(
    DELETE_TYPE_CREDIT,
  )

  return tuple
}

export interface RegisterTypeCreditPayload {
  name: string
}

const REGISTER_TYPE_CREDIT = gql`
  mutation CreateTypeCredit($data: RegisterTypeCreditPayload!) {
    registerTypeCredit(data: $data) {
      ...TypeCreditFragment
    }
  }
  ${TypeCreditFragment}
`

export const useRegisterTypeCreditMutation = () => {
  const tuple = useMutation<
    { registerTypeCredit: TypeCredit },
    { data: RegisterTypeCreditPayload }
  >(REGISTER_TYPE_CREDIT)

  return tuple
}
