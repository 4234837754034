import * as React from 'react'
import { Column } from 'react-table'
import { DataTable, useDataTable } from 'shared/common'
import { formatCurrency } from 'shared/utils'
import { CircularProgress } from '@material-ui/core'
import { useCompareCategoryAmounts } from '../data/UseCompareCategoryAmounts'
import {
  buildCategoryAmountCompareTree,
  CategoryAmountCompareElement,
} from '../utils/CategoryAmountCompareTree'

export type CategoryAmountCompareTableProps = ReturnType<
  typeof useCompareCategoryAmounts
>[1]

export const CategoryAmountCompareTable = ({
  loading,
  data,
  firstTitle,
  secondTitle,
}: CategoryAmountCompareTableProps & {
  firstTitle?: string
  secondTitle?: string
}) => {
  const { categoryAmounts } = data ?? {}

  const rows = React.useMemo(() => {
    if (!categoryAmounts) {
      return []
    }
    return buildCategoryAmountCompareTree(categoryAmounts)
  }, [categoryAmounts])

  const columns = React.useMemo<Column<CategoryAmountCompareElement>[]>(() => {
    return [
      {
        id: 'name',
        Header: 'Categoría',
        accessor: ({ name }) => name,
        Cell: ({ value, row }) => (
          <DepthCell type="string" depth={row.depth}>
            {value}
          </DepthCell>
        ),
      },
      {
        id: 'first',
        Header: () => (
          <span className="block w-100 text-right">{firstTitle}</span>
        ),
        accessor: ({ first }) => formatCurrency(parseFloat(first)),
        Cell: ({ value, row }) => (
          <DepthCell type="number" depth={row.depth}>
            {value}
          </DepthCell>
        ),
      },
      {
        id: 'second',
        Header: () => (
          <span className="block w-100 text-right">{secondTitle}</span>
        ),
        accessor: ({ second }) => formatCurrency(parseFloat(second)),
        Cell: ({ value, row }) => (
          <DepthCell type="number" depth={row.depth}>
            {value}
          </DepthCell>
        ),
      },
      {
        id: 'diff',
        Header: () => (
          <span className="block w-100 text-right">Diferencia</span>
        ),
        accessor: ({ diff }) => formatCurrency(parseFloat(diff)),
        Cell: ({ value, row }) => (
          <DepthCell type="number" depth={row.depth}>
            {value}
          </DepthCell>
        ),
      },
    ]
  }, [firstTitle, secondTitle])

  const tableInstance = useDataTable({
    pagination: false,
    selectable: false,
    getSubRows: row => row.children,
    columns,
    data: rows,
    initialState: {
      sortBy: React.useMemo(() => [{ id: 'name' }], []),
    },
  })

  if (loading) {
    return <CircularProgress></CircularProgress>
  }

  return <DataTable {...tableInstance}></DataTable>
}

interface DepthCellProps {
  type: 'number' | 'string'
  depth: number
}

function DepthCell({
  type,
  depth,
  children,
}: React.PropsWithChildren<DepthCellProps>) {
  return (
    <span
      className="block w-100"
      style={{
        fontSize: `${2 - depth * 0.25}rem`,
        fontWeight: 600 - 100 * depth,
        paddingLeft: type === 'string' && `${depth}rem`,
        textAlign: type === 'number' ? 'right' : 'left',
      }}
    >
      {children}
    </span>
  )
}
