import { useDisclosure } from 'shared/hooks'
import * as React from 'react'
import { createContext } from 'shared/utils'

export interface NavbarState {
  isFolded: boolean
  isNavbarOpen: boolean
  isNavbarClose: boolean
  foldedAndClosed: boolean
  foldedAndOpened: boolean
  closeNavbar: () => void
  openNavbar: () => void
  toggleFolded: () => void
}

const name = 'NavbarContext'

const [Provider, useNavbarContext] = createContext<NavbarState>({
  name,
  errorMessage: `useContext: ${name} is undefined. Seems you forgot to wrap component within the Provider`,
})

const NavbarProvider: React.FC = ({ children }) => {
  const { isOpen, open, close } = useDisclosure()
  const { isOpen: isFolded, toggle: toggleFolded } = useDisclosure()

  const state = React.useMemo(() => {
    return {
      isFolded,
      isNavbarOpen: isOpen,
      isNavbarClose: !isOpen,
      foldedAndClosed: isFolded && !isOpen,
      foldedAndOpened: isFolded && isOpen,
      closeNavbar: close,
      openNavbar: open,
      toggleFolded,
    }
  }, [isOpen, close, open, isFolded, toggleFolded])

  return <Provider value={state}>{children}</Provider>
}

export { NavbarProvider, useNavbarContext }
