import * as React from 'react'
import dayjs from 'dayjs'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import { useUserList } from '../data/Queries'
import { User } from 'auth/models/User'
import { Column, Row } from 'react-table'
import { EditButton } from 'shared/crud/EditButton'
import { getFullName } from '../utils'
import { StatusUpdate } from './StatusUpdate'
import { Delete } from './Delete'
import { Card, IconButton, Tooltip } from '@material-ui/core'
import { DeleteButton } from 'shared/crud/DeleteButton'
import { VpnKey } from '@material-ui/icons'
import { useDisclosure } from 'shared/hooks'
import { ChangeUserPassword } from './ChangeUserPassword'

export const List = () => {
  const { data: userListData } = useUserList({
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const columns = React.useMemo<Column<User>[]>(() => {
    return [
      {
        id: 'fullName',
        Header: 'Nombre',
        accessor: user => getFullName(user),
      },
      {
        Header: 'Correo electrónico',
        accessor: 'email',
      },
      {
        Header: 'Fecha de creación',
        accessor: 'createdAt',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        disableSortBy: true,
        Header: 'Estatus',
        accessor: user => <StatusUpdate user={user}></StatusUpdate>,
      },
      {
        disableSortBy: true,
        Header: 'Acciones',
        accessor: user => (
          <div className="flex">
            <div className="mr-6">
              <EditButton
                title="Editar usuario"
                aria-label="editar usuario"
                id={user.id}
                tenantId={user.id}
              ></EditButton>
            </div>
            <ChangePasswordAction user={user}></ChangePasswordAction>
          </div>
        ),
      },
    ]
  }, [])

  const data = React.useMemo(() => {
    return userListData?.listUser ?? []
  }, [userListData])

  const tableInstance = useDataTable({
    columns,
    data,
  })

  const toolBarContent = (selectedRows: Row<User>[]) => {
    return selectedRows.length ? (
      <DeleteButton>
        <Delete users={selectedRows?.map(row => row.original)}></Delete>
      </DeleteButton>
    ) : null
  }

  return (
    <Card>
      <DataTable toolBarContent={toolBarContent} {...tableInstance}></DataTable>
    </Card>
  )
}

function ChangePasswordAction({ user }: { user: User }) {
  const { isOpen, open, close } = useDisclosure({ defaultIsOpen: false })

  return (
    <>
      <Tooltip title="Cambiar contraseña">
        <IconButton onClick={open}>
          <VpnKey></VpnKey>
        </IconButton>
      </Tooltip>
      <ChangeUserPassword
        isOpen={isOpen}
        onClose={close}
        userId={user.id}
        name={`${user.firstName} ${user.lastName}`}
      ></ChangeUserPassword>
    </>
  )
}
