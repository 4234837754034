import { CrudPage } from 'shared/crud/CrudPage'
import { ListPage } from 'shared/crud/CrudPage/ListPage'
import { Create } from './components/Create'
import { Update } from './components/Update'
import { List } from './components/List'
import { AddPage } from 'shared/crud/CrudPage/AddPage'
import { EditPage } from 'shared/crud/CrudPage/EditPage'
import { useRouteMatch } from 'react-router-dom'
import { MoveType, moveTypes } from './models/CategoryMove'

const getListType = (filter: string) => {
  const label = moveTypes.find(ct => ct.value === filter)?.text

  return label ? ` - (${label})` : ''
}

export const CategoryMovePages = () => {
  const { url } = useRouteMatch()

  const urlParts = url.slice(1).split('/')

  const filter = urlParts[1] as MoveType

  return (
    <CrudPage name="categoryMove" url={url}>
      <ListPage
        title={`Listado de categorías de movimientos ${getListType(filter)}`}
      >
        <List moveType={filter}></List>
      </ListPage>
      <AddPage>
        <Create type={filter}></Create>
      </AddPage>
      <EditPage>
        <Update type={filter}></Update>
      </EditPage>
    </CrudPage>
  )
}
