import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { BankAccount } from '../models/BankAccount'

const LIST_BANK_ACCOUNT = gql`
  query {
    listBankAccount {
      id
      accountNumber
      name
      ownerName
      account {
        id
        initialBalance
        balance
      }
      bank {
        id
        name
        status
      }
      accountType
      status
      createdAt
      tenantId
    }
  }
`
export type ListBankAccountResponse = { listBankAccount: BankAccount[] }

export const useBankAccountList = (
  options?: QueryHookOptions<ListBankAccountResponse>,
) => {
  const result = useQuery<ListBankAccountResponse>(LIST_BANK_ACCOUNT, options)
  return result
}

const GET_BANK_ACCOUNT = gql`
  query GetBankAccount($id: String!) {
    getBankAccount(id: $id) {
      id
      accountNumber
      name
      ownerName
      account {
        id
        initialBalance
        balance
      }
      bank {
        id
        name
        status
      }
      accountType
      status
      tenantId
    }
  }
`
export type GetBankAccountResponse = { getBankAccount: BankAccount }
export interface GetBankAccountVars {
  id: string
}

export const useGetBankAccount = (
  options?: QueryHookOptions<GetBankAccountResponse, GetBankAccountVars>,
) => {
  const result = useQuery<GetBankAccountResponse, GetBankAccountVars>(
    GET_BANK_ACCOUNT,
    options,
  )
  return result
}
