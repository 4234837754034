import * as React from 'react'
import { CrudPage } from 'shared/crud/CrudPage'
import { ListPage } from 'shared/crud/CrudPage/ListPage'
import { Create } from './components/Create'
import { Update } from './components/Update'
import { List } from './components/List'
import { AddPage } from 'shared/crud/CrudPage/AddPage'
import { EditPage } from 'shared/crud/CrudPage/EditPage'
import { useRouteMatch } from 'react-router-dom'
import { companyTypes } from './models/Company'

const getListType = (filter: string) => {
  const label = companyTypes.find(ct => ct.value === filter)?.text

  return label ? ` - (${label})` : ''
}

export const CompanyPages = () => {
  const { url } = useRouteMatch()

  const urlParts = url.slice(1).split('/')

  const filter = urlParts[1]

  return (
    <CrudPage name="company" url={url}>
      <ListPage title={`Listado de compañías ${getListType(filter)}`}>
        <List companyType={filter}></List>
      </ListPage>
      <AddPage>
        <Create></Create>
      </AddPage>
      <EditPage>
        <Update></Update>
      </EditPage>
    </CrudPage>
  )
}
