import * as React from 'react'
import { AutocompleteForm, AutocompleteFormProps } from 'shared/forms'
import { useOrganizationList } from '../data/Queries'
import { Organization } from '../models/Organization'
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from '@material-ui/lab'
import { TextField, TextFieldProps } from '@material-ui/core'

export interface OrganizationAutocompleteProps<
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
> extends Omit<
    AutocompleteFormProps<Organization, Multiple, DisableClearable, FreeSolo>,
    'options' | 'loading'
  > {
  onOptionsLoaded?: (options: Organization[]) => void
}

export const OrganizationAutocomplete = <
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
>({
  getOptionLabel = option => option.name,
  getOptionSelected = (option, value) => option.id === value?.id,
  onOptionsLoaded,
  ...props
}: OrganizationAutocompleteProps<Multiple, DisableClearable, FreeSolo>) => {
  const { loading, data } = useOrganizationList({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      onOptionsLoaded?.(data.listOrganization)
    },
  })

  const options = React.useMemo(() => {
    return data?.listOrganization ?? []
  }, [data])

  return (
    <AutocompleteForm
      loading={loading}
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      {...props}
    ></AutocompleteForm>
  )
}

export interface OrganizationAutocompleteInputProps<
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
> extends Omit<
    AutocompleteProps<Organization, Multiple, DisableClearable, FreeSolo>,
    'options' | 'loading' | 'renderInput'
  > {
  onOptionsLoaded?: (options: Organization[]) => void
  inputProps?: TextFieldProps
}

export const OrganizationAutocompleteInput = <
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
>({
  getOptionLabel = option => option.name,
  getOptionSelected = (option, value) => option.id === value?.id,
  onOptionsLoaded,
  inputProps,
  ...props
}: OrganizationAutocompleteInputProps<
  Multiple,
  DisableClearable,
  FreeSolo
>) => {
  const { loading, data } = useOrganizationList({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      onOptionsLoaded?.(data.listOrganization)
    },
  })

  const options = React.useMemo(() => {
    return data?.listOrganization ?? []
  }, [data])

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return <TextField {...inputProps} {...params}></TextField>
  }

  return (
    <Autocomplete
      loading={loading}
      options={options}
      renderInput={renderInput}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      {...props}
    ></Autocomplete>
  )
}
