import * as React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Form, TextFieldForm } from 'shared/forms'
import { useUpdateCreditMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import { useGetCredit } from '../data/Queries'
import { TypeCredit } from 'modules/type-credit/models/TypeCredit'
import { Company, CompanyType } from 'modules/company/models/Company'
import { Organization } from 'modules/organization/models/Organization'
import { TypeCreditAutocomplete } from 'modules/type-credit/components/TypeCreditAutocomplete'
import { OrganizationAutocomplete } from 'modules/organization/components/OrganizationAutcomplete'
import { CompanyAutocomplete } from 'modules/company/components/CompanyAutocomplete'
import { CurrencyTextField } from 'shared/common/CurrencyTextField'
import { WithPermissions } from 'auth/components/WithPermissions'

interface UpdateForm {
  name: string
  typeCredit: TypeCredit
  organization: Organization
  company: Company
  initialBalance?: number
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre es un campo requerido'),
  typeCredit: yup
    .object()
    .nullable()
    .required('Debe asignar un tipo de crédito al crédito'),
  organization: yup
    .object()
    .nullable()
    .required('Debe asignar una organización al crédito'),
  company: yup
    .object()
    .nullable()
    .required('Debe asignar un acreedor al crédito'),
  initialBalance: yup
    .number()
    .min(0, 'La deuda inicial no puede ser negativa')
    .nullable(),
})

const formId = 'UpdateCredit'
const dialogId = 'CreditEditFormDialog'

export const Update = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const { id } = useParams<{ id: string }>()

  const { data, loading: isGetting, error } = useGetCredit({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
  const [updateCredit, { loading: isUpdating }] = useUpdateCreditMutation()

  const loading = isGetting || isUpdating

  const credit = React.useMemo(() => {
    return data?.getCredit
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<UpdateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      typeCredit: null,
      organization: null,
      company: null,
      initialBalance: null,
    },
  })
  const { formState, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (credit) {
      reset({
        ...credit,
        initialBalance: null,
      })
    }
  }, [credit, reset])

  React.useEffect(() => {
    if (error) {
      console.warn(error)

      let msg =
        'Ha ocurrido un error al obtener el tipo de crédito. Intentelo más tarde'

      if (error.message === 'Credit not found') {
        msg = 'Tipo de crédito no encontrado'
      }

      enqueueSnackbar(msg, { variant: 'error' })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, enqueueSnackbar])

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/credits')
  }

  const onSubmit: SubmitHandler<UpdateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const { typeCredit, company, organization, ...data } = values

      await updateCredit({
        variables: {
          data: {
            id: credit.id,
            typeCreditId: typeCredit?.id,
            creditorId: company?.id,
            organizationId: organization?.id,
            ...data,
          },
        },
      })

      enqueueSnackbar('Tipo de crédito actualizado correctamente', {
        variant: 'success',
      })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        'Ha ocurrido un error al actualizar el tipo de crédito. Intentelo más tarde',
        { variant: 'error' },
      )
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableEnforceFocus
      disableBackdropClick
      open={isOpen}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Editar tipo de crédito</DialogTitle>
      <DialogContent>
        <Form {...formInstance} onSubmit={onSubmit} formProps={{ id: formId }}>
          <TextFieldForm
            name="name"
            label="Nombre del crédito"
            placeholder="Ingresar nombre del crédito"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            autoFocus
            disabled={loading}
          />
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TypeCreditAutocomplete
                name="typeCredit"
                inputProps={{
                  label: 'Tipo de crédito',
                  variant: 'outlined',
                  required: true,
                }}
                placeholder="Buscar Tipo de crédito"
                className="mb-16"
                fullWidth
              ></TypeCreditAutocomplete>
            </Grid>
            <Grid xs={6} item>
              <OrganizationAutocomplete
                name="organization"
                inputProps={{
                  label: 'Organización',
                  variant: 'outlined',
                  required: true,
                }}
                placeholder="Buscar organización"
                className="mb-16"
                fullWidth
              ></OrganizationAutocomplete>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <CompanyAutocomplete
                name="company"
                type={[CompanyType.creditor]}
                inputProps={{
                  label: 'Acreedor',
                  variant: 'outlined',
                  required: true,
                }}
                placeholder="Buscar Acreedor"
                className="mb-16"
                fullWidth
              ></CompanyAutocomplete>
            </Grid>
            <Grid xs={6} item>
              <WithPermissions permissions={[]}>
                <TextFieldForm
                  TextComponent={CurrencyTextField}
                  name="initialBalance"
                  label="Deuda inicial"
                  placeholder="Ingresar deuda inicial"
                  variant="outlined"
                  className="mb-16"
                  fullWidth
                  disabled={loading}
                />
              </WithPermissions>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
