import { parseDate } from 'core/utils'
import { TenantModel } from 'models/TenantModel'
import { AccountPayment } from 'modules/account/models/AccountPayment'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { CategoryMove } from 'modules/category-move/models/CategoryMove'
import { Company } from 'modules/company/models/Company'
import { CreditCard } from 'modules/credit-card/models/CreditCard'
import { Credit } from 'modules/credit/models/Credit'
import { Organization } from 'modules/organization/models/Organization'

export enum PaymentMethod {
  cash = 'cash',
  transfer = 'transfer',
  deposit = 'deposit',
  check = 'check',
  credit_card = 'credit_card',
  debit_card = 'debit_card',
  credit = 'credit',
  charge = 'charge',
}

export const outcomePaymentMethodsLabels: { [key in PaymentMethod]: string } = {
  cash: 'Efectivo',
  transfer: 'Transferencia',
  deposit: 'Depósito',
  check: 'Cheque',
  credit_card: 'Tarjeta de crédito',
  debit_card: 'Tarjeta de débito',
  credit: 'Crédito',
  charge: 'Cargo',
}

export const outcomePaymentMethodsOptions: {
  text: string
  value: PaymentMethod
}[] = Object.keys(PaymentMethod).map(key => ({
  text: outcomePaymentMethodsLabels[key as PaymentMethod],
  value: key as PaymentMethod,
}))

export enum OutcomeType {
  expense = 'expense',
  credit = 'credit',
  creditCard = 'creditCard',
}

export const outcomeTypes: { text: string; value: OutcomeType }[] = [
  {
    text: 'Gasto',
    value: OutcomeType.expense,
  },
  {
    text: 'Pago a crédito',
    value: OutcomeType.credit,
  },
  {
    text: 'Pago a tarjeta de crédito',
    value: OutcomeType.creditCard,
  },
]

export class Outcome extends TenantModel {
  date: Date

  amount: number

  company: Company

  paymentMethod: PaymentMethod

  category: CategoryMove

  organization: Organization

  bankAccount: BankAccount

  creditCard: CreditCard

  notes: string

  hasTaxReceipt?: boolean

  isDirectDebit?: boolean

  outcomeType: OutcomeType

  accountPayment: AccountPayment

  credit: Credit

  creditCardToPay: CreditCard

  constructor(data: Partial<Outcome>) {
    super(data)
    const {
      date,
      company,
      category,
      organization,
      bankAccount,
      creditCard,
      credit,
      accountPayment,
      ...outcome
    } = data

    if (date) {
      this.date = parseDate(date)
    }

    if (company) {
      this.company = new Company(company)
    }

    if (category) {
      this.category = new CategoryMove(category)
    }

    if (organization) {
      this.organization = new Organization(organization)
    }

    if (bankAccount) {
      this.bankAccount = new BankAccount(bankAccount)
    }

    if (creditCard) {
      this.creditCard = new CreditCard(creditCard)
    }

    if (credit) {
      this.credit = new Credit(credit)
    }

    if (accountPayment) {
      this.accountPayment = new AccountPayment(accountPayment)
      if (accountPayment.credit) {
        this.credit = new Credit(accountPayment.credit)
      }
      if (accountPayment.creditCard) {
        this.creditCardToPay = new CreditCard(accountPayment.creditCard)
      }
    }

    Object.assign(this, outcome)
  }
}
