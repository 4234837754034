import { TenantModel } from 'models/TenantModel'
import { Account } from 'modules/account/models/Account'
import { Bank } from 'modules/bank/models/Bank'

export enum AccountType {
  checking_account = 'checking_account',
  saving_account = 'saving_account',
  investment_account = 'investment_account',
}

export const accountTypes: { text: string; value: AccountType }[] = [
  {
    text: 'Cheques',
    value: AccountType.checking_account,
  },
  {
    text: 'Ahorro',
    value: AccountType.saving_account,
  },
  {
    text: 'Inversión',
    value: AccountType.investment_account,
  },
]

export class BankAccount extends TenantModel {
  accountNumber: string
  name: string
  ownerName: string
  accountType: AccountType
  bank: Bank
  account: Account

  constructor(data: Partial<BankAccount> = {}) {
    super(data)
    const { bank, account, ...card } = data

    if (bank) {
      this.bank = new Bank(bank)
    }

    if (account) {
      this.account = new Account(account)
    }

    Object.assign(this, card)
  }
}
