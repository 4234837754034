import * as React from 'react'
import { useControllableProp } from 'shared/hooks'
import { useTransition, animated } from 'react-spring'

export const Fade: React.FC<{ in?: boolean }> = ({ children, in: inProp }) => {
  const [mounted, setMounted] = React.useState(false)

  const [isControlled, inState] = useControllableProp(inProp, mounted)

  React.useEffect(() => {
    if (!isControlled) {
      setMounted(true)
    }
  }, [isControlled])

  const transitions = useTransition(inState, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <>
      {transitions.map(({ item, props, key }) => {
        return (
          item && (
            <animated.div
              key={key}
              style={{ width: '100%', height: '100%', ...props }}
            >
              {children}
            </animated.div>
          )
        )
      })}
    </>
  )
}
