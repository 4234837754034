import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

export interface TransferListProps<T> {
  options: T[]
  initialValue?: T[]
  choicesLabel?: string
  chosenLabel?: string
  disabled?: boolean
  getOptionLabel: (item: T) => string
  onChosenChange?: (chosen: T[]) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
  }),
)

function not<T>(a: T[], b: T[]) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection<T>(a: T[], b: T[]) {
  return a.filter(value => b.indexOf(value) !== -1)
}

function union<T>(a: T[], b: T[]) {
  return [...a, ...not(b, a)]
}

export const TransferList = <T,>(props: TransferListProps<T>) => {
  const {
    choicesLabel,
    chosenLabel,
    options,
    initialValue = [],
    getOptionLabel,
    onChosenChange,
    disabled,
  } = props
  const classes = useStyles()
  const [checked, setChecked] = React.useState<T[]>([])
  const [left, setLeft] = React.useState<T[]>(() => not(options, initialValue))
  const [right, setRight] = React.useState<T[]>(initialValue)

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value: T) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = (items: T[]) => intersection(checked, items).length

  const handleToggleAll = (items: T[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedRight = () => {
    updateRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    updateRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const updateRight = (items: T[]) => {
    setRight(items)
    onChosenChange?.(items)
  }

  const customList = (title: React.ReactNode, input: T[]) => {
    const items = [...input]
    items.sort((a, b) => {
      const labelA = getOptionLabel(a)
      const labelB = getOptionLabel(b)
      if (labelA > labelB) {
        return 1
      }
      if (labelA < labelB) {
        return -1
      }
      // a must be equal to b
      return 0
    })
    return (
      <Card elevation={6}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              color="primary"
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'Todos los elementos seleccionados' }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items.length} seleccionados`}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {items.map((value: T) => {
            const labelId = `transfer-list-all-item-${value}-label`
            const label = getOptionLabel(value)

            return (
              <ListItem
                key={label}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={label} />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </Card>
    )
  }

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid
        item
        style={{
          flex: 1,
        }}
      >
        {customList(choicesLabel || 'Opciones', left)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="contained"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={disabled || leftChecked.length === 0}
            aria-label="Mover seleccionados a la derecha"
          >
            &gt;
          </Button>
          <Button
            variant="contained"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={disabled || rightChecked.length === 0}
            aria-label="Mover seleccionados a la izquierda"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          flex: 1,
        }}
      >
        {customList(chosenLabel || 'Elegidos', right)}
      </Grid>
    </Grid>
  )
}
