import * as React from 'react'
import { Layout } from 'layout/Layout'
import { LayoutContentWrapper, LayoutContent } from 'layout/components'

export const PublicLayout: React.FC = props => {
  return (
    <Layout>
      <LayoutContentWrapper>
        <LayoutContent scrollToTopOnRouteChange>{props.children}</LayoutContent>
      </LayoutContentWrapper>
    </Layout>
  )
}
