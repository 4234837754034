import * as React from 'react'
import { AutocompleteForm, AutocompleteFormProps } from 'shared/forms'
import { useBankAccountList } from '../data/Queries'
import { BankAccount } from '../models/BankAccount'

export interface BankAccountAutocompleteProps<
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
> extends Omit<
    AutocompleteFormProps<BankAccount, Multiple, DisableClearable, FreeSolo>,
    'options' | 'loading'
  > {
  onOptionsLoaded?: (options: BankAccount[]) => void
}

export const BankAccountAutocomplete = <
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
>({
  getOptionLabel = option => option.name,
  getOptionSelected = (option, value) => option.id === value.id,
  onOptionsLoaded,
  ...props
}: BankAccountAutocompleteProps<Multiple, DisableClearable, FreeSolo>) => {
  const { loading, data } = useBankAccountList({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      onOptionsLoaded?.(data.listBankAccount)
    },
  })

  const options = React.useMemo(() => {
    return data?.listBankAccount ?? []
  }, [data])

  return (
    <AutocompleteForm
      loading={loading}
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      {...props}
    ></AutocompleteForm>
  )
}
