import { Permission } from 'auth/models/Permission'
import * as React from 'react'
import { TransferList } from 'shared/common/TransferList/TransferList'
import { usePermissionsList } from '../data/Queries'

export interface PermissionsTransferListProps {
  initialValue?: Permission[]
  onSelectionChanged: (selected: Permission[]) => void
}

export const PermissionsTransferList = ({
  initialValue,
  onSelectionChanged,
}: PermissionsTransferListProps) => {
  const { loading, data } = usePermissionsList({
    fetchPolicy: 'network-only',
  })

  const options = React.useMemo(() => {
    return data?.listPermissions ?? []
  }, [data])

  return loading || !options.length ? (
    <>loading</>
  ) : (
    <TransferList
      initialValue={
        initialValue &&
        options.filter(option => initialValue.find(iv => iv.id === option.id))
      }
      options={options}
      getOptionLabel={item => item.label}
      onChosenChange={onSelectionChanged}
    ></TransferList>
  )
}
