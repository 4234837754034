import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client'
import { AccountTransfer } from 'modules/account/models/AccountTransfer'

export interface ListTransferResponse {
  listTransfer: AccountTransfer[]
}

export interface ListAccountTransferPayload {
  organizationId?: string
  bankAccountId?: string
  initDate: Date
  endDate: Date
}

export const ListTransferFragment = gql`
  fragment ListTransferFragment on AccountTransfer {
    id
    date
    notes
    source {
      amount
      account {
        bankAccount {
          name
        }
        organization {
          name
        }
      }
    }
    target {
      amount
      account {
        bankAccount {
          name
        }
        organization {
          name
        }
      }
    }
    status
    createdAt
  }
`

const LIST_TRANSFER = gql`
  query ListTransfer($data: ListAccountTransferPayload!) {
    listTransfer(data: $data) {
      ...ListTransferFragment
    }
  }
  ${ListTransferFragment}
`

export const useTransferList = (
  options?: QueryHookOptions<
    ListTransferResponse,
    { data: ListAccountTransferPayload }
  >,
) => {
  const result = useLazyQuery<
    ListTransferResponse,
    { data: ListAccountTransferPayload }
  >(LIST_TRANSFER, options)
  return result
}
