import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Form, SelectForm, TextFieldForm } from 'shared/forms'
import { useUpdateCreditCardMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import { useGetCreditCard } from '../data/Queries'
import { BankSelect } from 'modules/bank/components/BankSelect'
import { generateNumberList } from 'core/utils'
import { CurrencyTextField } from 'shared/common/CurrencyTextField'
import { WithPermissions } from 'auth/components/WithPermissions'

interface UpdateForm {
  accountNumber: string
  name: string
  ownerName: string
  bankId: string
  endDay: number
  limitDay: number
  creditLimit: number
  initialBalance?: number
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre de la tarjeta es un campo requerido'),
  accountNumber: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(4, 'El mínimo de caracteres es ${min}')
    .required('Los últimos 4 digitos son un campo requerido'),
  ownerName: yup
    .string()
    .required('El nombre del titular es un campo requerido'),
  endDay: yup
    .number()
    // eslint-disable-next-line no-template-curly-in-string
    .max(31, 'El día de corte debe ser menor a ${max}')
    .required('El día de corte es un campo requerido'),
  limitDay: yup
    .number()
    // eslint-disable-next-line no-template-curly-in-string
    .max(31, 'El día límite de pago debe ser menor a ${max}')
    .required('El día límite de pago es un campo requerido'),
  creditLimit: yup
    .number()
    .required('El límite de crédito es un campo requerido'),
  bankId: yup.string().required('El banco es un campo requerido'),
  initialBalance: yup
    .number()
    .min(0, 'El saldo inicial no puede ser negativo')
    .nullable(),
})

const formId = 'UpdateCreditCard'
const dialogId = 'CreditCardEditFormDialog'

const days = generateNumberList(31).map(i => i + 1)

export const Update = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const { id } = useParams<{ id: string }>()

  const { data, loading: isGetting, error } = useGetCreditCard({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
  const [
    updateCreditCard,
    { loading: isUpdating },
  ] = useUpdateCreditCardMutation()

  const loading = isGetting || isUpdating

  const card = React.useMemo(() => {
    return data?.getCreditCard
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<UpdateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      accountNumber: '',
      name: '',
      ownerName: '',
      bankId: '',
      endDay: 1,
      limitDay: 1,
      creditLimit: 1000,
      initialBalance: null,
    },
  })
  const { formState, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (card) {
      const { bank, ...cardData } = card
      reset({ ...cardData, bankId: bank.id, initialBalance: null })
    }
  }, [card, reset])

  React.useEffect(() => {
    if (error) {
      console.warn(error)

      let msg =
        'Ha ocurrido un error al obtener la tarjeta. Intentelo más tarde'

      if (error.message === 'CreditCard not found') {
        msg = 'Tarjeta no encontrado'
      }

      enqueueSnackbar(msg, { variant: 'error' })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, enqueueSnackbar])

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/creditcards')
  }

  const onSubmit: SubmitHandler<UpdateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      await updateCreditCard({
        variables: { data: { id: card.id, ...values } },
      })

      enqueueSnackbar('Tarjeta actualizada correctamente', {
        variant: 'success',
      })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        'Ha ocurrido un error al actualizar la tarjeta. Intentelo más tarde',
        { variant: 'error' },
      )
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      open={isOpen}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Editar tarjeta</DialogTitle>
      <DialogContent>
        <Form {...formInstance} onSubmit={onSubmit} formProps={{ id: formId }}>
          <Grid container spacing={2}>
            <Grid xs={8} item>
              <TextFieldForm
                name="name"
                label="Nombre de la tarjeta"
                placeholder="Ingresar nombre"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                autoFocus
                disabled={loading}
              />
            </Grid>
            <Grid xs={4} item>
              <TextFieldForm
                name="accountNumber"
                label="Últimos 4 digitos"
                placeholder="Ingresar últimos 4 digitos"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                transform={{
                  output: ev =>
                    /^[0-9]+$/.test(ev.target.value)
                      ? ev.target.value
                      : ev.target.value.slice(0, -1),
                }}
                inputProps={{
                  maxLength: 4,
                }}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <TextFieldForm
            name="ownerName"
            label="Titular"
            placeholder="Ingresar titular"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            disabled={loading}
          />
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="creditLimit"
                label="Límite de crédito"
                placeholder="Ingresar límite de crédito"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <BankSelect
                name="bankId"
                disabled={loading}
                defaultBank={card?.bank}
              ></BankSelect>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <SelectForm
                name="endDay"
                label="Día de corte"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              >
                {days.map(day => {
                  return (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  )
                })}
              </SelectForm>
            </Grid>
            <Grid xs={6} item>
              <SelectForm
                name="limitDay"
                label="Día límite de pago"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              >
                {days.map(day => {
                  return (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  )
                })}
              </SelectForm>
            </Grid>
            <WithPermissions permissions={[]}>
              <TextFieldForm
                TextComponent={CurrencyTextField}
                name="initialBalance"
                label="Saldo inicial"
                placeholder="Ingresar saldo inicial"
                variant="outlined"
                className="mb-16"
                fullWidth
                disabled={loading}
              />
            </WithPermissions>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
