import * as React from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { useNavbarMobileContext } from 'layout/contexts'

export const useNavigationElement = (nestedLevel: number) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const { closeNavbarMobile } = useNavbarMobileContext()

  const state = React.useMemo(() => {
    return {
      itemPadding: nestedLevel > 0 ? 40 + nestedLevel * 16 : 24,
      closeNavbar: () => mdDown && closeNavbarMobile(),
    }
  }, [nestedLevel, mdDown, closeNavbarMobile])

  return state
}
