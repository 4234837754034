export interface CsvParserConfig<T> {
  data: T[]
  columns: CsvColumn<T>[]
}

export interface CsvColumn<T> {
  id: string
  header?: string
  multiline?: boolean
  cell: (row: T) => string | number
}

export type CsvRow = Array<any>
export type CsvRows = Array<CsvRow>

export const parseArrayToCsv = <T>(config: CsvParserConfig<T>) => {
  const { columns, data } = config

  const rows: CsvRows = [columns.map(col => col.header ?? col.id)]

  for (const item of data) {
    rows.push(
      columns.map(col => {
        let cellContent = `${col.cell(item) ?? ''}`

        if (col.multiline || cellContent.includes(',')) {
          cellContent = cellContent.replace(/"/g, '""')
          return `"${cellContent}"`
        }

        return cellContent
      }),
    )
  }

  return rows.map(row => row.join(',')).join('\n')
}
