import { NavigationElement } from '../types'
import { NavCollapse } from './NavCollapse'
import { NavGroup } from './NavGroup'
import { NavItem } from './NavItem'
import { NavLink } from './NavLink'
import { NavProps } from './types'

import { WithPermissions } from 'auth/components/WithPermissions'

function getPermissions(item: NavigationElement) {
  const permissionSet = new Set<string>()

  let permissions: string[] = []
  if (item.type === 'item' || item.type === 'link') {
    permissions = item.permissions ?? []
  } else if (item.type !== 'divider') {
    for (const child of item.children) {
      permissions = [...permissions, ...getPermissions(child)]
    }
  }

  permissions.forEach(permission => {
    permissionSet.add(permission)
  })

  return Array.from(permissionSet)
}

export const NavigationItemWrapper = (props: NavProps<NavigationElement>) => {
  const { item, nestedLevel } = props

  const permissions = getPermissions(item)

  const renderItem = () => {
    if (item.type === 'item') {
      return <NavItem item={item} nestedLevel={nestedLevel}></NavItem>
    }

    if (item.type === 'link') {
      return <NavLink item={item} nestedLevel={nestedLevel}></NavLink>
    }

    if (item.type === 'collapse') {
      return <NavCollapse item={item} nestedLevel={nestedLevel}></NavCollapse>
    }

    if (item.type === 'group') {
      return <NavGroup item={item} nestedLevel={nestedLevel}></NavGroup>
    }

    return null
  }

  return (
    <WithPermissions permissions={permissions}>{renderItem()}</WithPermissions>
  )
}
