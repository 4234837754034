import * as React from 'react'
import { Layout } from 'layout/Layout'
import {
  ToolbarLayout,
  NavbarWrapperLayout,
  LayoutContentWrapper,
  LayoutContent,
} from 'layout/components'
import { NavbarProvider, NavbarMobileProvider } from 'layout/contexts'

export const PrivateLayout: React.FC = props => {
  return (
    <NavbarProvider>
      <NavbarMobileProvider>
        <Layout>
          <NavbarWrapperLayout></NavbarWrapperLayout>
          <LayoutContentWrapper>
            <ToolbarLayout />
            <LayoutContent scrollToTopOnRouteChange>
              {props.children}
            </LayoutContent>
          </LayoutContentWrapper>
        </Layout>
      </NavbarMobileProvider>
    </NavbarProvider>
  )
}
