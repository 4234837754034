import { Button } from '@material-ui/core'
import { ExportToCsvConfig, useExportToCsv } from 'shared/hooks/UseExportToCsv'

export interface ExportCsvButtonProps<T> extends ExportToCsvConfig<T> {}

export const ExportCsvButton = <T,>(props: ExportCsvButtonProps<T>) => {
  const exportFn = useExportToCsv(props)

  return (
    <Button variant="outlined" onClick={exportFn}>
      CSV
    </Button>
  )
}
