import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@material-ui/core'
import {
  SubmitHandler,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Form, SelectForm, TextFieldForm } from 'shared/forms'
import { useRegisterCategoryMoveMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import {
  CategoryMove,
  CategoryOutcomeType,
  CategoryOutcomeTypeTexts,
  MoveType,
} from '../models/CategoryMove'
import { CategoryMoveAutocomplete } from './CategoryMoveAutocomplete'

interface CreateForm {
  name: string
  description: string
  parent?: CategoryMove
  outcomeType?: CategoryOutcomeType
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre es un campo requerido'),
  parent: yup.object().nullable().notRequired(),
  description: yup.string().required('La descripción es un campo requerido'),
  outcomeType: yup.string().notRequired(),
})

const dialogId = 'categorymoves/create-dialog'
const formId = 'categorymoves/create-form'

export const Create: React.FC<{ type: MoveType }> = ({ type }) => {
  const [isOpen, setIsOpen] = React.useState(true)
  const [addCategoryMove, { loading }] = useRegisterCategoryMoveMutation()

  const { enqueueSnackbar } = useSnackbar()

  const { url } = useRouteMatch()

  const history = useHistory()

  const formInstance = useForm<CreateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
      parent: null,
      outcomeType: CategoryOutcomeType.expense,
    },
  })

  const { formState } = formInstance

  const { isDirty } = formState

  const onSubmit: SubmitHandler<CreateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const { parent, ...data } = values

      await addCategoryMove({
        variables: {
          data: { categoryMoveId: parent?.id, ...data, moveType: type },
        },
      })

      enqueueSnackbar('Categoría registrada correctamente', {
        variant: 'success',
      })
      onClose()
    } catch (e) {
      console.warn(e)

      let msg =
        'Ha ocurrido un error al registrar la cuenta. Intentelo más tarde'

      if (e.message === "Can't assign that parent") {
        msg = 'No puedes asignar esa categoría como padre'
      }

      enqueueSnackbar(msg, { variant: 'error' })
    }
  }

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push(url.replace('/create', ''))
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      open={isOpen}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Registrar categoría</DialogTitle>
      <DialogContent>
        <Form formProps={{ id: formId }} onSubmit={onSubmit} {...formInstance}>
          <TextFieldForm
            name="name"
            label="Nombre"
            placeholder="Ingresar nombre"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            autoFocus
            disabled={loading}
          />
          <TextFieldForm
            name="description"
            label="Descripción"
            placeholder="Ingresar descripción"
            variant="outlined"
            className="mb-16"
            rows={3}
            multiline
            required
            fullWidth
            disabled={loading}
          />
          <CategoryMoveAutocomplete
            type={type}
            onlyChilds={false}
            name="parent"
            inputProps={{ label: 'Categoría padre', variant: 'outlined' }}
            placeholder="Buscar categoría padre"
            className="mb-16"
            fullWidth
            disabled={loading}
          ></CategoryMoveAutocomplete>
          {type === MoveType.outcome ? (
            <OutcomeTypeForm disabled={loading}></OutcomeTypeForm>
          ) : null}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function OutcomeTypeForm({ disabled }) {
  const { getValues, control, setValue } = useFormContext()

  const parent = useWatch<CategoryMove>({
    control,
    name: 'parent',
    defaultValue: getValues('parent'),
  })

  const { outcomeType } = parent ?? {}

  React.useEffect(() => {
    const newValue = outcomeType ?? CategoryOutcomeType.expense
    setValue('outcomeType', newValue)
  }, [outcomeType, setValue])

  return (
    <SelectForm
      label="Tipo de cuenta"
      name="outcomeType"
      variant="outlined"
      className="mb-16"
      fullWidth
      disabled={disabled || !!parent}
    >
      {Object.keys(CategoryOutcomeType).map(cot => (
        <MenuItem key={cot} value={cot}>
          {CategoryOutcomeTypeTexts[cot]}
        </MenuItem>
      ))}
    </SelectForm>
  )
}
