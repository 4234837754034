import * as React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Form, TextFieldForm } from 'shared/forms'
import { useUpdateTypeCreditMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import { useGetTypeCredit } from '../data/Queries'

interface UpdateForm {
  name: string
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre es un campo requerido'),
})

const formId = 'UpdateTypeCredit'
const dialogId = 'TypeCreditEditFormDialog'

export const Update = () => {
  const [isOpen, setIsOpen] = React.useState(true)

  const { id } = useParams<{ id: string }>()

  const { data, loading: isGetting, error } = useGetTypeCredit({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
  const [
    updateTypeCredit,
    { loading: isUpdating },
  ] = useUpdateTypeCreditMutation()

  const loading = isGetting || isUpdating

  const typeCredit = React.useMemo(() => {
    return data?.getTypeCredit
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<UpdateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  })
  const { formState, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (typeCredit) {
      reset(typeCredit)
    }
  }, [typeCredit, reset])

  React.useEffect(() => {
    if (error) {
      console.warn(error)

      let msg =
        'Ha ocurrido un error al obtener el tipo de crédito. Intentelo más tarde'

      if (error.message === 'TypeCredit not found') {
        msg = 'Tipo de crédito no encontrado'
      }

      enqueueSnackbar(msg, { variant: 'error' })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, enqueueSnackbar])

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/type-credits')
  }

  const onSubmit: SubmitHandler<UpdateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      await updateTypeCredit({
        variables: { data: { id: typeCredit.id, ...values } },
      })

      enqueueSnackbar('Tipo de crédito actualizado correctamente', {
        variant: 'success',
      })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        'Ha ocurrido un error al actualizar el tipo de crédito. Intentelo más tarde',
        { variant: 'error' },
      )
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      open={isOpen}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Editar tipo de crédito</DialogTitle>
      <DialogContent>
        <Form {...formInstance} onSubmit={onSubmit} formProps={{ id: formId }}>
          <TextFieldForm
            name="name"
            label="Nombre"
            placeholder="Ingresar nombre"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            autoFocus
            disabled={loading}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
