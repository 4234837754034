import { TextField, TextFieldProps } from '@material-ui/core'
import dayjs from 'dayjs'
import {
  BaseDatePickerProps,
  DatePicker as MaterialDatePicker,
} from '@material-ui/pickers'
import { SharedPickerProps } from '@material-ui/pickers/Picker/makePickerWithState'
import { ResponsiveWrapper } from '@material-ui/pickers/wrappers/ResponsiveWrapper'

export type TDate = dayjs.Dayjs

export type PickerProps = BaseDatePickerProps<TDate> &
  SharedPickerProps<TDate, typeof ResponsiveWrapper>

export interface DatePickerProps extends Omit<PickerProps, 'renderInput'> {
  inputProps?: TextFieldProps
  defaultValue?: TDate
}

const READ_ONLY_VIEWS = ['year', 'month']

export const DatePicker = ({
  inputProps = {},
  ...autocompleteProps
}: DatePickerProps) => {
  const { inputProps: propInputProps } = inputProps

  const renderInput = ({
    inputProps: paramInputProps,
    ...params
  }: TextFieldProps) => {
    delete params.helperText
    let readOnly: boolean

    if (autocompleteProps?.views) {
      readOnly = autocompleteProps.views.every(view =>
        READ_ONLY_VIEWS.includes(view),
      )
    }

    return (
      <TextField
        inputProps={{
          ...propInputProps,
          ...paramInputProps,
          readOnly,
        }}
        {...inputProps}
        {...params}
      ></TextField>
    )
  }

  return (
    <MaterialDatePicker<TDate>
      {...autocompleteProps}
      renderInput={renderInput}
    ></MaterialDatePicker>
  )
}
