export function parseDate(date: string | Date) {
  try {
    if (date) {
      return new Date(date)
    }
  } catch (e) {
    console.warn(e)
  }

  return null
}
