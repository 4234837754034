import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { UserProfile } from 'auth/models/UserProfile'

export const UserProfileFragment = gql`
  fragment UserProfileFragment on UserProfile {
    id
    name
    label
    status
    createdAt
    tenantId
  }
`

const GET_USER_PROFILE = gql`
  query GetUserProfile($id: String!) {
    getUserProfile(id: $id) {
      permissions {
        id
        name
        label
      }
      ...UserProfileFragment
    }
  }
  ${UserProfileFragment}
`
export type GetUserProfileResponse = { getUserProfile: UserProfile }
export interface GetUserProfileVars {
  id: string
}

export const useGetUserProfile = (
  options?: QueryHookOptions<GetUserProfileResponse, GetUserProfileVars>,
) => {
  const result = useQuery<GetUserProfileResponse, GetUserProfileVars>(
    GET_USER_PROFILE,
    options,
  )
  return result
}
