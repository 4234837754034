import * as React from 'react'
import { Card, CircularProgress, Grid, Typography } from '@material-ui/core'
import { Filter, FilterForm } from './Filter'
import { ListMonthByMonthVars, useMonthByMonthChart } from './data/Queries'
import { Center, SimplePage } from 'shared'
import dayjs from 'dayjs'
import { Chart } from './Chart'
import {
  CategoryAmountCompareTable,
  useCategoryAmountCompareTable,
} from './category-amount-compare-table'
import {
  CategoryOutcomeType,
  CategoryOutcomeTypeTexts,
  MoveType,
} from 'modules/category-move/models/CategoryMove'

export const MonthByMonthReport = () => {
  const [titles, setTitles] = React.useState<{
    firstTitle?: string
    secondTitle?: string
  }>({})

  const [fetchIncomeTable, incomeTableState] = useCategoryAmountCompareTable({
    moveType: MoveType.income,
  })
  const [fetchOutcomeTable, outcomeTableState] = useCategoryAmountCompareTable({
    moveType: MoveType.outcome,
  })
  const [
    fetchPurchaseTable,
    purchaseTableState,
  ] = useCategoryAmountCompareTable({
    moveType: MoveType.outcome,
    categoryOutcomeType: CategoryOutcomeType.purchase,
  })

  const [
    getChart,
    { data: chartData, loading: chartLoading },
  ] = useMonthByMonthChart({
    fetchPolicy: 'network-only',
  })
  const loading = chartLoading

  const chart = React.useMemo(() => {
    if (!chartData?.getIncomeAndOutcomeDay) {
      return {
        labels: [],
        income: [],
        outcome: [],
      }
    }

    return {
      labels: chartData?.getIncomeAndOutcomeDay.map(r => r.name),
      income: chartData?.getIncomeAndOutcomeDay.map(r => r.income),
      outcome: chartData?.getIncomeAndOutcomeDay.map(r => r.outcome),
    }
  }, [chartData])

  const onFilter = (
    payload: ListMonthByMonthVars['data'],
    values: FilterForm,
  ) => {
    const firstTitle = `${dayjs.months()[values.month]} ${values.year}`
    const secondTitle = `${dayjs.months()[values.month2]} ${values.year2}`
    setTitles({ firstTitle, secondTitle })
    fetchIncomeTable(payload)
    fetchOutcomeTable(payload)
    fetchPurchaseTable(payload)
    getChart({
      variables: {
        chart: {
          payload: [
            {
              name: firstTitle,
              ...payload.first,
            },
            {
              name: secondTitle,
              ...payload.second,
            },
          ],
        },
      },
    })
  }

  return (
    <SimplePage
      header={
        <div className="flex h-full w-full py-24 px-40 items-center justify-between">
          <div>
            <Typography variant="h6">Comparativo mes a mes</Typography>
          </div>
        </div>
      }
    >
      <div className="p-32">
        <Grid container spacing={3} className="w-full">
          <Grid item md={12}>
            <Filter
              loading={loading}
              className="mb-14 p-20"
              onFilter={onFilter}
            ></Filter>
          </Grid>
          <Grid item md={8}>
            <Card className="mb-32">
              <h1 className="ml-16">Ingresos</h1>
              <CategoryAmountCompareTable
                {...incomeTableState}
                {...titles}
              ></CategoryAmountCompareTable>
            </Card>
            <Card className="mb-32">
              <h1 className="ml-16">
                {CategoryOutcomeTypeTexts[CategoryOutcomeType.purchase]}
              </h1>
              <CategoryAmountCompareTable
                {...purchaseTableState}
                {...titles}
              ></CategoryAmountCompareTable>
            </Card>
            <Card>
              <h1 className="ml-16">Egresos</h1>
              <CategoryAmountCompareTable
                {...outcomeTableState}
                {...titles}
              ></CategoryAmountCompareTable>
            </Card>
          </Grid>
          <Grid item md={4}>
            <Card>
              {chartLoading ? (
                <Center className="p-8">
                  <CircularProgress></CircularProgress>
                </Center>
              ) : (
                <Chart {...chart}></Chart>
              )}
            </Card>
          </Grid>
        </Grid>
      </div>
    </SimplePage>
  )
}
