import * as React from 'react'

export const useSafeDispatch = <T,>(dispatch: React.Dispatch<T>) => {
  const mounted = React.useRef(false)

  React.useLayoutEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  return React.useCallback(
    (args: T) => {
      if (mounted.current) {
        dispatch(args)
      }
    },
    [dispatch],
  )
}
