import * as React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

type LinkProps<S = any> = React.PropsWithoutRef<NavLinkProps<S>> &
  React.RefAttributes<HTMLAnchorElement>

export const NavLinkAdapter = React.forwardRef(
  (props: LinkProps, ref: React.Ref<HTMLAnchorElement>) => (
    <NavLink innerRef={ref} {...props}></NavLink>
  ),
)
