import * as React from 'react'
import dayjs from 'dayjs'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import { ListAccountTransferPayload, useTransferList } from '../data/Queries'
import { Column, Row } from 'react-table'
import { Card, Grid, makeStyles } from '@material-ui/core'
import { formatCurrency } from 'shared/utils'
import { AccountTransfer } from 'modules/account/models/AccountTransfer'
import { Filter } from '../components/Filter'
import { Delete } from './Delete'
import { DeleteButton } from 'shared/crud/DeleteButton'
import {
  ExportCsvButton,
  ExportCsvButtonProps,
} from 'shared/crud/ExportCsvButton'

const useStyles = makeStyles({
  notesField: {
    maxHeight: 60,
    overflowY: 'auto',
    whiteSpace: 'break-spaces',
    width: 200,
  },
})

export const List = () => {
  const [getList, { data: companyListData, loading }] = useTransferList({
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const classes = useStyles()
  const columns = React.useMemo<Column<AccountTransfer>[]>(() => {
    return [
      {
        Header: 'Fecha',
        accessor: 'date',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        id: 'source.account',
        Header: 'Cuenta origen',
        accessor: ({ source }) => {
          if (source.account.bankAccount) {
            return source.account.bankAccount.name
          }
          if (source.account.organization) {
            return source.account.organization.name
          }
          return ''
        },
      },
      {
        id: 'target.account',
        Header: 'Cuenta destino',
        accessor: ({ target }) => {
          if (target.account.bankAccount) {
            return target.account.bankAccount.name
          }
          if (target.account.organization) {
            return target.account.organization.name
          }
          return ''
        },
      },
      {
        id: 'source.amount',
        Header: 'Monto',
        accessor: ({ source }) => formatCurrency(source.amount),
      },
      {
        id: 'notes',
        Header: 'Notas',
        accessor: ({ notes }) => <p className={classes.notesField}>{notes}</p>,
      },
      {
        disableSortBy: true,
        Header: 'Acciones',
        accessor: transfer => (
          <DeleteButton>
            <Delete transfer={transfer}></Delete>
          </DeleteButton>
        ),
      },
    ]
  }, [classes])

  const data = React.useMemo(() => {
    return companyListData?.listTransfer ?? []
  }, [companyListData])

  const tableInstance = useDataTable({
    columns,
    data,
  })

  const csvProps = React.useMemo<ExportCsvButtonProps<AccountTransfer>>(() => {
    return {
      columns: [
        {
          id: 'date',
          header: 'Fecha',
          cell: row => dayjs(row.date).format('L'),
        },
        {
          id: 'source',
          header: 'Cuenta origen',
          cell: row => {
            if (row.source.account.bankAccount) {
              return row.source.account.bankAccount.name
            }
            if (row.source.account.organization) {
              return row.source.account.organization.name
            }
            return ''
          },
        },
        {
          id: 'target',
          header: 'Cuenta destino',
          cell: row => {
            if (row.target.account.bankAccount) {
              return row.target.account.bankAccount.name
            }
            if (row.target.account.organization) {
              return row.target.account.organization.name
            }
            return ''
          },
        },
        {
          id: 'amount',
          header: 'Monto',
          cell: row => row.source.amount,
        },
        {
          id: 'notes',
          header: 'Notas',
          multiline: true,
          cell: row => row.notes,
        },
      ],
      filename: `Transferencias - ${dayjs().format('L hh:mm:ss')}`,
      data,
    }
  }, [data])

  const toolBarContent = (selectedRows: Row<AccountTransfer>[]) => {
    return <ExportCsvButton {...csvProps}></ExportCsvButton>
  }

  const onFilter = (payload: ListAccountTransferPayload) => {
    getList({
      variables: {
        data: payload,
      },
    })
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Filter
            loading={loading}
            className="my-14 p-20"
            onFilter={onFilter}
          ></Filter>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Card>
            <DataTable
              toolBarContent={toolBarContent}
              {...tableInstance}
            ></DataTable>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}
