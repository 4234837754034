import * as React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from '@material-ui/core'
import { FieldError, useController, useFormContext } from 'react-hook-form'
import { useIds } from 'shared/hooks'

export interface ISelectFormProps {
  SelectComponent?: React.ComponentType<SelectProps>
}

export type SelectFormProps = ISelectFormProps & SelectProps

export const SelectForm = ({
  id: propId,
  name,
  label,
  SelectComponent = Select,
  onChange: propOnChange,
  defaultValue,
  className,
  ...props
}: SelectFormProps) => {
  const [selectId, labelId] = useIds(propId, 'select', 'label')

  const { control, errors } = useFormContext()

  const error = errors[name] as FieldError

  const {
    field: { ref, value, onChange: formOnChange, ...formProps },
  } = useController({
    name,
    control,
    defaultValue,
  })

  const onChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode,
  ) => {
    formOnChange(event.target.value)
    propOnChange?.(event, child)
  }

  return (
    <FormControl
      className={className}
      variant={props.variant}
      error={Boolean(error)}
      fullWidth={props.fullWidth}
      required={props.required}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <SelectComponent
        id={selectId}
        labelId={labelId}
        value={value}
        onChange={onChange}
        {...props}
        {...formProps}
      ></SelectComponent>
      {error ? <FormHelperText>{error.message}</FormHelperText> : null}
    </FormControl>
  )
}
