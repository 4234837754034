import * as React from 'react'
import { AutocompleteForm, AutocompleteFormProps } from 'shared/forms'
import { useCategoryMoveList } from '../data/Queries'
import {
  CategoryMove,
  CategoryOutcomeType,
  MoveType,
} from '../models/CategoryMove'

export interface CategoryMoveAutocompleteProps<
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
> extends Omit<
    AutocompleteFormProps<CategoryMove, Multiple, DisableClearable, FreeSolo>,
    'options' | 'loading'
  > {
  type: MoveType
  onlyChilds?: boolean
  outcomeType?: CategoryOutcomeType
  onOptionsLoaded?: (options: CategoryMove[]) => void
}

export const CategoryMoveAutocomplete = <
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
>({
  type,
  onlyChilds = true,
  outcomeType,
  getOptionLabel = option => option.name,
  getOptionSelected = (option, value) => option.id === value.id,
  onOptionsLoaded,
  ...props
}: CategoryMoveAutocompleteProps<Multiple, DisableClearable, FreeSolo>) => {
  const { loading, data } = useCategoryMoveList({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      onOptionsLoaded?.(data.listCategoryMove)
    },
    variables: { data: { onlyChilds, moveType: type, outcomeType } },
  })

  const options = React.useMemo(() => {
    return data?.listCategoryMove ?? []
  }, [data])

  return (
    <AutocompleteForm
      loading={loading}
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      {...props}
    ></AutocompleteForm>
  )
}
