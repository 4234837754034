import { Hidden, makeStyles, SwipeableDrawer } from '@material-ui/core'
import clsx from 'clsx'
import { useNavbarContext, useNavbarMobileContext } from 'layout/contexts'
import * as React from 'react'
import { NavbarLayout } from './NavbarLayout'

const navbarWidth = 260

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 4,
    [theme.breakpoints.up('lg')]: {
      width: navbarWidth,
      minWidth: navbarWidth,
    },
  },
  wrapperFolded: {
    [theme.breakpoints.up('lg')]: {
      width: 64,
      minWidth: 64,
    },
  },
  navbar: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    flex: '1 1 auto',
    width: navbarWidth,
    minWidth: navbarWidth,
    height: '100%',
    zIndex: 4,
    background: theme.palette.background.default,
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
    boxShadow:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  },
  left: {
    left: 0,
  },
  right: {
    right: 0,
  },
  folded: {
    position: 'absolute',
    width: 64,
    minWidth: 64,
    top: 0,
    bottom: 0,
  },
  foldedAndOpened: {
    width: navbarWidth,
    minWidth: navbarWidth,
  },
  navbarContent: {
    flex: '1 1 auto',
  },
  foldedAndClosed: {
    '& $navbarContent': {
      '& .logo-icon': {
        width: 32,
        height: 32,
      },
      '& .logo-text': {
        opacity: 0,
      },
      '& .react-badge': {
        opacity: 0,
      },
      '& .list-item-text, & .arrow-icon, & .item-badge': {
        opacity: 0,
      },
      '& .list-subheader .list-subheader-text': {
        opacity: 0,
      },
      '& .list-subheader:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        minWidth: 16,
        borderTop: '2px solid',
        opacity: 0.2,
      },
      '& .collapse-children': {
        display: 'none',
      },
      '& .user': {
        '& .username, & .email': {
          opacity: 0,
        },
        '& .avatar': {
          width: 40,
          height: 40,
          top: 32,
          padding: 0,
        },
      },
      '& .list-item.active': {
        marginLeft: 12,
        width: 40,
        padding: 12,
        borderRadius: 20,
        '&.square': {
          borderRadius: 0,
          marginLeft: 0,
          paddingLeft: 24,
          width: '100%',
        },
      },
    },
  },
}))

export const NavbarWrapperLayout: React.FC = () => {
  const classes = useStyles()

  const {
    isFolded,
    foldedAndClosed,
    foldedAndOpened,
    openNavbar,
    closeNavbar,
  } = useNavbarContext()

  const { isNavbarMobileOpen, closeNavbarMobile } = useNavbarMobileContext()

  return (
    <div
      id="fuse-navbar"
      className={clsx(classes.wrapper, isFolded && classes.wrapperFolded)}
    >
      <Hidden mdDown>
        <div
          className={clsx(
            classes.navbar,
            classes.left,
            isFolded && classes.folded,
            foldedAndOpened && classes.foldedAndOpened,
            foldedAndClosed && classes.foldedAndClosed,
          )}
          onMouseLeave={() => foldedAndOpened && closeNavbar()}
          onMouseEnter={() => foldedAndClosed && openNavbar()}
        >
          <NavbarLayout className={classes.navbarContent} />
        </div>
      </Hidden>

      <Hidden lgUp>
        <SwipeableDrawer
          anchor="left"
          variant="temporary"
          open={isNavbarMobileOpen}
          classes={{
            paper: classes.navbar,
          }}
          onClose={() => closeNavbarMobile()}
          onOpen={() => {}}
          disableSwipeToOpen
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavbarLayout className={classes.navbarContent} />
        </SwipeableDrawer>
      </Hidden>
    </div>
  )
}
