import { CrudPage } from 'shared/crud/CrudPage'
import { ListPage } from 'shared/crud/CrudPage/ListPage'
import { List } from './components/List'

export const BankPages = () => {
  return (
    <CrudPage name="bank" actions={['list']}>
      <ListPage title="Listado de bancos" hideAddButton>
        <List></List>
      </ListPage>
    </CrudPage>
  )
}
