import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { User } from 'auth/models/User'
import { useDisclosure } from 'shared/hooks'
import { useSnackbar } from 'notistack'
import { useDeleteUserMutation } from '../data/Mutations'

export interface DeleteProps {
  users: User[]
}

const dialogId = 'UserDeleteDialog'

export const Delete = ({ users }: DeleteProps) => {
  const { isOpen, open, close } = useDisclosure()

  const { enqueueSnackbar } = useSnackbar()

  const [deleteUser] = useDeleteUserMutation()

  const deleteUsers = async () => {
    try {
      await Promise.all(users.map(u => deleteUser({ variables: { id: u.id } })))
      enqueueSnackbar(`${users.length} usuarios eliminados correctamente`, {
        variant: 'success',
      })
      close()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        `Ha ocurrido un error al eliminar los usuarios . Intentelo más tarde`,
        { variant: 'error' },
      )
    }
  }

  return (
    <>
      <Tooltip title="Eliminar usuarios">
        <IconButton aria-label="eliminar" onClick={open}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        open={isOpen}
        onClose={close}
        aria-labelledby={dialogId}
      >
        <DialogTitle id={dialogId}>Eliminar usuarios</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Deseas eliminar los siguientes usuarios?
          </DialogContentText>
          <List dense>
            {users.map(user => (
              <ListItem key={user.id}>
                <ListItemText
                  primary={`- ${user.firstName} ${user.lastName}`}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteUsers} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
