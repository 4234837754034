import clsx from 'clsx'

export const Center = ({ className, ...props }: any) => {
  return (
    <div
      className={clsx('flex', 'justify-center', 'items-center', className)}
      {...props}
    ></div>
  )
}
