import dayjs from 'dayjs'
import {
  buildDateRange,
  buildDateRangeSegments,
} from 'modules/reports/statement-of-incomes/utils/AggregatedDates'

export type FilterType = 'monthly' | 'annual'

export const getLimits = (type: FilterType) => {
  if (type === 'monthly') {
    const lastMonth = dayjs().month() - 1
    return buildDateRange('monthly', dayjs().month(lastMonth))
  }

  const december = dayjs().month(11).startOf('month')
  return buildDateRange('yearly', december)
}

export const getChartFilter = (type: FilterType) => {
  if (type === 'monthly') {
    const lastMonth = dayjs().month() - 1
    return buildDateRangeSegments('monthly', dayjs().month(lastMonth))
  }

  const december = dayjs().month(11).startOf('month')
  return buildDateRangeSegments('yearly', december)
}
