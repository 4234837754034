import * as React from 'react'

export function useConstant<T extends any>(init: () => T) {
  const ref = React.useRef<T | null>(null)

  if (ref.current === null) {
    ref.current = init()
  }

  return ref.current
}
