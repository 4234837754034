import { Checkbox, makeStyles, Theme } from '@material-ui/core'
import { ArrowRight } from '@material-ui/icons'
import {
  SortingRule,
  TableOptions,
  useSortBy,
  useTable,
  usePagination,
  useGlobalFilter,
  TableInstance,
  useRowSelect,
  Row,
  useExpanded,
  Column,
} from 'react-table'

export interface DataTableState<Data extends object> {
  pagination?: boolean
  selectable?: boolean
  page: number
  pageSize: number
  sortedColumns: Array<SortingRule<Data>>
  globalFilter: string
  selectedCount: number
  selectedRows: Row<Data>[]
  count: number
}

export interface UseDataTableOptions<Data extends object = {}>
  extends Pick<
    TableOptions<Data>,
    'data' | 'columns' | 'getSubRows' | 'initialState'
  > {
  pagination?: boolean
  selectable?: boolean
  manual?: boolean
  count?: number
  pageCount?: number
  onStateChanged?: (state: DataTableState<Data>) => void
}

export interface DataTableInstance<Data extends object = {}>
  extends DataTableState<Data>,
    Pick<TableInstance<Data>, 'setPageSize' | 'gotoPage' | 'setGlobalFilter'> {
  tableInstance: TableInstance<Data>
}

function globalFilterFn<Data extends object = {}>(
  rows: Row<Data>[],
  columnIds: Extract<keyof Data, string>[],
  searchQuery: string,
) {
  if (!searchQuery) return rows

  const lowercaseQuery = searchQuery.toLowerCase()
  const rowMatches = (row: Row<Data>): boolean =>
    Object.values(row.values)?.some(
      rowValue =>
        typeof rowValue === 'string' &&
        rowValue.toLowerCase().includes(lowercaseQuery),
    ) || row?.subRows?.some(rowMatches)

  return rows.filter(rowMatches)
}

export const useDataTable = <Data extends object = {}>({
  columns,
  data,
  selectable = true,
  manual,
  count,
  pageCount: pageCountProp,
  getSubRows,
  pagination = true,
  initialState,
}: UseDataTableOptions<Data>): DataTableInstance<Data> => {
  const instance = useTable<Data>(
    {
      columns,
      data,
      getSubRows,
      initialState,
      manualSortBy: manual,
      manualPagination: manual,
      manualGlobalFilter: manual,
      pageCount: pageCountProp,
      globalFilter: globalFilterFn,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.allColumns.push(_columns => {
        const cols: Column<Data>[] = []
        if (selectable) {
          cols.push({
            id: 'selection',
            disableSortBy: true,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <Checkbox
                  color="primary"
                  {...getToggleAllRowsSelectedProps()}
                />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <Checkbox
                  color="primary"
                  {...row.getToggleRowSelectedProps()}
                  onClick={ev => ev.stopPropagation()}
                />
              </div>
            ),
          })
        }

        if (getSubRows) {
          cols.push({
            id: 'expander',
            disableSortBy: true,
            maxWidth: 200,
            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
              <span {...getToggleAllRowsExpandedProps()}>
                <ExpandedArrow isExpanded={isAllRowsExpanded}></ExpandedArrow>
              </span>
            ),
            Cell: ({ row }) =>
              row.canExpand ? (
                <span
                  {...row.getToggleRowExpandedProps({
                    style: {
                      paddingLeft: `${row.depth * 2}rem`,
                    },
                  })}
                >
                  <ExpandedArrow isExpanded={row.isExpanded}></ExpandedArrow>
                </span>
              ) : null,
          })
        }

        return [...cols, ..._columns]
      })
    },
  )
  const {
    selectedFlatRows,
    rowsById,
    setPageSize,
    gotoPage,
    setGlobalFilter,
    state: {
      pageIndex,
      pageSize,
      sortBy,
      globalFilter = '',
      selectedRowIds,
      expanded,
    },
  } = instance

  const getCount = () => {
    if (manual) {
      return count
    }

    if (!data.length) {
      return 0
    }

    if (getSubRows) {
      const ids = Object.keys(expanded)
      return ids.reduce((acu, id) => {
        return acu + (rowsById[id]?.subRows?.length || 0)
      }, data.length)
    }

    return data.length
  }

  const dataTableState: DataTableState<Data> = {
    pagination,
    selectable,
    page: pageIndex,
    pageSize,
    sortedColumns: sortBy,
    globalFilter,
    selectedCount: Object.keys(selectedRowIds).length,
    selectedRows: selectedFlatRows,
    count: getCount(),
  }

  return {
    ...dataTableState,
    setPageSize,
    gotoPage,
    setGlobalFilter,
    tableInstance: instance,
  }
}

interface ExpandedArrowProps {
  isExpanded: boolean
}

const useExpandedArrowStyles = makeStyles<Theme, ExpandedArrowProps>({
  root: {
    transform: ({ isExpanded }) =>
      isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
})

function ExpandedArrow({ isExpanded }: ExpandedArrowProps) {
  const classes = useExpandedArrowStyles({ isExpanded })
  return <ArrowRight className={classes.root}></ArrowRight>
}
