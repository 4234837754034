import { TenantModel } from 'models/TenantModel'

export enum MoveType {
  income = 'income',
  outcome = 'outcome',
}

export enum CategoryOutcomeType {
  expense = 'expense',
  purchase = 'purchase',
}

export const CategoryOutcomeTypeTexts: Record<CategoryOutcomeType, string> = {
  [CategoryOutcomeType.expense]: 'Gasto',
  [CategoryOutcomeType.purchase]: 'Costo de Ventas y/o Servicios',
}

export const moveTypes: { text: string; value: MoveType }[] = [
  {
    text: 'Ingreso',
    value: MoveType.income,
  },
  {
    text: 'Egreso',
    value: MoveType.outcome,
  },
]

export const moveTypesMap = moveTypes.reduce((acu, next) => {
  acu[next.value] = next.text
  return acu
}, {})

export class CategoryMove extends TenantModel {
  name: string
  description: string
  moveType: MoveType
  outcomeType: CategoryOutcomeType
  parent: CategoryMove | null

  constructor(data: Partial<CategoryMove> = {}) {
    super(data)
    Object.assign(this, data)
  }
}

export interface CategoryAmount {
  id: string
  categoryId: string
  name: string
  parentId?: string
  amount: string
}
