import * as React from 'react'
import { Login } from 'auth/Login'
import { PublicLayout } from './components/PublicLayout'
import { Redirect, Route, Switch } from 'react-router-dom'

export const Public: React.FC = () => {
  return (
    <PublicLayout>
      <Switch>
        <Route path="/login">
          <Login></Login>
        </Route>
        <Redirect to="/login"></Redirect>
      </Switch>
    </PublicLayout>
  )
}
