import { MenuItem } from '@material-ui/core'
import { SelectForm, SelectFormProps } from 'shared/forms'
import { creditKindOptions } from '../models/Credit'

export interface CreditKindSelectProps extends SelectFormProps {
  allOption?: boolean
  allOptionText?: string
  allOptionValue?: string
}

export const CreditKindSelect = ({
  allOption = true,
  allOptionText = 'Todos',
  allOptionValue = 'all',
  ...props
}: CreditKindSelectProps) => {
  return (
    <SelectForm {...props}>
      <MenuItem value={allOptionValue}>
        <em>{allOptionText}</em>
      </MenuItem>
      {creditKindOptions.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </SelectForm>
  )
}
