import { useDisclosure } from 'shared/hooks'
import * as React from 'react'
import { createContext } from 'shared/utils'

export interface NavbarMobileState {
  isNavbarMobileOpen: boolean
  isNavbarMobileClose: boolean
  closeNavbarMobile: () => void
  openNavbarMobile: () => void
  toggleNavbarMobile: () => void
}

const name = 'NavbarMobileContext'

const [Provider, useNavbarMobileContext] = createContext<NavbarMobileState>({
  name,
  errorMessage: `useContext: ${name} is undefined. Seems you forgot to wrap component within the Provider`,
})

const NavbarMobileProvider: React.FC = ({ children }) => {
  const { isOpen, open, close, toggle } = useDisclosure()

  const state = React.useMemo(() => {
    return {
      isNavbarMobileOpen: isOpen,
      isNavbarMobileClose: !isOpen,
      closeNavbarMobile: close,
      openNavbarMobile: open,
      toggleNavbarMobile: toggle,
    }
  }, [isOpen, close, open, toggle])

  return <Provider value={state}>{children}</Provider>
}

export { NavbarMobileProvider, useNavbarMobileContext }
