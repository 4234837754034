import * as React from 'react'
import { assignRef, ReactRef } from '../utils'

export function useCombinedRefs<T>(...refs: (ReactRef<T> | undefined)[]) {
  const targetRef = React.useRef<T>(null)

  React.useEffect(() => {
    refs.forEach(ref => {
      assignRef(ref, targetRef.current)
    })
  }, [refs])

  return targetRef
}
