import { Private, Public } from 'apps'
import { useAuthContext } from 'auth/contexts/AuthContext'
import { SplashScreen } from 'layout/components'

function App() {
  const { loading, user } = useAuthContext()

  if (loading) {
    return <SplashScreen></SplashScreen>
  }

  return user ? <Private></Private> : <Public></Public>
}

export default App
