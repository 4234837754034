import { AppBar, Hidden, makeStyles, Theme, Toolbar } from '@material-ui/core'
import clsx from 'clsx'
import { NavbarMobileToggleButton } from './NavbarMobileToggleButton'
import { NavbarUserMenu } from './NavbarUserMenu'

const useStyles = makeStyles<Theme>(theme => ({
  appBar: {
    background:
      'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 10px',
    backgroundAttachment: 'local, scroll',
  },
}))

export const ToolbarLayout = () => {
  const classes = useStyles()

  return (
    <AppBar
      id="fuse-toolbar"
      className={clsx(classes.appBar, 'flex relative z-10 shadow-md')}
      color="default"
    >
      <Toolbar className="p-0 min-h-48 md:min-h-64">
        <Hidden lgUp>
          <NavbarMobileToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
        </Hidden>

        <div className="flex flex-1"></div>

        <div className="flex items-center px-16">
          <NavbarUserMenu></NavbarUserMenu>
        </div>
      </Toolbar>
    </AppBar>
  )
}
