import { useTheme } from '@material-ui/core'
import ReactApexChart from 'react-apexcharts'
import { formatCurrency } from 'shared/utils'

interface ChartProps {
  labels: string[]
  income: number[]
  outcome: number[]
}

const options: ApexCharts.ApexOptions = {
  chart: {
    type: 'bar',
    height: '100%',
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  fill: {
    opacity: 1,
  },
  yaxis: {
    labels: {
      formatter: function (value) {
        return formatCurrency(value)
      },
    },
  },
}

export function Chart({ labels, income, outcome }: ChartProps) {
  const theme = useTheme()

  return (
    <div className="flex w-full p-8">
      <div className="h-256 w-full">
        <ReactApexChart
          options={{
            ...options,
            colors: [theme.palette.info.main, theme.palette.error.main],
            xaxis: {
              categories: labels,
            },
          }}
          series={[
            {
              data: income,
              borderWidth: 0,
              fill: true,
              name: 'Ingreso',
              pointHitRadius: 20,
              pointRadius: 0,
            },
            {
              data: outcome,
              borderWidth: 0,
              fill: true,
              name: 'Egreso',
              pointHitRadius: 20,
              pointRadius: 0,
            },
          ]}
          type={options.chart.type}
          height={options.chart.height}
        />
      </div>
    </div>
  )
}
