import { useMutation, gql } from '@apollo/client'
import { User } from '../models/User'

export interface LoginPayload {
  email: string
  password: string
}

export interface LoginResponse {
  token: string
  user: User
}

const LOGIN = gql`
  mutation Login($data: LoginPayload!) {
    login(data: $data) {
      token
      user {
        id
        email
        firstName
        lastName
        isSuperuser
        profiles {
          id
          name
          permissions {
            id
            name
          }
        }
        permissions {
          id
          name
        }
        tenants {
          id
          name
        }
      }
    }
  }
`

export const useLoginMutation = () => {
  const tuple = useMutation<{ login: LoginResponse }, { data: LoginPayload }>(
    LOGIN,
  )

  return tuple
}
