import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Form, TextFieldForm } from 'shared/forms'
import { useChangeUserPasswordMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { PasswordTextField } from 'shared/common/PasswordTextField'

interface ChangeUserPassswordProps {
  userId: string
  name?: string
  isOpen: boolean
  onClose(): void
}

interface ChangeUserPasswordForm {
  password: string
  passwordConfirm: string
}

const schema = yup.object().shape({
  password: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .min(8, 'La contraseña debe contener mínimo ${min} caracteres')
    .required('La contraseña es un campo requerido'),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password')], 'Las contraseñas no coinciden'),
})

const dialogId = 'users/change-password-dialog'
const formId = 'users/change-password-form'

export const ChangeUserPassword = ({
  userId,
  isOpen,
  onClose,
  name,
}: ChangeUserPassswordProps) => {
  const [changePassword, { loading }] = useChangeUserPasswordMutation()

  const { enqueueSnackbar } = useSnackbar()

  const formInstance = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  })

  const { formState, trigger, getValues, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen, reset])

  const onSubmit: SubmitHandler<ChangeUserPasswordForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const { password } = values

      await changePassword({
        variables: {
          data: {
            id: userId,
            password,
          },
        },
      })

      enqueueSnackbar('Contraseña registrada correctamente', {
        variant: 'success',
      })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        'Ha ocurrido un error al cambiar la contraseña. Intentelo más tarde',
        { variant: 'error' },
      )
    }
  }

  const onPasswordChange = () => {
    return getValues('passwordConfirm') && trigger('passwordConfirm')
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      open={isOpen}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>
        Cambiar contraseña {name ? `- ${name}` : null}
      </DialogTitle>
      <DialogContent>
        <Form formProps={{ id: formId }} onSubmit={onSubmit} {...formInstance}>
          <TextFieldForm
            TextComponent={PasswordTextField}
            name="password"
            label="Contraseña"
            placeholder="Ingresar contraseña"
            variant="outlined"
            className="mb-16"
            onChange={onPasswordChange}
            required
            fullWidth
            disabled={loading}
          />
          <TextFieldForm
            TextComponent={PasswordTextField}
            name="passwordConfirm"
            label="Confirmar contraseña"
            placeholder="Ingresar confirmación"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            disabled={loading}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
