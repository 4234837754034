import * as React from 'react'
import { LoginLayout } from './components/LoginLayout'
import { Link } from 'react-router-dom'
import { Button, Typography } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Form, TextFieldForm } from 'shared/forms'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLoginMutation } from './data/AuthMutations'
import { useSnackbar } from 'notistack'
import { AuthRepository } from './data/AuthRepository'
import { useAuthDispatch } from './contexts/AuthContext'

interface LoginForm {
  email: string
  password: string
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('El correo ingresado no cumple con el formato')
    .required('Debes especificar tu correo electrónico'),
  password: yup.string().required('Debes especificar tu contraseña'),
})

export const Login = () => {
  const [doLogin, { loading }] = useLoginMutation()

  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useAuthDispatch()

  const formInstance = useForm<LoginForm>({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  })

  const onSubmit: SubmitHandler<LoginForm> = async values => {
    try {
      const { data } = await doLogin({ variables: { data: values } })

      AuthRepository.setToken(data.login.token)
      AuthRepository.setTenant(data.login.user.tenants[0]?.id)

      dispatch({ type: 'setUser', payload: data.login.user })
    } catch (e) {
      console.warn(e)
      let message = 'Ocurrió un error al iniciar sesión. Intentalo más tarde.'

      if (e && e.message === 'Invalid credentials') {
        message = 'Usuario y contraseña no válidos'
      }
      enqueueSnackbar(message, { variant: 'error' })
    }
  }

  return (
    <LoginLayout>
      <Typography variant="h6" className="md:w-full mb-32">
        Inicio de sesión
      </Typography>

      <Form
        {...formInstance}
        formProps={{ className: 'flex flex-col justify-center w-full' }}
        onSubmit={onSubmit}
      >
        <TextFieldForm
          name="email"
          label="Correo electrónico"
          placeholder="Ingresar correo electrónico"
          variant="outlined"
          className="mb-16"
          required
          fullWidth
          autoFocus
          disabled={loading}
        />

        <TextFieldForm
          type="password"
          name="password"
          label="Contraseña"
          placeholder="Ingresar contraseña"
          variant="outlined"
          className="mb-16"
          required
          fullWidth
          disabled={loading}
        />

        <div className="flex items-center justify-between">
          <Link className="font-medium" to="/pages/auth/forgot-password-2">
            ¿Olvidaste tu contraseña?
          </Link>
        </div>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16"
          aria-label="Acceder"
          disabled={loading}
        >
          Acceder
        </Button>
      </Form>
    </LoginLayout>
  )
}
