import styled from '@emotion/styled'

export const LayoutContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  zIndex: 3,
  overflow: 'hidden',
  flex: '1 1 auto',
})
