import { TypeCredit } from '../models/TypeCredit'
import { Status } from 'models/BaseModel'
import { StatusMenu } from 'shared/crud'
import { useChangeTypeCreditStatusMutation } from '../data/Mutations'

export interface StatusUpdateProps {
  typeCredit: TypeCredit
}

export const StatusUpdate = ({ typeCredit }: StatusUpdateProps) => {
  const { id, status, tenantId } = typeCredit

  const [updateStatus, { loading }] = useChangeTypeCreditStatusMutation()

  const handleChange = (newStatus: Status) => {
    updateStatus({ variables: { data: { id, status: newStatus } } })
  }

  return (
    <StatusMenu
      value={status}
      onChange={handleChange}
      disabled={loading || !tenantId}
    ></StatusMenu>
  )
}
