import { ThemeOptions } from '@material-ui/core'
import { red } from '@material-ui/core/colors'

export const palette: ThemeOptions['palette'] = {
  type: 'light',
  primary: {
    main: '#b43c40',
  },
  secondary: {
    main: '#d4d3d3',
  },
  background: {
    paper: '#FFFFFF',
    default: '#F7F7F7',
  },
  error: red,
}
