import { TextField, TextFieldProps } from '@material-ui/core'
import { BaseDatePickerProps, DatePicker } from '@material-ui/pickers'
import { SharedPickerProps } from '@material-ui/pickers/Picker/makePickerWithState'
import { ResponsiveWrapper } from '@material-ui/pickers/wrappers/ResponsiveWrapper'
import dayjs from 'dayjs'
import { FieldError, useController, useFormContext } from 'react-hook-form'

export type TDate = dayjs.Dayjs

export type PickerProps = BaseDatePickerProps<TDate> &
  SharedPickerProps<TDate, typeof ResponsiveWrapper>

const READ_ONLY_VIEWS = ['year', 'month']
export interface DatePickerFormProps
  extends Omit<PickerProps, 'value' | 'onChange' | 'renderInput'> {
  name: string
  inputProps?: TextFieldProps
  onChange?: (date: TDate, keyboardInputValue?: string) => void
  defaultValue?: TDate
}

export const DatePickerForm = ({
  name: propName,
  defaultValue,
  inputProps = {},
  onChange: propOnChange,
  ...autocompleteProps
}: DatePickerFormProps) => {
  const { name: inputName, inputProps: propInputProps } = inputProps

  const name = propName ?? inputName

  const { control, errors } = useFormContext()

  const error = errors[name] as FieldError

  const {
    field: { ref, value, onChange: formOnChange, ...formProps },
  } = useController({
    name,
    control,
    defaultValue,
  })

  const renderInput = ({
    inputProps: paramInputProps,
    ...params
  }: TextFieldProps) => {
    delete params.helperText
    let readOnly: boolean

    if (autocompleteProps?.views) {
      readOnly = autocompleteProps.views.every(view =>
        READ_ONLY_VIEWS.includes(view),
      )
    }

    return (
      <TextField
        name={name}
        inputProps={{
          ...propInputProps,
          ...paramInputProps,
          readOnly,
        }}
        {...inputProps}
        {...params}
        error={Boolean(error)}
        helperText={error?.message}
      ></TextField>
    )
  }

  const onChange = (date: TDate, keyboardInputValue?: string) => {
    formOnChange(date)
    propOnChange?.(date, keyboardInputValue)
  }

  return (
    <DatePicker<TDate>
      value={value}
      onChange={onChange}
      {...formProps}
      {...autocompleteProps}
      renderInput={renderInput}
    ></DatePicker>
  )
}
