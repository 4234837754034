import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Form, SelectForm, TextFieldForm } from 'shared/forms'
import { useUpdateBankAccountMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import { useGetBankAccount } from '../data/Queries'
import { BankSelect } from 'modules/bank/components/BankSelect'
import { AccountType, accountTypes } from '../models/BankAccount'
import { CurrencyTextField } from 'shared/common/CurrencyTextField'
import { WithPermissions } from 'auth/components/WithPermissions'

interface UpdateForm {
  accountNumber: string
  name: string
  ownerName: string
  accountType: AccountType
  bankId: string
  initialBalance?: number
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre de la cuenta es un campo requerido'),
  accountNumber: yup
    .string()
    // eslint-disable-next-line no-template-curly-in-string
    .max(24, 'El máximo de caracteres es ${min}')
    .required('El número de cuenta es un campo requerido'),
  ownerName: yup
    .string()
    .required('El nombre del titular es un campo requerido'),
  accountType: yup.string().required('El tipo de cuenta es un campo requerido'),
  bankId: yup.string().required('El banco es un campo requerido'),
  initialBalance: yup
    .number()
    .min(0, 'El saldo inicial no puede ser negativo')
    .nullable(),
})

const formId = 'UpdateBankAccount'
const dialogId = 'BankAccountEditFormDialog'

export const Update = () => {
  const [isOpen, setIsOpen] = React.useState(true)
  const { id } = useParams<{ id: string }>()

  const { data, loading: isGetting, error } = useGetBankAccount({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
  const [
    updateBankAccount,
    { loading: isUpdating },
  ] = useUpdateBankAccountMutation()

  const loading = isGetting || isUpdating

  const account = React.useMemo(() => {
    return data?.getBankAccount
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const formInstance = useForm<UpdateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      accountNumber: '',
      name: '',
      ownerName: '',
      accountType: AccountType.checking_account,
      bankId: '',
      initialBalance: null,
    },
  })
  const { formState, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (account) {
      const { bank, ...accountData } = account
      reset({ ...accountData, bankId: bank.id, initialBalance: null })
    }
  }, [account, reset])

  React.useEffect(() => {
    if (error) {
      console.warn(error)

      let msg = 'Ha ocurrido un error al obtener la cuenta. Intentelo más tarde'

      if (error.message === 'BankAccount not found') {
        msg = 'Cuenta no encontrada'
      }

      enqueueSnackbar(msg, { variant: 'error' })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, enqueueSnackbar])

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push('/bankaccounts')
  }

  const onSubmit: SubmitHandler<UpdateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      await updateBankAccount({
        variables: { data: { id: account.id, ...values } },
      })

      enqueueSnackbar('Cuenta actualizada correctamente', {
        variant: 'success',
      })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        'Ha ocurrido un error al actualizar la cuenta. Intentelo más tarde',
        { variant: 'error' },
      )
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      open={isOpen}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Editar cuenta</DialogTitle>
      <DialogContent>
        <Form {...formInstance} onSubmit={onSubmit} formProps={{ id: formId }}>
          <Grid container spacing={2}>
            <Grid xs={8} item>
              <TextFieldForm
                name="name"
                label="Nombre de la cuenta"
                placeholder="Ingresar nombre"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                autoFocus
                disabled={loading}
              />
            </Grid>
            <Grid xs={4} item>
              <TextFieldForm
                name="accountNumber"
                label="Número de cuenta"
                placeholder="Ingresar número de cuenta"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                transform={{
                  output: ev =>
                    /^[0-9]+$/.test(ev.target.value)
                      ? ev.target.value
                      : ev.target.value.slice(0, -1),
                }}
                inputProps={{
                  maxLength: 24,
                }}
                disabled={loading}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <TextFieldForm
                name="ownerName"
                label="Titular"
                placeholder="Ingresar titular"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid xs={6} item>
              <SelectForm
                name="accountType"
                label="Tipo de cuenta"
                variant="outlined"
                className="mb-16"
                required
                fullWidth
                disabled={loading}
              >
                {accountTypes.map(type => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.text}
                  </MenuItem>
                ))}
              </SelectForm>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={6} item>
              <BankSelect name="bankId" disabled={loading}></BankSelect>
            </Grid>
            <Grid xs={6} item>
              <WithPermissions permissions={[]}>
                <TextFieldForm
                  TextComponent={CurrencyTextField}
                  name="initialBalance"
                  label="Saldo inicial"
                  placeholder="Ingresar saldo inicial"
                  variant="outlined"
                  className="mb-16"
                  fullWidth
                  disabled={loading}
                />
              </WithPermissions>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
