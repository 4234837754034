import * as React from 'react'
import dayjs from 'dayjs'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import { useCreditList } from '../data/Queries'
import { Credit, CreditKind } from '../models/Credit'
import { Column, Row } from 'react-table'
import { EditButton } from 'shared/crud/EditButton'
import { StatusUpdate } from './StatusUpdate'
import { Delete } from './Delete'
import { Card } from '@material-ui/core'
import { formatCurrency, getRowsForDeletion } from 'shared/utils'
import { DeleteButton } from 'shared/crud/DeleteButton'
import {
  ExportCsvButton,
  ExportCsvButtonProps,
} from 'shared/crud/ExportCsvButton'

export const List = () => {
  const { data: creditListData } = useCreditList({
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { data: { kind: [CreditKind.creditor, CreditKind.provider] } },
  })

  const columns = React.useMemo<Column<Credit>[]>(() => {
    return [
      {
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        id: 'organization',
        Header: 'Organización beneficiaria',
        accessor: ({ organization }) => organization.name,
      },
      {
        Header: 'Fecha de inicio',
        accessor: 'initDate',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        Header: 'Deuda total',
        accessor: 'totalDebt',
        Cell: ({ value }) => formatCurrency(value),
      },
      {
        id: 'account.initialBalance',
        Header: 'Deuda inicial',
        accessor: ({ account }) => formatCurrency(account.initialBalance),
      },
      {
        id: 'account.balance',
        Header: 'Deuda actual',
        accessor: ({ account }) => formatCurrency(account.balance),
      },
      {
        id: 'company',
        Header: 'Acreedor',
        accessor: ({ company }) => company.name,
      },
      {
        Header: 'Fecha de último pago',
        accessor: 'lastPaymentDate',
        Cell: ({ value }) => (value ? dayjs(value).format('L') : ''),
      },
      {
        Header: 'Fecha de creación',
        accessor: 'createdAt',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        disableSortBy: true,
        Header: 'Estatus',
        accessor: credit => <StatusUpdate credit={credit}></StatusUpdate>,
      },
      {
        disableSortBy: true,
        Header: 'Acciones',
        accessor: credit => (
          <EditButton
            title="Editar crédito"
            aria-label="editar crédito"
            id={credit.id}
            tenantId={credit.tenantId}
          ></EditButton>
        ),
      },
    ]
  }, [])

  const data = React.useMemo(() => {
    return creditListData?.listCredit ?? []
  }, [creditListData])

  const tableInstance = useDataTable({
    columns,
    data,
  })

  const csvProps = React.useMemo<ExportCsvButtonProps<Credit>>(() => {
    return {
      columns: [
        {
          id: 'name',
          header: 'Nombre',
          cell: row => row.name,
        },
        {
          id: 'organization.name',
          header: 'Organización beneficiaria',
          cell: row => row.organization.name,
        },
        {
          header: 'Fecha de inicio',
          id: 'initDate',
          cell: row => dayjs(row.initDate).format('L'),
        },
        {
          id: 'totalDebt',
          header: 'Deuda total',
          cell: row => row.totalDebt,
        },
        {
          id: 'account.initialBalance',
          Header: 'Deuda inicial',
          cell: row => row.account.initialBalance,
        },
        {
          id: 'account.balance',
          header: 'Deuda actual',
          cell: row => row.account.balance,
        },
        {
          id: 'company.name',
          header: 'Acreedor',
          cell: row => row.company.name,
        },
        {
          id: 'lastPaymentDate',
          header: 'Fecha de último pago',
          cell: row => dayjs(row.lastPaymentDate).format('L'),
        },
        {
          id: 'createdAt',
          header: 'Fecha de creación',
          cell: row => dayjs(row.createdAt).format('L'),
        },
        {
          id: 'status',
          header: 'Estatus',
          cell: row => row.status,
        },
      ],
      filename: `Créditos - ${dayjs().format('L hh:mm:ss')}`,
      data,
    }
  }, [data])

  const toolBarContent = (selectedRows: Row<Credit>[]) => {
    return selectedRows.length ? (
      <DeleteButton>
        <Delete credits={getRowsForDeletion(selectedRows)}></Delete>
      </DeleteButton>
    ) : (
      <ExportCsvButton {...csvProps}></ExportCsvButton>
    )
  }

  return (
    <Card>
      <DataTable toolBarContent={toolBarContent} {...tableInstance}></DataTable>
    </Card>
  )
}
