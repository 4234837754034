import styled from '@emotion/styled'
import { Scrollbars } from 'shared'

export const LayoutContent = styled(Scrollbars)({
  position: 'relative',
  display: 'flex',
  overflow: 'auto',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
  WebkitOverflowScrolling: 'touch',
  zIndex: 2,
})
