import * as React from 'react'
import * as yup from 'yup'
import {
  Button,
  Card,
  CardProps,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { useForm, useWatch, useFormContext } from 'react-hook-form'
import { Form, SelectForm } from 'shared/forms'
import { ListOutcomePayload } from '../data/Queries'
import dayjs from 'dayjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateNumberList } from 'core/utils'
import clsx from 'clsx'
import { OrganizationAutocomplete } from 'modules/organization/components/OrganizationAutcomplete'
import { Organization } from 'modules/organization/models/Organization'
import { useConstant } from 'shared/hooks'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { BankAccountAutocomplete } from 'modules/bank-account/components/BankAccountAutocomplete'
import { AccountType, accountTypes } from 'modules/account/models/Account'
import { CreditCard } from 'modules/credit-card/models/CreditCard'
import { CreditCardAutocomplete } from 'modules/credit-card/components/CreditCardAutocomplete'
import { Credit } from 'modules/credit/models/Credit'
import { CreditAutocomplete } from 'modules/credit/components/CreditAutocomplete'
import { CategoryMoveAutocomplete } from 'modules/category-move/components/CategoryMoveAutocomplete'
import {
  CategoryMove,
  MoveType,
} from 'modules/category-move/models/CategoryMove'
import {
  PaymentType,
  paymentTypes,
} from 'modules/account/models/AccountPayment'

export interface FilterProps extends CardProps {
  loading?: boolean
  onFilter: (payload: ListOutcomePayload, values: FilterForm) => void
}

interface FilterForm {
  organization?: Organization
  accountType: AccountType | 'all'
  bankAccount?: BankAccount
  creditCard?: CreditCard
  credit?: Credit
  category: CategoryMove
  year: number
  month: number
  paymentType: PaymentType | 'all'
  paymentCreditCard?: CreditCard
  paymentCredit?: Credit
}

const schema = yup.object().shape({
  organization: yup.object().nullable(),
  accounType: yup.string().isOptional('all'),
  bankAccount: yup.object().nullable(),
  creditCard: yup.object().nullable(),
  credit: yup.object().nullable(),
  category: yup.object().nullable(),
  year: yup.number().required('Debe seleccionar un año'),
  month: yup.number().required('Debe seleccionar un mes'),
  paymentType: yup.string().isOptional('all'),
  paymentCreditCard: yup.object().nullable(),
  paymentCredit: yup.object().nullable(),
})

const baseYear = 2021

const years = generateNumberList(dayjs().year() - baseYear + 1).map(
  n => n + baseYear,
)

const buildFilter = ({
  organization,
  accountType,
  bankAccount,
  creditCard,
  credit,
  category,
  year,
  month,
  paymentType,
  paymentCredit,
  paymentCreditCard,
}: FilterForm) => {
  const monthDate = dayjs(`${month + 1}/${year}`, 'M/YYYY')

  return {
    organizationId: organization?.id,
    accountType: accountType === 'all' ? null : accountType,
    bankAccountId: bankAccount?.id,
    creditCardId: creditCard?.id,
    creditId: credit?.id,
    categoryId: category?.id,
    initDate: monthDate.startOf('month').toDate(),
    endDate: monthDate.endOf('month').toDate(),
    paymentType: paymentType === 'all' ? null : paymentType,
    paymentId: paymentCredit?.id ?? paymentCreditCard?.id,
  }
}

const now = dayjs()

export const Filter = ({
  onFilter,
  className,
  loading,
  ...props
}: FilterProps) => {
  const defaultValues = useConstant<FilterForm>(() => ({
    year: now.year(),
    month: now.month(),
    organization: null,
    accountType: 'all',
    bankAccount: null,
    creditCard: null,
    credit: null,
    category: null,
    paymentType: 'all',
    paymentCredit: null,
    paymentCreditCard: null,
  }))

  const formInstance = useForm<FilterForm>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  React.useEffect(() => {
    onFilter(buildFilter(defaultValues), defaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  const onSubmit = (values: FilterForm) => {
    onFilter(buildFilter(values), values)
  }

  return (
    <Card {...props} className={clsx('flex flex-row items-center', className)}>
      <Typography className="flex-none" variant="body1">
        Filtrar listado
      </Typography>
      <Form
        formProps={{ className: 'flex-grow ml-48 flex flex-row items-center' }}
        onSubmit={onSubmit}
        {...formInstance}
      >
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <SelectForm
                  name="year"
                  label="Seleccionar año"
                  fullWidth
                  disabled={loading}
                  required
                >
                  {years.map(year => {
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    )
                  })}
                </SelectForm>
                <OrganizationAutocomplete
                  name="organization"
                  inputProps={{
                    label: 'Organización',
                    placeholder: 'Buscar organización...',
                  }}
                  fullWidth
                  disabled={loading}
                ></OrganizationAutocomplete>
              </Grid>
              <Grid item xs={6}>
                <SelectForm
                  name="month"
                  label="Seleccionar año"
                  fullWidth
                  disabled={loading}
                  required
                >
                  {dayjs.months().map((month, index) => {
                    return (
                      <MenuItem key={month} value={index}>
                        {month}
                      </MenuItem>
                    )
                  })}
                </SelectForm>
                <CategoryMoveAutocomplete
                  name="category"
                  type={MoveType.outcome}
                  inputProps={{ label: 'Categoría' }}
                  placeholder="Buscar categoría"
                  fullWidth
                ></CategoryMoveAutocomplete>
              </Grid>
              <Grid item xs={6}>
                <SelectForm
                  name="accountType"
                  label="Pagado desde"
                  fullWidth
                  disabled={loading}
                >
                  <MenuItem value="all">
                    <em>Todos</em>
                  </MenuItem>
                  {accountTypes.map(pm => (
                    <MenuItem key={pm.value} value={pm.value}>
                      {pm.text}
                    </MenuItem>
                  ))}
                </SelectForm>
                <CreditForm></CreditForm>
                <CreditCardForm></CreditCardForm>
                <BankAccountForm></BankAccountForm>
              </Grid>
              <Grid item xs={6}>
                <SelectForm
                  name="paymentType"
                  label="Pagado a"
                  fullWidth
                  disabled={loading}
                >
                  <MenuItem value="all">
                    <em>Todos</em>
                  </MenuItem>
                  {paymentTypes.map(pm => (
                    <MenuItem key={pm.value} value={pm.value}>
                      {pm.text}
                    </MenuItem>
                  ))}
                </SelectForm>
                <PaymentCreditForm></PaymentCreditForm>
                <PaymentCreditCardForm></PaymentCreditCardForm>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} className="flex justify-end items-center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Card>
  )
}

function CreditCardForm() {
  const { getValues, control } = useFormContext()

  const accountType = useWatch<AccountType>({
    control,
    name: 'accountType',
    defaultValue: getValues('accountType'),
  })

  return accountType === AccountType.creditCard ? (
    <CreditCardAutocomplete
      name="creditCard"
      inputProps={{ label: 'Tarjeta de crédito' }}
      placeholder="Buscar el tarjeta"
      fullWidth
    ></CreditCardAutocomplete>
  ) : null
}

function CreditForm() {
  const { getValues, control } = useFormContext()

  const accountType = useWatch<AccountType>({
    control,
    name: 'accountType',
    defaultValue: getValues('accountType'),
  })

  return accountType === AccountType.credit ? (
    <CreditAutocomplete
      name="credit"
      inputProps={{ label: 'Crédito' }}
      placeholder="Buscar el crédito"
      fullWidth
    ></CreditAutocomplete>
  ) : null
}

function BankAccountForm() {
  const { getValues, control } = useFormContext()

  const accountType = useWatch<AccountType>({
    control,
    name: 'accountType',
    defaultValue: getValues('accountType'),
  })

  return accountType === AccountType.bankAccount ? (
    <BankAccountAutocomplete
      name="bankAccount"
      inputProps={{ label: 'Cuenta de banco' }}
      placeholder="Buscar cuenta de banco"
      fullWidth
    ></BankAccountAutocomplete>
  ) : null
}

function PaymentCreditCardForm() {
  const { getValues, control } = useFormContext()

  const paymentType = useWatch<AccountType>({
    control,
    name: 'paymentType',
    defaultValue: getValues('paymentType'),
  })

  return paymentType === AccountType.creditCard ? (
    <CreditCardAutocomplete
      name="paymentCreditCard"
      inputProps={{ label: 'Tarjeta de crédito' }}
      placeholder="Buscar el tarjeta"
      fullWidth
    ></CreditCardAutocomplete>
  ) : null
}

function PaymentCreditForm() {
  const { getValues, control } = useFormContext()

  const paymentType = useWatch<AccountType>({
    control,
    name: 'paymentType',
    defaultValue: getValues('paymentType'),
  })

  return paymentType === AccountType.credit ? (
    <CreditAutocomplete
      name="paymentCredit"
      inputProps={{ label: 'Crédito' }}
      placeholder="Buscar el crédito"
      fullWidth
    ></CreditAutocomplete>
  ) : null
}
