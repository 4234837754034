import * as React from 'react'
import { makeStyles } from '@material-ui/core'

export interface SimplePageProps {
  header?: React.ReactNode
  contentToolbar?: React.ReactNode
}

const headerHeight = 100

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    position: 'relative',
    flex: '1 0 auto',
    height: '100%',
    backgroundColor: theme.palette.grey[300],
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    zIndex: 2,
    maxWidth: '100%',
    minWidth: 0,
    backgroundColor: theme.palette.grey[300],
  },
  header: {
    height: headerHeight,
    minHeight: headerHeight,
    display: 'flex',
    background: `linear-gradient(to left, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    color: theme.palette.primary.contrastText,
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.dark,
  },
  topBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: headerHeight,
    pointerEvents: 'none',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    zIndex: 9999,
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flex: '1 0 auto',
  },
  backdrop: {
    position: 'absolute',
  },
}))

export const SimplePage: React.FC<SimplePageProps> = ({
  header,
  contentToolbar,
  children,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className="flex flex-auto flex-col container z-10 h-full">
        {header && <div className={classes.header}>{header}</div>}

        <div className={classes.wrapper}>
          <div className={classes.contentWrapper}>
            {contentToolbar && (
              <div className={classes.toolbar}>{contentToolbar}</div>
            )}

            {children && <div className={classes.content}>{children}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}
