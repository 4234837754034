import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Organization } from '../models/Organization'
import { useDisclosure } from 'shared/hooks'
import { useSnackbar } from 'notistack'
import { useDeleteOrganizationMutation } from '../data/Mutations'

export interface DeleteProps {
  organizations: Organization[]
}

const dialogId = 'OrganizationDeleteDialog'

export const Delete = ({ organizations }: DeleteProps) => {
  const { isOpen, open, close } = useDisclosure()

  const { enqueueSnackbar } = useSnackbar()

  const [deleteOrganization] = useDeleteOrganizationMutation()

  const deleteCards = async () => {
    try {
      await Promise.all(
        organizations.map(u => deleteOrganization({ variables: { id: u.id } })),
      )
      enqueueSnackbar(
        `${organizations.length} organizaciones eliminadas correctamente`,
        { variant: 'success' },
      )
      close()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        `Ha ocurrido un error al eliminar las organizaciones . Intentelo más tarde`,
        { variant: 'error' },
      )
    }
  }

  return (
    <>
      <Tooltip title="Eliminar organizaciones">
        <IconButton aria-label="eliminar" onClick={open}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        open={isOpen}
        onClose={close}
        aria-labelledby={dialogId}
      >
        <DialogTitle id={dialogId}>Eliminar organizaciones</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Deseas eliminar las siguientes organizaciones?
          </DialogContentText>
          <List dense>
            {organizations.map(org => (
              <ListItem key={org.id}>
                <ListItemText primary={`- ${org.name}`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteCards} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
