import * as React from 'react'
import {
  Card,
  CardProps,
  Checkbox,
  FormControlLabel,
  Grid,
} from '@material-ui/core'
import dayjs from 'dayjs'
import { generateNumberList } from 'core/utils'
import { OrganizationAutocompleteInput } from 'modules/organization/components/OrganizationAutcomplete'
import { Organization } from 'modules/organization/models/Organization'
import { DatePicker } from 'shared/common/DatePicker'

export interface FilterProps extends CardProps {
  loading?: boolean
  filter: FilterValues
  onFilter: (values: FilterValues) => void
}

export interface FilterValues {
  month: dayjs.Dayjs
  aggregate?: boolean
  organization?: Organization
}

const baseYear = 2021

const years = generateNumberList(dayjs().year() - baseYear + 1).map(
  n => n + baseYear,
)
const minYearDate = dayjs(`${years[0]}/1`, 'YYYY/M')
const maxYearDate = dayjs(`${years[years.length - 1]}/12`, 'YYYY/M')

export const Filter = ({ filter, onFilter, loading }: FilterProps) => {
  const handleFilterChange = (newFilter: Partial<FilterValues>) => {
    const values = { ...filter, ...newFilter }
    onFilter(values)
  }

  return (
    <Card className="flex flex-row items-center h-full w-640 p-12">
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <DatePicker
            value={filter.month}
            onChange={date => handleFilterChange({ month: date })}
            label="Seleccionar mes"
            views={['year', 'month']}
            minDate={minYearDate}
            maxDate={maxYearDate}
            disabled={loading}
            inputProps={{ fullWidth: true }}
          ></DatePicker>
        </Grid>
        <Grid item xs={5}>
          <OrganizationAutocompleteInput<false, false, false>
            inputProps={{ label: 'Seleccionar organización', required: true }}
            onChange={(_, organization) => {
              handleFilterChange({ organization })
            }}
            placeholder="Buscar organización..."
            fullWidth
            disabled={loading}
          ></OrganizationAutocompleteInput>
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!!filter.aggregate}
                onChange={(_, checked) =>
                  handleFilterChange({ aggregate: checked })
                }
              />
            }
            label="Acumulado"
          />
        </Grid>
      </Grid>
    </Card>
  )
}
