import { WatchQueryFetchPolicy } from '@apollo/client'
import * as React from 'react'
import { useNetIncomeChartData, useNetIncomeTotals } from '../data/Queries'

export interface UseNetIncomeTotalsStateOptions {
  pollInterval?: number
  fetchPolicy?: WatchQueryFetchPolicy
  nextFetchPolicy?: WatchQueryFetchPolicy
}

export const useNetIncomeTotalsState = ({
  pollInterval,
  fetchPolicy,
  nextFetchPolicy,
}: UseNetIncomeTotalsStateOptions = {}) => {
  const [getTotals, totalsState] = useNetIncomeTotals({
    pollInterval,
    fetchPolicy,
    nextFetchPolicy,
  })

  const [getChartData, chartState] = useNetIncomeChartData({
    pollInterval,
    fetchPolicy,
    nextFetchPolicy,
  })

  const { data: totalsData } = totalsState
  const { data: chartData } = chartState

  const totals = React.useMemo(() => {
    if (!totalsData?.getIncomeAndOutcome) {
      return
    }

    return {
      income: totalsData?.getIncomeAndOutcome.find(r => r.name === 'income')
        ?.amount,
      creditCardIncome: totalsData?.getIncomeAndOutcome.find(
        r => r.name === 'creditCardIncome',
      )?.amount,
      creditIncome: totalsData?.getIncomeAndOutcome.find(
        r => r.name === 'creditIncome',
      )?.amount,
      totalCredits: totalsData?.getIncomeAndOutcome.find(
        r => r.name === 'totalCredits',
      )?.amount,
      outcome: totalsData?.getIncomeAndOutcome.find(r => r.name === 'outcome')
        ?.amount,
      total: totalsData?.getIncomeAndOutcome.find(r => r.name === 'total')
        ?.amount,
      initialBalance: totalsData?.getIncomeAndOutcome.find(
        r => r.name === 'initialBalance',
      )?.amount,
      balance: totalsData?.getIncomeAndOutcome.find(r => r.name === 'balance')
        ?.amount,
    }
  }, [totalsData])

  const chart = React.useMemo(() => {
    if (!chartData?.getIncomeAndOutcomeDay) {
      return null
    }

    return {
      labels: chartData?.getIncomeAndOutcomeDay.map(r => r.name),
      income: chartData?.getIncomeAndOutcomeDay.map(r => r.income),
      outcome: chartData?.getIncomeAndOutcomeDay.map(r => r.outcome),
    }
  }, [chartData])

  return { totals, chart, getTotals, getChartData, totalsState, chartState }
}

export type UseNetIncomeTotalsState = ReturnType<typeof useNetIncomeTotalsState>
