export const typography = {
  fontFamily: ['Muli', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(
    ',',
  ),
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  htmlFontSize: 10,
  body1: {
    fontSize: '1.4rem',
  },
  body2: {
    fontSize: '1.4rem',
  },
}
