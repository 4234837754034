import { useMutation, gql } from '@apollo/client'
import { Credit, CreditPaymentType } from '../models/Credit'
import { CreditFragment } from './Queries'
import { Status } from 'models/BaseModel'

export interface ChangeCreditStatusPayload {
  id: string
  status: Status
}

const CHANGE_CREDIT_STATUS = gql`
  mutation ChangeCreditStatus($data: ChangeCreditStatusPayload!) {
    changeCreditStatus(data: $data) {
      id
      status
    }
  }
`

export const useChangeCreditStatusMutation = () => {
  const tuple = useMutation<
    { changeCreditStatus: Credit },
    { data: ChangeCreditStatusPayload }
  >(CHANGE_CREDIT_STATUS)

  return tuple
}

export interface UpdateCreditPayload {
  id: string
  name: string
  typeCreditId: string
  organizationId: string
  creditorId: string
  initialBalance?: number
}

const UPDATE_CREDIT = gql`
  mutation UpdateCredit($data: UpdateCreditPayload!) {
    updateCredit(data: $data) {
      ...CreditFragment
    }
  }
  ${CreditFragment}
`

export const useUpdateCreditMutation = () => {
  const tuple = useMutation<
    { updateCredit: Credit },
    { data: UpdateCreditPayload }
  >(UPDATE_CREDIT)

  return tuple
}

const DELETE_CREDIT = gql`
  mutation DeleteCredit($id: String!) {
    deleteCredit(id: $id) {
      id
      status
    }
  }
`

export const useDeleteCreditMutation = () => {
  const tuple = useMutation<{ deleteCredit: Credit }, { id: string }>(
    DELETE_CREDIT,
  )

  return tuple
}

export interface RegisterCreditPayload {
  name: string
  typeCreditId: string
  organizationId: string
  creditorId: string
  totalDebt: number
  debt?: number
  term: number
  paymentType: CreditPaymentType
  interest: number
  initDate: Date
  pay?: number
  lastPaymentDate?: Date
}

const REGISTER_CREDIT = gql`
  mutation CreateCredit($data: RegisterCreditPayload!) {
    registerCredit(data: $data) {
      ...CreditFragment
    }
  }
  ${CreditFragment}
`

export const useRegisterCreditMutation = () => {
  const tuple = useMutation<
    { registerCredit: Credit },
    { data: RegisterCreditPayload }
  >(REGISTER_CREDIT)

  return tuple
}
