import { BaseModel } from 'models/BaseModel'
import { CreditCard } from 'modules/credit-card/models/CreditCard'
import { Credit } from 'modules/credit/models/Credit'
import { Outcome } from 'modules/outcome/models/Outcome'

export enum PaymentType {
  credit = 'credit',
  creditCard = 'creditCard',
}

export const paymentTypes: { text: string; value: PaymentType }[] = [
  {
    text: 'Crédito',
    value: PaymentType.credit,
  },
  {
    text: 'Tarjeta de crédito',
    value: PaymentType.creditCard,
  },
]

export class AccountPayment extends BaseModel {
  date: Date
  period: string
  capital: number
  interest?: number
  otherAmount?: number
  outcome: Outcome
  creditCard: CreditCard
  credit: Credit
  amount: number

  constructor(data: Partial<AccountPayment>) {
    const { credit, creditCard, outcome, ...values } = data
    super(data)
    this.outcome = new Outcome(outcome)
    if (credit) {
      this.credit = new Credit(credit)
    }

    if (creditCard) {
      this.creditCard = new CreditCard(creditCard)
    }
    Object.assign(this, values)
  }
}
