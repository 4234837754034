import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { StatementOfIncomes } from './statement-of-incomes'
import { AccountBalanceReport } from './account-balance'
import { MonthByMonthReport } from './month-by-month'
import { WithPermissions } from 'auth/components/WithPermissions'

export const ReportsPages = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${url}/statement-of-incomes`}>
        <WithPermissions
          permissions={['report.statementOfIncomes']}
          rejected={redirectToHome}
        >
          <StatementOfIncomes></StatementOfIncomes>
        </WithPermissions>
      </Route>

      <Route path={`${url}/month-by-month`}>
        <WithPermissions
          permissions={['report.monthByMonth']}
          rejected={redirectToHome}
        >
          <MonthByMonthReport></MonthByMonthReport>
        </WithPermissions>
      </Route>
      <Route path={`${url}/account-balance`}>
        <WithPermissions
          permissions={['report.accountBalance']}
          rejected={redirectToHome}
        >
          <AccountBalanceReport></AccountBalanceReport>
        </WithPermissions>
      </Route>
      {redirectToHome()}
    </Switch>
  )
}

function redirectToHome() {
  return <Redirect to="/"></Redirect>
}
