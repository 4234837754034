import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client'
import {
  CategoryAmount,
  MoveType,
} from 'modules/category-move/models/CategoryMove'
import { CategoryAmountMovesFilter } from 'modules/reports/statement-of-incomes/data/UseCategoryAmounts'

export interface CategoryAmountCompareFilter {
  first: CategoryAmountMovesFilter
  second: CategoryAmountMovesFilter
  moveType: MoveType
  categoryOutcomeType?: string
}

export type CategoryAmountCompare = Omit<CategoryAmount, 'amount'> & {
  first: string
  second: string
  diff: string
}

export interface ListCategoryAmountCompareResponse {
  categoryAmounts: CategoryAmountCompare[]
}

export interface ListCategoryAmountCompareVars {
  data: CategoryAmountCompareFilter
}

const QUERY = gql`
  query ListCategoryAmountCompare($data: CategoryAmountCompareFilter!) {
    categoryAmounts: listCategoryAmountsCompare(data: $data) {
      id
      name
      first
      second
      diff
      parentId
    }
  }
`

export const useCompareCategoryAmounts = (
  options?: QueryHookOptions<
    ListCategoryAmountCompareResponse,
    ListCategoryAmountCompareVars
  >,
) => {
  return useLazyQuery<
    ListCategoryAmountCompareResponse,
    ListCategoryAmountCompareVars
  >(QUERY, options)
}
