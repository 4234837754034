const TOKEN = 'cc-saip'

let token: string

const getToken = () => {
  if (!token) {
    token = localStorage.getItem(TOKEN)
  }

  return token
}

const setToken = (newToken: string) => {
  localStorage.setItem(TOKEN, newToken)
  token = newToken
}

const TENANT = 'cc-sat'

let tenant: string

const getTenant = () => {
  if (!tenant) {
    tenant = localStorage.getItem(TENANT)
  }

  return tenant
}

const setTenant = (newTenant: string) => {
  if (!newTenant) {
    return
  }
  localStorage.setItem(TENANT, newTenant)
  tenant = newTenant
}

const clearSession = () => {
  token = null
  localStorage.removeItem(TOKEN)
}

export const AuthRepository = {
  getToken,
  setToken,
  getTenant,
  setTenant,
  clearSession,
}
