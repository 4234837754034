import { gql } from '@apollo/client'
import { apolloClient } from 'core/GqlClient'
import { User } from '../models/User'

const GET_CURRENT_USER = gql`
  query {
    getCurrentUser {
      id
      email
      firstName
      lastName
      isSuperuser
      profiles {
        id
        name
        permissions {
          id
          name
        }
      }
      permissions {
        id
        name
      }
      tenants {
        id
        name
      }
    }
  }
`
export const fetchCurrentUser = async () => {
  const result = await apolloClient.query<{ getCurrentUser: User }>({
    query: GET_CURRENT_USER,
  })

  return result.data.getCurrentUser
}
