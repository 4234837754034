import * as React from 'react'
import dayjs from 'dayjs'
import { DataTable, useDataTable } from 'shared/common/DataTable'
import { useProfilesList } from 'modules/user/data/Queries'
import { UserProfile } from 'auth/models/UserProfile'
import { Column } from 'react-table'
import { EditButton } from 'shared/crud/EditButton'
import { Card } from '@material-ui/core'

export const List = () => {
  const { data: profilesListData } = useProfilesList({
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const columns = React.useMemo<Column<UserProfile>[]>(() => {
    return [
      {
        id: 'fullName',
        Header: 'Nombre',
        accessor: 'name',
      },
      {
        Header: 'Descripción',
        accessor: 'label',
      },
      {
        Header: 'Fecha de creación',
        accessor: 'createdAt',
        Cell: ({ value }) => dayjs(value).format('L'),
      },
      {
        disableSortBy: true,
        Header: 'Acciones',
        accessor: profile => (
          <EditButton
            title="Editar perfil de usuario"
            aria-label="editar perfil de usuario"
            id={profile.id}
            tenantId={profile.tenantId}
          ></EditButton>
        ),
      },
    ]
  }, [])

  const data = React.useMemo(() => {
    return profilesListData?.listUserProfiles ?? []
  }, [profilesListData])

  const tableInstance = useDataTable({
    columns,
    data,
  })

  return (
    <Card>
      <DataTable {...tableInstance}></DataTable>
    </Card>
  )
}
