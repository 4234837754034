import * as React from 'react'
import clsx from 'clsx'
import {
  Card,
  CardContent,
  lighten,
  makeStyles,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    background: `linear-gradient(to right, ${
      theme.palette.grey[400]
    } 0%, ${lighten(theme.palette.grey[100], 0.5)} 100%)`,
    color: theme.palette.primary.main,
  },
  logo: {
    width: 300,
    height: 'auto',
  },
}))

export const LoginLayout: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.root,
        'flex flex-col flex-auto flex-shrink-0 p-24 md:flex-row md:p-0',
      )}
    >
      <div className="flex flex-col flex-grow-0 justify-start items-center  p-16 text-center md:p-80 md:flex-shrink-0 md:flex-1 md:text-left">
        {/* <FuseAnimate animation="transition.expandIn"> */}
        <img
          className="w-1/2 max-w-md"
          src="assets/images/logo.png"
          alt="logo"
        />
        {/* </FuseAnimate> */}

        {/* <FuseAnimate animation="transition.slideUpIn" delay={300}> */}
        <Typography variant="h3" color="inherit">
          Bienvenido!
        </Typography>
        {/* </FuseAnimate> */}
      </div>

      {/* <FuseAnimate animation={{ translateX: [0, '100%'] }}> */}
      <Card
        elevation={2}
        className="w-full max-w-400 mx-auto m-16 md:m-0"
        square
      >
        <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
          {children}
        </CardContent>
      </Card>
      {/* </FuseAnimate> */}
    </div>
  )
}
