import { Organization } from '../models/Organization'
import { Status } from 'models/BaseModel'
import { StatusMenu } from 'shared/crud'
import { useChangeOrganizationStatusMutation } from '../data/Mutations'

export interface StatusUpdateProps {
  organization: Organization
}

export const StatusUpdate = ({ organization }: StatusUpdateProps) => {
  const { id, status, tenantId } = organization

  const [updateStatus, { loading }] = useChangeOrganizationStatusMutation()

  const handleChange = (newStatus: Status) => {
    updateStatus({ variables: { data: { id, status: newStatus } } })
  }

  return (
    <StatusMenu
      value={status}
      onChange={handleChange}
      disabled={loading || !tenantId}
    ></StatusMenu>
  )
}
