import { CompanyType } from 'modules/company/models/Company'
import { CreditKind } from '../models/Credit'

export function getCreditCompanyTypes(creditKind?: CreditKind) {
  if (!creditKind) {
    return [CompanyType.creditor, CompanyType.customer]
  }

  if (creditKind === CreditKind.customer) {
    return [CompanyType.customer]
  }

  return [CompanyType.creditor]
}
