import * as React from 'react'
import { AutocompleteForm, AutocompleteFormProps } from 'shared/forms'
import { useTypeCreditList } from '../data/Queries'
import { TypeCredit } from '../models/TypeCredit'

export interface TypeCreditAutocompleteProps<
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
> extends Omit<
    AutocompleteFormProps<TypeCredit, Multiple, DisableClearable, FreeSolo>,
    'options' | 'loading'
  > {
  onOptionsLoaded?: (options: TypeCredit[]) => void
}

export const TypeCreditAutocomplete = <
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
>({
  getOptionLabel = option => option.name,
  getOptionSelected = (option, value) => option.id === value.id,
  onOptionsLoaded,
  ...props
}: TypeCreditAutocompleteProps<Multiple, DisableClearable, FreeSolo>) => {
  const { loading, data } = useTypeCreditList({
    fetchPolicy: 'network-only',
    onCompleted: data => {
      onOptionsLoaded?.(data.listTypeCredit)
    },
  })

  const options = React.useMemo(() => {
    return data?.listTypeCredit ?? []
  }, [data])

  return (
    <AutocompleteForm
      loading={loading}
      options={options}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      {...props}
    ></AutocompleteForm>
  )
}
