import { createMuiTheme } from '@material-ui/core'
import { mixins } from './mixins'
import { overrides } from './overrides'
import { palette } from './palette'
import { typography } from './typography'

export const AppTheme = createMuiTheme({
  palette,
  typography,
  mixins,
  overrides,
})
