import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client'
import { AccountType } from 'modules/account/models/Account'
import { AccountMove } from 'modules/account/models/AccountMove'
import { BankAccount } from 'modules/bank-account/models/BankAccount'
import { CreditCard } from 'modules/credit-card/models/CreditCard'
import { Credit, CreditKind } from 'modules/credit/models/Credit'
import { Organization } from 'modules/organization/models/Organization'

export interface ReportBaseFilter {
  initDate: Date
  endDate: Date
  organizationId?: string
}
export interface ListAccountBalancePayload {
  initDate: Date
  endDate: Date
  accountType?: AccountType
  creditKind?: CreditKind
  organizationId?: string
  bankAccountId?: string
  creditId?: string
  creditCompanyId?: string
  creditCardId?: string
}

export interface AccountBalance {
  id: string
  initialBalance: number
  income: number
  transferIncome: number
  outcome: number
  transferOutcome: number
  balance: number
  organization?: Organization
  bankAccount?: BankAccount
  creditCard?: CreditCard
  credit?: Credit
}

export interface ListAccountBalanceResponse {
  listAccountBalance: AccountBalance[]
}

export const AccountBalanceFragment = gql`
  fragment AccountBalanceFragment on AccountBalance {
    id
    initialBalance
    income
    transferIncome
    outcome
    transferOutcome
    balance
    organization {
      id
      name
    }
    bankAccount {
      id
      name
    }
    creditCard {
      id
      name
    }
    credit {
      id
      name
      kind
      company {
        id
        name
      }
    }
  }
`

const LIST = gql`
  query ListAccountBalance($data: ListAccountBalancePayload!) {
    listAccountBalance(data: $data) {
      ...AccountBalanceFragment
    }
  }
  ${AccountBalanceFragment}
`

export const useAccountBalanceList = (
  options?: QueryHookOptions<
    ListAccountBalanceResponse,
    { data: ListAccountBalancePayload }
  >,
) => {
  const result = useLazyQuery<
    ListAccountBalanceResponse,
    { data: ListAccountBalancePayload }
  >(LIST, options)
  return result
}

export interface ListAccountBalanceDetailResponse {
  listAccountBalanceDetail: AccountMove[]
}

const DETAIL = gql`
  query ListAccountBalanceDetail(
    $account: String!
    $data: ListAccountBalancePayload!
  ) {
    listAccountBalanceDetail(account: $account, data: $data) {
      id
      date
      initialBalance
      amount
      balance
      moveType
      notes
      income {
        id
        category {
          id
          name
        }
        company {
          id
          name
        }
      }
      outcome {
        id
        category {
          id
          name
        }
        company {
          id
          name
        }
      }
    }
  }
`

export const useAccountBalanceDetail = (
  options?: QueryHookOptions<
    ListAccountBalanceDetailResponse,
    { account: string; data: ListAccountBalancePayload }
  >,
) => {
  const result = useLazyQuery<
    ListAccountBalanceDetailResponse,
    { account: string; data: ListAccountBalancePayload }
  >(DETAIL, options)
  return result
}
