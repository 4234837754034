import * as React from 'react'
import { AppBar, Hidden, makeStyles, useTheme } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import clsx from 'clsx'
import { Scrollbars } from 'shared'
import { NavbarMobileToggleButton } from './NavbarMobileToggleButton'
import { NavbarFoldedToggleButton } from './NavbarFoldedToggleButton'
import Logo from './Logo'
import { Navigation } from 'navigation/Navigation'

const useStyles = makeStyles(theme => ({
  root: {
    '& ::-webkit-scrollbar-thumb': {
      boxShadow: `inset 0 0 0 20px ${
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.24)'
          : 'rgba(255, 255, 255, 0.24)'
      }`,
    },
    '& ::-webkit-scrollbar-thumb:active': {
      boxShadow: `inset 0 0 0 20px ${
        theme.palette.type === 'light'
          ? 'rgba(0, 0, 0, 0.37)'
          : 'rgba(255, 255, 255, 0.37)'
      }`,
    },
  },
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    background:
      'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 10px',
    backgroundAttachment: 'local, scroll',
  },
}))

export const NavbarLayout: React.FC<{ className: string }> = props => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div
      className={clsx(
        'flex flex-col overflow-hidden h-full',
        classes.root,
        props.className,
      )}
    >
      <AppBar
        color="primary"
        position="static"
        className="flex flex-row items-center flex-shrink h-48 md:h-64 min-h-48 md:min-h-64 px-12 shadow-0"
      >
        <div className="flex flex-1 mx-8">
          <Logo></Logo>
        </div>

        <Hidden mdDown>
          <NavbarFoldedToggleButton className="w-40 h-40 p-0" />
        </Hidden>

        <Hidden lgUp>
          <NavbarMobileToggleButton className="w-40 h-40 p-0">
            {theme.direction === 'ltr' ? (
              <ArrowBackIcon></ArrowBackIcon>
            ) : (
              <ArrowForwardIcon></ArrowForwardIcon>
            )}
          </NavbarMobileToggleButton>
        </Hidden>
      </AppBar>

      <Scrollbars
        className={clsx(classes.content)}
        option={{ suppressScrollX: true }}
      >
        <Navigation className="navigation"></Navigation>
      </Scrollbars>
    </div>
  )
}
