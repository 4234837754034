import * as React from 'react'
import { useAuthContext, useAuthDispatch } from 'auth/contexts/AuthContext'
import {
  Avatar,
  Button,
  Icon,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core'
import { AuthRepository } from 'auth/data/AuthRepository'
import { useDisclosure } from 'shared/hooks'
import { ChangeUserPassword } from 'modules/user/components/ChangeUserPassword'

export const NavbarUserMenu = () => {
  const [userMenu, setUserMenu] = React.useState(null)

  const { user } = useAuthContext()
  const dispatch = useAuthDispatch()

  const userMenuClick: React.MouseEventHandler<HTMLButtonElement> = ev => {
    setUserMenu(ev.currentTarget)
  }

  const userMenuClose = () => {
    setUserMenu(null)
  }

  const logout = () => {
    dispatch({ type: 'logout' })
    AuthRepository.clearSession()
    userMenuClose()
  }

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
      >
        <Avatar color="secondary" className="md:mx-4">
          {user.firstName[0]}
        </Avatar>
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="normal-case font-bold flex">
            {user.firstName} {user.lastName}
          </Typography>
        </div>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        <ChangePasswordMenuItem userId={user.id}></ChangePasswordMenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon className="min-w-40">
            <Icon>exit_to_app</Icon>
          </ListItemIcon>
          <ListItemText primary="Cerrar sesión" />
        </MenuItem>
      </Popover>
    </>
  )
}

function ChangePasswordMenuItem({ userId }) {
  const { isOpen, open, close } = useDisclosure({ defaultIsOpen: false })

  return (
    <>
      <MenuItem onClick={open}>
        <ListItemIcon className="min-w-40">
          <Icon>vpn_key</Icon>
        </ListItemIcon>
        <ListItemText primary="Cambiar contraseña" />
      </MenuItem>
      <ChangeUserPassword
        isOpen={isOpen}
        onClose={close}
        userId={userId}
      ></ChangeUserPassword>
    </>
  )
}
