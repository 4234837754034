import { useMutation, gql } from '@apollo/client'
import { AccountType, BankAccount } from '../models/BankAccount'
import { Status } from 'models/BaseModel'

export interface ChangeBankAccountStatusPayload {
  id: string
  status: Status
}

const CHANGE_BANK_ACCOUNT_STATUS = gql`
  mutation ChangeBankAccountStatus($data: ChangeBankAccountStatusPayload!) {
    changeBankAccountStatus(data: $data) {
      id
      status
    }
  }
`

export const useChangeBankAccountStatusMutation = () => {
  const tuple = useMutation<
    { changeBankAccountStatus: BankAccount },
    { data: ChangeBankAccountStatusPayload }
  >(CHANGE_BANK_ACCOUNT_STATUS)

  return tuple
}

export interface UpdateBankAccountPayload {
  id: string
  accountNumber: string
  name: string
  ownerName: string
  accountType: AccountType
  bankId: string
  initialBalance?: number
}

const UPDATE_BANK_ACCOUNT = gql`
  mutation UpdateBankAccount($data: UpdateBankAccountPayload!) {
    updateBankAccount(data: $data) {
      id
      accountNumber
      name
      ownerName
      account {
        id
        initialBalance
        balance
      }
      bank {
        id
        name
        status
      }
      accountType
      status
      tenantId
    }
  }
`

export const useUpdateBankAccountMutation = () => {
  const tuple = useMutation<
    { updateBankAccount: BankAccount },
    { data: UpdateBankAccountPayload }
  >(UPDATE_BANK_ACCOUNT)

  return tuple
}

const DELETE_BANK_ACCOUNT = gql`
  mutation DeleteBankAccount($id: String!) {
    deleteBankAccount(id: $id) {
      id
      status
    }
  }
`

export const useDeleteBankAccountMutation = () => {
  const tuple = useMutation<{ deleteBankAccount: BankAccount }, { id: string }>(
    DELETE_BANK_ACCOUNT,
  )

  return tuple
}

export interface RegisterBankAccountPayload {
  accountNumber: string
  initialBalance: number
  name: string
  ownerName: string
  accountType: AccountType
  bankId: string
}

const REGISTER_BANK_ACCOUNT = gql`
  mutation CreateBankAccount($data: RegisterBankAccountPayload!) {
    registerBankAccount(data: $data) {
      id
      accountNumber
      name
      ownerName
      account {
        id
        initialBalance
        balance
      }
      bank {
        id
        name
        status
      }
      accountType
      status
      tenantId
    }
  }
`

export const useRegisterBankAccountMutation = () => {
  const tuple = useMutation<
    { registerBankAccount: BankAccount },
    { data: RegisterBankAccountPayload }
  >(REGISTER_BANK_ACCOUNT)

  return tuple
}
