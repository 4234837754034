import * as React from 'react'
import { Switch, useLocation } from 'react-router-dom'
import { Fade } from 'shared/transitions'

export const PrivateContent: React.FC = ({ children }) => {
  const location = useLocation()

  const { pathname } = location

  const nextSlashIndex = pathname.indexOf('/', 1)

  const key = `/${pathname.slice(
    1,
    nextSlashIndex >= 0 ? nextSlashIndex : undefined,
  )}`

  return (
    <Fade key={key}>
      <Switch location={location}>{children}</Switch>
    </Fade>
  )
}
