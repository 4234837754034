import * as React from 'react'
import clsx from 'clsx'
import {
  Icon,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core'
import { NavProps } from './types'
import { NavigationLink } from '../types'
import { NavigationBadge } from '../NavigationBadge'
import { useNavigationElement } from 'navigation/hooks/useNavigationElement'

const useStyles = makeStyles<Theme, { itemPadding: number }>(theme => ({
  item: props => ({
    height: 40,
    width: 'calc(100% - 16px)',
    borderRadius: '0 20px 20px 0',
    paddingRight: 12,
    paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
    '&.active': {
      backgroundColor: `${theme.palette.secondary.main}!important`,
      color: `${theme.palette.secondary.contrastText}!important`,
      pointerEvents: 'none',
      transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
      '& .list-item-text-primary': {
        color: 'inherit',
      },
      '& .list-item-icon': {
        color: 'inherit',
      },
    },
    '& .list-item-icon': {
      marginRight: 16,
    },
    '& .list-item-text': {},
    color: theme.palette.text.primary,
    textDecoration: 'none !important',
  }),
}))

export const NavLink: React.FC<NavProps<NavigationLink>> = props => {
  const { item, nestedLevel } = props

  const { closeNavbar, itemPadding } = useNavigationElement(nestedLevel)

  const classes = useStyles({
    itemPadding,
  })

  return (
    <ListItem
      button
      component="a"
      href={item.url}
      target={item.target ? item.target : '_blank'}
      className={clsx(classes.item, 'list-item')}
      onClick={closeNavbar}
      role="button"
    >
      {item.icon && (
        <Icon className="list-item-icon text-16 flex-shrink-0" color="action">
          {item.icon}
        </Icon>
      )}

      <ListItemText
        className="list-item-text"
        primary={item.title}
        classes={{ primary: 'text-14 list-item-text-primary' }}
      />

      {item.badge && <NavigationBadge badge={item.badge} />}
    </ListItem>
  )
}
