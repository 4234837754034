import Decimal from 'decimal.js-light'
import { CategoryAmountCompare } from 'modules/reports/month-by-month/data/UseCompareCategoryAmounts'

import { sumList } from 'shared/utils'

export interface CategoryAmountCompareElement extends CategoryAmountCompare {
  children?: CategoryAmountCompareElement[]
}

export type CategoryAmountTree = CategoryAmountCompareElement[]

export function buildCategoryAmountCompareTree(
  data: CategoryAmountCompare[],
): CategoryAmountTree {
  const firstLevels = data.filter(d => !d.parentId)
  return firstLevels.map(fl => resolveItem(fl, data))
}

function resolveItem(
  item: CategoryAmountCompare,
  data: CategoryAmountCompare[],
): CategoryAmountCompareElement {
  const children = data
    .filter(d => d.parentId === item.id)
    .map(child => resolveItem(child, data))

  const first = sumList(
    children.map(child => new Decimal(child.first)),
    new Decimal(item.first),
  )

  const second = sumList(
    children.map(child => new Decimal(child.second)),
    new Decimal(item.second),
  )

  const diff = sumList(
    children.map(child => new Decimal(child.diff)),
    new Decimal(item.diff),
  )

  return {
    ...item,
    first: first.toString(),
    second: second.toString(),
    diff: diff.toString(),
    children,
  }
}
