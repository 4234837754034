import * as React from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import { FieldError, useController, useFormContext } from 'react-hook-form'

export interface ITextFieldFormProps {
  TextComponent?: React.ComponentType<TextFieldProps>
  transform?: {
    output?: (
      ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => string
    input?: (value: string) => string
  }
}

export type TextFieldFormProps = ITextFieldFormProps & TextFieldProps

export const TextFieldForm: React.FC<TextFieldFormProps> = ({
  name = '',
  transform = {},
  TextComponent = TextField,
  autoComplete = 'nope',
  onChange: propOnChange,
  defaultValue,
  ...props
}) => {
  const { control, errors } = useFormContext()

  const error = errors[name] as FieldError

  const {
    field: { ref, value, onChange: formOnChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  })

  const getValue = (value: string) => {
    if (transform.input) {
      return transform.input(value)
    }

    return value
  }

  const setValue = (
    ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (transform.output) {
      return transform.output(ev)
    }

    return ev.target.value
  }

  const onChange = (
    ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    formOnChange(setValue(ev))

    propOnChange?.(ev)
  }

  return (
    <TextComponent
      {...inputProps}
      {...props}
      value={getValue(value)}
      onChange={e => onChange(e)}
      inputRef={ref}
      error={Boolean(error)}
      helperText={error?.message}
      autoComplete={autoComplete}
    ></TextComponent>
  )
}
