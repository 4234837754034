import { generateNumberList } from 'core/utils'
import dayjs from 'dayjs'
import { Frequency } from '../models/Frequency'

interface Option {
  text: string
  value: string
}

const months = dayjs.months()
const days = generateNumberList(30).map(day => day + 1)

export const formatCronString = (days: string, months = '*') => {
  return `0 0 ${days} ${months} *`
}

export const parseCron = (cron: string) => {
  const cronParts = cron.split(' ')

  if (cronParts.length !== 5) {
    throw new Error('Bad cron string')
  }

  const [minutes, hours, daysOfMonth, months, daysOfWeek] = cronParts

  return {
    minutes,
    hours,
    daysOfMonth,
    months,
    daysOfWeek,
  }
}

export const getMonthOptions = (frequency: Frequency): Option[] => {
  if (frequency === Frequency.annual) {
    return months.map((month, i) => ({
      text: month,
      value: (i + 1).toString(),
    }))
  }

  if (frequency === Frequency.semester) {
    return getLimitMonths(6)
  }

  if (frequency === Frequency.bimonthly) {
    return getLimitMonths(2)
  }

  return []
}

export const getDayOptions = (frequency: Frequency): Option[] => {
  if (frequency === Frequency.biweekly) {
    return getLimitDays(15)
  }

  return days.map(day => ({
    text: formatDay(day),
    value: day.toString(),
  }))
}

function getLimitDays(step: number): Option[] {
  return generateLimit(days, step).map(val => {
    const [start, end] = val

    const value = val.map(v => v + 1).join(',')

    return {
      value,
      text: `${formatDay(days[start])} - ${formatDay(days[end])}`,
    }
  })
}

function formatDay(day: number) {
  return day.toString().padStart(2, '0')
}

function getLimitMonths(step: number): Option[] {
  return generateLimit(months, step).map(val => {
    const [start, end] = val

    const value = val.map(v => v + 1).join(',')

    return {
      value,
      text: `${months[start]} - ${months[end]}`,
    }
  })
}

function generateLimit(array: any[], step: number): number[][] {
  const result: number[][] = []

  for (let index = 0; index < array.length - step; index++) {
    const endIndex = index + step
    result.push([index, endIndex])
  }

  return result
}
