import * as React from 'react'

export interface UseInitializeAutocompleteProps<Option extends object> {
  validate?: (options: Option[]) => boolean
  find: (option: Option) => boolean
  setValue: (option: Option) => void
}

export const useInitializeAutocomplete = <Option extends object>({
  validate,
  find,
  setValue,
}: UseInitializeAutocompleteProps<Option>): [(options: Option[]) => void] => {
  const [options, setOptions] = React.useState<Option[]>([])

  React.useEffect(() => {
    if (!validate?.(options)) {
      return
    }

    const value = options.find(find)
    if (value) {
      setValue(value)
    }
  }, [validate, options, setValue, find])

  const onOptionsLoaded = (options: Option[]) => {
    setOptions(options)
  }

  return [onOptionsLoaded]
}
