import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { ScheduleOutcome } from '../models/ScheduleOutcome'
import { useDisclosure } from 'shared/hooks'
import { useSnackbar } from 'notistack'
import { useDeleteScheduleOutcomeMutation } from '../data/Mutations'

export interface DeleteProps {
  scheduleOutcomes: ScheduleOutcome[]
}

const dialogId = 'ScheduleOutcomeDeleteDialog'

export const Delete = ({ scheduleOutcomes }: DeleteProps) => {
  const { isOpen, open, close } = useDisclosure()

  const { enqueueSnackbar } = useSnackbar()

  const [deleteScheduleOutcome] = useDeleteScheduleOutcomeMutation()

  const deleteOutcomes = async () => {
    try {
      await Promise.all(
        scheduleOutcomes.map(u =>
          deleteScheduleOutcome({ variables: { id: u.id } }),
        ),
      )
      enqueueSnackbar(
        `${scheduleOutcomes.length} egresos eliminados correctamente`,
        { variant: 'success' },
      )
      close()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        `Ha ocurrido un error al eliminar los egresos . Intentelo más tarde`,
        { variant: 'error' },
      )
    }
  }

  return (
    <>
      <Tooltip title="Eliminar egresos">
        <IconButton aria-label="eliminar" onClick={open}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        open={isOpen}
        onClose={close}
        aria-labelledby={dialogId}
      >
        <DialogTitle id={dialogId}>Eliminar egresos</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Deseas eliminar los siguientes egresos?
          </DialogContentText>
          <List dense>
            {scheduleOutcomes.map(outcome => (
              <ListItem key={outcome.id}>
                <ListItemText primary={`- ${outcome.name}`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteOutcomes} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
