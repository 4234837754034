import * as React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { Form, TextFieldForm } from 'shared/forms'
import { useUpdateCategoryMoveMutation } from '../data/Mutations'
import { useSnackbar } from 'notistack'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useGetCategoryMove } from '../data/Queries'
import { CategoryMoveAutocomplete } from './CategoryMoveAutocomplete'
import { CategoryMove, MoveType } from '../models/CategoryMove'

interface UpdateForm {
  name: string
  description: string
  parent?: CategoryMove
}

const schema = yup.object().shape({
  name: yup.string().required('El nombre es un campo requerido'),
  description: yup.string().required('La descripción es un campo requerido'),
  parent: yup.object().nullable().notRequired(),
})

const formId = 'UpdateCategoryMove'
const dialogId = 'CategoryMoveEditFormDialog'

export const Update = ({ type }: { type: MoveType }) => {
  const [isOpen, setIsOpen] = React.useState(true)

  const { id } = useParams<{ id: string }>()

  const { data, loading: isGetting, error } = useGetCategoryMove({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  })
  const [
    updateCategoryMove,
    { loading: isUpdating },
  ] = useUpdateCategoryMoveMutation()

  const loading = isGetting || isUpdating

  const categoryMove = React.useMemo(() => {
    return data?.getCategoryMove
  }, [data])

  const { enqueueSnackbar } = useSnackbar()

  const { url } = useRouteMatch()

  const history = useHistory()

  const formInstance = useForm<UpdateForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      description: '',
      parent: null,
    },
  })
  const { formState, reset } = formInstance

  const { isDirty } = formState

  React.useEffect(() => {
    if (categoryMove) {
      reset(categoryMove)
    }
  }, [categoryMove, reset])

  React.useEffect(() => {
    if (error) {
      console.warn(error)

      let msg =
        'Ha ocurrido un error al obtener la categoría. Intentelo más tarde'

      if (error.message === 'CategoryMove not found') {
        msg = 'Categoría no encontrada'
      }

      enqueueSnackbar(msg, { variant: 'error' })
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, enqueueSnackbar])

  const onClose = () => {
    setIsOpen(false)
  }

  const onExited = () => {
    history.push(url.replace(`/edit/${id}`, ''))
  }

  const onSubmit: SubmitHandler<UpdateForm> = async values => {
    try {
      if (!isDirty) {
        return
      }

      const { parent, ...rest } = values

      await updateCategoryMove({
        variables: {
          data: { id: categoryMove.id, parentId: parent?.id, ...rest },
        },
      })

      enqueueSnackbar('Categoría actualizada correctamente', {
        variant: 'success',
      })
      onClose()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        'Ha ocurrido un error al actualizar la categoría. Intentelo más tarde',
        { variant: 'error' },
      )
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      open={isOpen}
      onExited={onExited}
      onClose={onClose}
      aria-labelledby={dialogId}
    >
      <DialogTitle id={dialogId}>Editar categoría</DialogTitle>
      <DialogContent>
        <Form
          {...formInstance}
          onSubmit={onSubmit}
          onError={console.warn}
          formProps={{ id: formId }}
        >
          <TextFieldForm
            name="name"
            label="Nombre"
            placeholder="Ingresar nombre"
            variant="outlined"
            className="mb-16"
            required
            fullWidth
            autoFocus
            disabled={loading}
          />
          <TextFieldForm
            name="description"
            label="Descripción"
            placeholder="Ingresar descripción"
            variant="outlined"
            className="mb-16"
            rows={3}
            multiline
            required
            fullWidth
            disabled={loading}
          />
          {categoryMove ? (
            <>
              <CategoryMoveAutocomplete
                type={type}
                onlyChilds={false}
                outcomeType={categoryMove.outcomeType}
                name="parent"
                inputProps={{ label: 'Categoría padre', variant: 'outlined' }}
                placeholder="Buscar categoría padre"
                className="mb-16"
                fullWidth
                disabled={loading}
              ></CategoryMoveAutocomplete>
              <span className="error">
                * Modificar el padre de una categoría puede afectar los
                resultados de reportes consultados en el pasado
              </span>
            </>
          ) : null}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button type="submit" color="primary" form={formId} disabled={loading}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
