import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { TypeCredit } from '../models/TypeCredit'

export const TypeCreditFragment = gql`
  fragment TypeCreditFragment on TypeCredit {
    id
    name
    status
    createdAt
    tenantId
  }
`

const LIST_TYPE_CREDIT = gql`
  query {
    listTypeCredit {
      ...TypeCreditFragment
    }
  }
  ${TypeCreditFragment}
`
export type ListTypeCreditResponse = { listTypeCredit: TypeCredit[] }

export const useTypeCreditList = (
  options?: QueryHookOptions<ListTypeCreditResponse>,
) => {
  const result = useQuery<ListTypeCreditResponse>(LIST_TYPE_CREDIT, options)
  return result
}

const GET_TYPE_CREDIT = gql`
  query GetTypeCredit($id: String!) {
    getTypeCredit(id: $id) {
      ...TypeCreditFragment
    }
  }
  ${TypeCreditFragment}
`
export type GetTypeCreditResponse = { getTypeCredit: TypeCredit }
export interface GetTypeCreditVars {
  id: string
}

export const useGetTypeCredit = (
  options?: QueryHookOptions<GetTypeCreditResponse, GetTypeCreditVars>,
) => {
  const result = useQuery<GetTypeCreditResponse, GetTypeCreditVars>(
    GET_TYPE_CREDIT,
    options,
  )
  return result
}
