import { CrudPage } from 'shared/crud/CrudPage'
import { ListPage } from 'shared/crud/CrudPage/ListPage'
import { AddPage } from 'shared/crud/CrudPage/AddPage'
import { List } from './components/List'
import { Create } from './components/Create'

export const TransferPages = () => {
  return (
    <CrudPage name="transfer" actions={['list', 'create']}>
      <ListPage title="Listado de transferencias">
        <List></List>
      </ListPage>
      <AddPage>
        <Create></Create>
      </AddPage>
    </CrudPage>
  )
}
