import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client'
import { AccountBalance } from 'modules/reports/account-balance/data/Queries'

export interface ListAccountBalancePayload {
  initDate: Date
  endDate: Date
  organizationId?: string
  bankAccountId?: string
  credittId?: string
  creditCardId?: string
}

export interface ListAccountBalanceStatementResponse {
  listAccountBalanceStatement: AccountBalance[]
}

export const AccountBalanceStatementFragment = gql`
  fragment AccountBalanceStatementFragment on AccountBalance {
    id
    initialBalance
    income
    outcome
    balance
    organization {
      id
      name
    }
    bankAccount {
      id
      name
    }
  }
`

const LIST_ACCOUNT = gql`
  query ListAccountBalanceStatement($data: ListAccountBalancePayload!) {
    listAccountBalanceStatement(data: $data) {
      ...AccountBalanceStatementFragment
    }
  }
  ${AccountBalanceStatementFragment}
`

export const useAccountBalanceList = (
  options?: QueryHookOptions<
    ListAccountBalanceStatementResponse,
    { data: ListAccountBalancePayload }
  >,
) => {
  const result = useLazyQuery<
    ListAccountBalanceStatementResponse,
    { data: ListAccountBalancePayload }
  >(LIST_ACCOUNT, options)
  return result
}
