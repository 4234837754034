import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { SATPaymentMethod } from '../models/SATPaymentMethod'

export interface ListSATPaymentMethodResponse {
  listSATPaymentMethod: SATPaymentMethod[]
}

export const ListSATPaymentMethodFragment = gql`
  fragment ListSATPaymentMethodFragment on SATPaymentMethod {
    id
    code
    name
    status
    createdAt
  }
`

const LIST_SAT_PAYMENT_METHOD = gql`
  query {
    listSATPaymentMethod {
      ...ListSATPaymentMethodFragment
    }
  }
  ${ListSATPaymentMethodFragment}
`

export const useSATPaymentMethodList = (
  options?: QueryHookOptions<ListSATPaymentMethodResponse>,
) => {
  const result = useQuery<ListSATPaymentMethodResponse>(
    LIST_SAT_PAYMENT_METHOD,
    options,
  )
  return result
}
