import { Overrides } from '@material-ui/core/styles/overrides'

export const overrides: Overrides = {
  MuiInputLabel: {
    root: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      right: 32,
    },
    shrink: {
      right: 'unset',
    },
  },
}
