import * as React from 'react'

import { CsvParserConfig, parseArrayToCsv } from 'shared/utils/Csv'

export interface ExportToCsvConfig<T> extends CsvParserConfig<T> {
  filename: string
}

export const useExportToCsv = <T,>({
  data,
  columns,
  filename,
}: ExportToCsvConfig<T>) => {
  return React.useCallback(() => {
    const csv = parseArrayToCsv({
      data,
      columns,
    })
    const encodedCsv = encodeURIComponent(csv)
    const csvUri = `data:text/csv;charset=utf-8,${encodedCsv}`

    const link = document.createElement('a')
    link.setAttribute('href', csvUri)
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [data, columns, filename])
}
