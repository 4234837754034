import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { CreditCard } from '../models/CreditCard'
import { useDisclosure } from 'shared/hooks'
import { useSnackbar } from 'notistack'
import { useDeleteCreditCardMutation } from '../data/Mutations'

export interface DeleteProps {
  cards: CreditCard[]
}

const dialogId = 'CreditCardDeleteDialog'

export const Delete = ({ cards }: DeleteProps) => {
  const { isOpen, open, close } = useDisclosure()

  const { enqueueSnackbar } = useSnackbar()

  const [deleteCreditCard] = useDeleteCreditCardMutation()

  const deleteCards = async () => {
    try {
      await Promise.all(
        cards.map(u => deleteCreditCard({ variables: { id: u.id } })),
      )
      enqueueSnackbar(`${cards.length} tarjetas eliminadas correctamente`, {
        variant: 'success',
      })
      close()
    } catch (e) {
      console.warn(e)
      enqueueSnackbar(
        `Ha ocurrido un error al eliminar las tarjetas . Intentelo más tarde`,
        { variant: 'error' },
      )
    }
  }

  return (
    <>
      <Tooltip title="Eliminar tarjetas">
        <IconButton aria-label="eliminar" onClick={open}>
          <DeleteIcon></DeleteIcon>
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        open={isOpen}
        onClose={close}
        aria-labelledby={dialogId}
      >
        <DialogTitle id={dialogId}>Eliminar tarjetas</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Deseas eliminar las siguientes tarjetas?
          </DialogContentText>
          <List dense>
            {cards.map(company => (
              <ListItem key={company.id}>
                <ListItemText primary={`- ${company.name}`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            Cancelar
          </Button>
          <Button onClick={deleteCards} color="primary">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
