import * as React from 'react'
import { Box, Card, Tab, Tabs, Typography } from '@material-ui/core'
import { Filter, FilterValues } from './Filter'
import { useAccountBalanceList } from './data/Queries'
import { SimplePage } from 'shared'
import { AccountBalanceTable } from './table'
import { useNetIncomeTotalsState } from 'modules/dashboard/components/UseNetIncomeTotals'
import { NetIncomeTotals } from 'modules/dashboard/components/NetIncomeTotals'
import {
  CategoryOutcomeType,
  CategoryOutcomeTypeTexts,
  MoveType,
} from 'modules/category-move/models/CategoryMove'
import {
  CategoryAmountTable,
  CategoryAmountTableFilter,
  useCategoryAmountTable,
} from './category-amount-table'
import { buildDateRange, buildDateRangeSegments } from './utils/AggregatedDates'
import dayjs from 'dayjs'

function TabPanel(props: {
  children: React.ReactNode
  current: number
  index: number
}) {
  const { children, current, index } = props

  return (
    <div
      role="tabpanel"
      hidden={current !== index}
      id={`income-statement-tabpanel-${index}`}
      aria-labelledby={`income-statement-tab-${index}`}
    >
      {current === index && <Box>{children}</Box>}
    </div>
  )
}

export const StatementOfIncomes = () => {
  const [tab, setTab] = React.useState(0)
  const [title, setTitle] = React.useState('')
  const [filter, setFilter] = React.useState<FilterValues>({
    month: dayjs(),
  })
  const [fetchIncomeTable, incomeTableState] = useCategoryAmountTable({
    moveType: MoveType.income,
  })
  const [fetchOutcomeTable, outcomeTableState] = useCategoryAmountTable({
    moveType: MoveType.outcome,
  })
  const [fetchPurchaseTable, purchaseTableState] = useCategoryAmountTable({
    moveType: MoveType.outcome,
    categoryOutcomeType: CategoryOutcomeType.purchase,
  })
  const netIncomeTotalsState = useNetIncomeTotalsState({
    fetchPolicy: 'network-only',
  })
  const {
    getTotals,
    getChartData,
    totalsState: { loading: totalsLoading },
    chartState: { loading: chartLoading },
  } = netIncomeTotalsState

  const [
    getListAccount,
    { data: listDataAccount, loading: accountsLoading },
  ] = useAccountBalanceList({
    fetchPolicy: 'network-only',
  })

  const loading = totalsLoading || chartLoading || accountsLoading

  const accounts = React.useMemo(() => {
    return listDataAccount?.listAccountBalanceStatement ?? []
  }, [listDataAccount])

  const fetchOnFilter = (values: FilterValues) => {
    const segmentType = values.aggregate ? 'yearly' : 'monthly'
    const totals = buildDateRange(segmentType, values.month)
    const dateRangeSegments = buildDateRangeSegments(segmentType, values.month)

    if (segmentType === 'monthly') {
      setTitle(values.month.format('MMMM YYYY'))
    } else {
      setTitle(values.month.format('YYYY'))
    }

    const organizationId = values.organization?.id

    const segmentsFilter: CategoryAmountTableFilter = {
      organizationId,
      segments: [],
    }

    if (segmentType === 'monthly') {
      segmentsFilter.segments.push({
        name: values.month.format('MMMM YYYY'),
        initDate: totals.initDate,
        endDate: totals.endDate,
      })
    } else {
      segmentsFilter.segments = dateRangeSegments.map(segment => ({
        name: segment.name,
        initDate: segment.initDate,
        endDate: segment.endDate,
      }))
    }

    fetchIncomeTable(segmentsFilter)
    fetchOutcomeTable(segmentsFilter)
    fetchPurchaseTable(segmentsFilter)

    getTotals({
      variables: {
        totals: {
          organizationId,
          ...totals,
        },
      },
    })
    getChartData({
      variables: {
        chart: {
          payload: dateRangeSegments,
          organizationId,
        },
      },
    })
    getListAccount({
      variables: {
        data: {
          initDate: totals.initDate,
          endDate: totals.endDate,
          organizationId,
        },
      },
    })
  }

  const onFilter = (values: FilterValues) => {
    setFilter(values)
    fetchOnFilter(values)
  }

  React.useEffect(() => {
    fetchOnFilter(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: any) => {
    setTab(newValue)
  }

  return (
    <SimplePage
      header={
        <div className="flex h-full w-full p-12 px-40 items-center justify-between">
          <div>
            <Typography variant="h6">Estado de resultados - {title}</Typography>
          </div>
          <Filter
            className="mb-14 p-20"
            filter={filter}
            loading={loading}
            onFilter={onFilter}
          ></Filter>
        </div>
      }
      contentToolbar={
        <Card square>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            aria-label="Estado de resultados"
          >
            <Tab
              label="Resumen"
              id={`incomes-statement-0`}
              aria-controls={`incomes-statement-tabpanel-0`}
            />
            <Tab
              label="Detalle"
              id={`incomes-statement-1`}
              aria-controls={`incomes-statement-tabpanel-1`}
            />
          </Tabs>
        </Card>
      }
    >
      <div className="p-32">
        <TabPanel current={tab} index={0}>
          <Card className="mb-32">
            <NetIncomeTotals {...netIncomeTotalsState}></NetIncomeTotals>
          </Card>
          <Card>
            <h1 className="ml-16">Saldos finales</h1>
            <AccountBalanceTable
              data={!accountsLoading ? accounts : []}
            ></AccountBalanceTable>
          </Card>
        </TabPanel>
        <TabPanel current={tab} index={1}>
          <Card className="mb-32">
            <h1 className="ml-16">Ingresos</h1>
            <CategoryAmountTable {...incomeTableState}></CategoryAmountTable>
          </Card>
          <Card className="mb-32">
            <h1 className="ml-16">
              {CategoryOutcomeTypeTexts[CategoryOutcomeType.purchase]}
            </h1>
            <CategoryAmountTable {...purchaseTableState}></CategoryAmountTable>
          </Card>
          <Card>
            <h1 className="ml-16">Egresos</h1>
            <CategoryAmountTable {...outcomeTableState}></CategoryAmountTable>
          </Card>
        </TabPanel>
      </div>
    </SimplePage>
  )
}
