import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Permission } from 'auth/models/Permission'
import { User } from 'auth/models/User'
import { UserProfile } from 'auth/models/UserProfile'

const LIST_USER = gql`
  query {
    listUser {
      id
      email
      firstName
      lastName
      createdAt
      status
    }
  }
`
export type ListUserResponse = { listUser: User[] }

export const useUserList = (options?: QueryHookOptions<ListUserResponse>) => {
  const result = useQuery<ListUserResponse>(LIST_USER, options)
  return result
}

const GET_USER = gql`
  query GetUser($id: String!) {
    getUser(id: $id) {
      id
      email
      firstName
      lastName
      createdAt
      status
      profiles {
        id
        name
        label
      }
      permissions {
        id
        name
        label
      }
    }
  }
`
export type GetUserResponse = { getUser: User }
export interface GetUserVars {
  id: string
}

export const useGetUser = (
  options?: QueryHookOptions<GetUserResponse, GetUserVars>,
) => {
  const result = useQuery<GetUserResponse, GetUserVars>(GET_USER, options)
  return result
}

const LIST_PROFILES = gql`
  query ListProfiles {
    listUserProfiles {
      id
      name
      label
      tenantId
    }
  }
`

export interface ListProfileResponse {
  listUserProfiles: UserProfile[]
}

export const useProfilesList = (
  options?: QueryHookOptions<ListProfileResponse>,
) => {
  const result = useQuery<ListProfileResponse>(LIST_PROFILES, options)
  return result
}

const LIST_PERMISSIONS = gql`
  query ListPermissions {
    listPermissions {
      id
      name
      label
    }
  }
`

export interface ListPermissionsResponse {
  listPermissions: Permission[]
}

export const usePermissionsList = (
  options?: QueryHookOptions<ListPermissionsResponse>,
) => {
  const result = useQuery<ListPermissionsResponse>(LIST_PERMISSIONS, options)
  return result
}
