import currency from 'currency-formatter'

export function formatCurrency(value: number) {
  return currency.format(value, {
    code: 'MXN',
  })
}

export function formatNumber(value: number) {
  const val = currency.format(value, {
    code: 'MXN',
  })
  return val.replace('$', '')
}
