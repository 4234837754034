import { __DEV__ } from 'core/utils'

type MessageOptions = {
  condition: boolean
  message: string
}

export const warn = (options: MessageOptions) => {
  const { condition, message } = options
  if (condition && __DEV__) {
    console.warn(message)
  }
}

export const error = (options: MessageOptions) => {
  const { condition, message } = options
  if (condition && __DEV__) {
    console.error(message)
  }
}
