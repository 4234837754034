import { Grid, Typography } from '@material-ui/core'
import { WithPermissions } from 'auth/components/WithPermissions'
import { SimplePage } from 'shared'
import { ExpenseCategories } from './components/ExpenseCategories'
import { NetIncome } from './components/NetIncome'

export const Dashboard = () => {
  return (
    <SimplePage
      header={
        <div className="flex h-full w-full py-24 px-40 items-center justify-between">
          <Typography variant="h6">Dashboard</Typography>
        </div>
      }
    >
      <div className="p-32">
        <Grid container spacing={2}>
          <WithPermissions permissions={['dashboard.netIncome']}>
            <Grid item xs={6}>
              <NetIncome></NetIncome>
            </Grid>
          </WithPermissions>
          <WithPermissions permissions={['dashboard.expenseCategories']}>
            <Grid item xs={6}>
              <ExpenseCategories></ExpenseCategories>
            </Grid>
          </WithPermissions>
        </Grid>
      </div>
    </SimplePage>
  )
}
