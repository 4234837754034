import * as React from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  Value,
} from '@material-ui/lab'
import { FieldError, useController, useFormContext } from 'react-hook-form'

export interface AutocompleteInstance<T> {
  getOptions: () => T[]
}

export interface AutocompleteFormProps<
  T,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  name: string
  inputProps?: TextFieldProps
  instance?: React.Ref<AutocompleteInstance<T>>
}

export const AutocompleteForm = <
  T,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
>({
  name: propName,
  inputProps = {},
  defaultValue,
  onChange: propOnChange,
  instance,
  noOptionsText = 'No hay opciones',
  ...autocompleteProps
}: AutocompleteFormProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const { name: inputName } = inputProps

  const name = propName ?? inputName

  const { control, errors } = useFormContext()

  const error = errors[name] as FieldError

  React.useImperativeHandle(instance, () => ({
    getOptions: () => {
      return autocompleteProps.options
    },
  }))

  const {
    field: { ref, value, onChange: formOnChange, ...formProps },
  } = useController({
    name,
    control,
    defaultValue,
  })

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        name={name}
        {...inputProps}
        inputRef={ref}
        error={Boolean(error)}
        helperText={error?.message}
        {...params}
      ></TextField>
    )
  }

  const onChange = (
    event: React.ChangeEvent<{}>,
    value: Value<T, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>,
  ) => {
    formOnChange(value)

    propOnChange?.(event, value, reason, details)
  }

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      {...formProps}
      {...autocompleteProps}
      renderInput={renderInput}
      noOptionsText={noOptionsText}
    ></Autocomplete>
  )
}
