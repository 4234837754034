import { WithPermissions } from 'auth/components/WithPermissions'
import { Route } from 'react-router-dom'
import { useCrudContext } from './CrudContext'

export const AddPage: React.FC = ({ children }) => {
  const { url, name } = useCrudContext()
  return (
    <WithPermissions permissions={[`${name}.create`]}>
      <Route path={`${url}/create`}>{children}</Route>
    </WithPermissions>
  )
}
