import * as React from 'react'
import clsx from 'clsx'
import { List, makeStyles } from '@material-ui/core'
import { useNavigationList } from './NavigationConfig'
import { NavigationItemWrapper } from './components/NavigationItemWrapper'

const useStyles = makeStyles(theme => ({
  navigation: {
    '& .list-item': {
      '&:hover': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? 'rgba(255, 255, 255, 0.05)'
            : 'rgba(0,0,0,.04)',
      },
      '&:focus:not(.active)': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? 'rgba(255, 255, 255, 0.06)'
            : 'rgba(0,0,0,.05)',
      },
    },
  },
  verticalNavigation: {
    '&.active-square-list': {
      '& .list-item, & .active.list-item': {
        width: '100%',
        borderRadius: '0',
      },
    },
    '&.dense': {
      '& .list-item': {
        paddingTop: 0,
        paddingBottom: 0,
        height: 32,
      },
    },
  },
  horizontalNavigation: {
    '&.active-square-list': {
      '& .list-item': {
        borderRadius: '0',
      },
    },
    '& .list-item': {
      padding: '8px 12px 8px 12px',
      height: 40,
      minHeight: 40,
      '&.level-0': {
        height: 44,
        minHeight: 44,
      },
      '& .list-item-text': {
        padding: '0 0 0 8px',
      },
    },
  },
  '@global': {
    '.popper-navigation-list': {
      '& .list-item': {
        padding: '8px 12px 8px 12px',
        height: 40,
        minHeight: 40,
        '& .list-item-text': {
          padding: '0 0 0 8px',
        },
      },
      '&.dense': {
        '& .list-item': {
          minHeight: 32,
          height: 32,
          '& .list-item-text': {
            padding: '0 0 0 8px',
          },
        },
      },
    },
  },
}))

export const Navigation: React.FC<{ className?: string }> = ({ className }) => {
  const classes = useStyles()

  const navigation = useNavigationList()

  return (
    <List
      className={clsx(
        'navigation whitespace-nowrap',
        classes.navigation,
        classes.verticalNavigation,
        className,
      )}
    >
      {navigation.map(_item => (
        <NavigationItemWrapper
          key={_item.id}
          item={_item}
          nestedLevel={0}
        ></NavigationItemWrapper>
      ))}
    </List>
  )
}
