import { WithPermissions } from 'auth/components/WithPermissions'
import { generateCrudActions } from 'auth/utils/generateCrudActions'
import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CrudConsumer, CrudProvider } from './CrudContext'

export type CrudAction = 'list' | 'create' | 'update' | 'delete'

export const crudActions: CrudAction[] = ['list', 'create', 'update', 'delete']

const crudRoutes: Record<CrudAction, string> = {
  list: '',
  create: '/create',
  update: '/edit/:id',
  delete: '',
}

export const validCrudRoutes = (url: string) => {
  return ['', '/create', '/edit/:id'].map(p => url + p)
}

export interface CrudPageProps {
  name?: string
  url?: string
  includePaths?: (url: string) => string[]
  actions?: CrudAction[]
}

export const CrudPage: React.FC<CrudPageProps> = ({
  children,
  url: urlProp,
  actions = crudActions,
  includePaths = () => [],
  name,
}) => {
  const permissions = generateCrudActions(name, actions)

  return (
    <WithPermissions rejected={redirectToHome} permissions={permissions}>
      <CrudProvider url={urlProp} name={name}>
        <CrudConsumer>
          {({ url }) => {
            const routes = actions
              .map(action => `${url}${crudRoutes[action]}`)
              .concat(includePaths(url))
            return (
              <Switch>
                <Route exact path={routes}>
                  {children}
                </Route>
                <Redirect to={url}></Redirect>
              </Switch>
            )
          }}
        </CrudConsumer>
      </CrudProvider>
    </WithPermissions>
  )
}

function redirectToHome() {
  return <Redirect to="/"></Redirect>
}
