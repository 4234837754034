import { useMutation, gql } from '@apollo/client'
import { Credit } from 'modules/credit/models/Credit'
import { CreditFragment } from 'modules/credit/data/Queries'

export interface UpdateCustomerCreditPayload {
  id: string
  name: string
  organizationId: string
  customerId: string
  initialBalance?: number
}

const UPDATE_CREDIT = gql`
  mutation UpdateCustomerCredit($data: UpdateCustomerCreditPayload!) {
    updateCustomerCredit(data: $data) {
      ...CreditFragment
    }
  }
  ${CreditFragment}
`

export const useUpdateCustomerCreditMutation = () => {
  const tuple = useMutation<
    { updateCredit: Credit },
    { data: UpdateCustomerCreditPayload }
  >(UPDATE_CREDIT)

  return tuple
}

export interface RegisterCustomerCreditPayload {
  name: string
  organizationId: string
  customerId: string
  totalDebt: number
  debt?: number
  initDate: Date
  lastPaymentDate?: Date
}

const REGISTER_CREDIT = gql`
  mutation CreateCustomerCredit($data: RegisterCustomerCreditPayload!) {
    registerCustomerCredit(data: $data) {
      ...CreditFragment
    }
  }
  ${CreditFragment}
`

export const useRegisterCustomerCreditMutation = () => {
  const tuple = useMutation<
    { registerCredit: Credit },
    { data: RegisterCustomerCreditPayload }
  >(REGISTER_CREDIT)

  return tuple
}
