import { useLocation } from 'react-router'
import * as H from 'history'

export const useQueryParams = <T extends object>() => {
  const location = useLocation()
  return parseQuery<T>(location)
}

function parseQuery<T extends object>(location: H.Location) {
  return location.search
    .slice(1)
    .split('&')
    .reduce<T>((acu, next) => {
      const [key, value] = next.split('=')
      acu[key] = value
      return acu
    }, {} as T)
}
